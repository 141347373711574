import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';

export const PinchZoomImageViewer: React.FC = () => {
  console.log(window.location.search);
  const [activeIndex, setActiveIndex] = useState(0);
  const [srcs, setSrcs] = useState<string[]>([]);
  const slider = useRef<Slider>(null);
  const [canSwipe, setCanSwipe] = useState(true);

  const router = useIonRouter();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const index = urlParams.get('index');
    const srcs = urlParams.get('srcs');

    if (index) {
      setActiveIndex(~~index);
    }
    if (srcs) {
      setSrcs(JSON.parse(srcs));
    }
  }, []);

  useEffect(() => {
    if (srcs.length) {
      slider.current?.slickGoTo(activeIndex);
    }
  }, [srcs, activeIndex]);

  return (
    <IonPage>
      <IonContent>
        <Wrapper>
          <TopBar>
            <ChevronLeft src={'/assets/icon/chevron_up.svg'} onClick={() => router.goBack()} />
          </TopBar>
          <Content>
            <Slider
              ref={slider}
              slidesToShow={1}
              arrows={false}
              dots={false}
              infinite={false}
              afterChange={index => {
                setActiveIndex(index);
              }}
              draggable={canSwipe}
            >
              {srcs.map((src, i) => (
                <div key={src}>
                  <TransformWrapper
                    panning={{ disabled: canSwipe }}
                    onZoom={ref => {
                      setCanSwipe(ref.state.scale <= 1);
                    }}
                  >
                    <TransformComponent>
                      <SequentialImg src={src} myIndex={i} activeIndex={activeIndex} />
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              ))}
            </Slider>
          </Content>
        </Wrapper>
      </IonContent>
    </IonPage>
  );
};

export const SequentialImg: React.FC<SequentialImgProps> = ({ src, activeIndex, myIndex }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if ((myIndex <= activeIndex - 3 || myIndex <= activeIndex + 3) && !hasLoaded) {
      setHasLoaded(true);
    }
  }, [activeIndex]);

  return hasLoaded ? <Img loading={'eager'} src={src} /> : null;
};

interface SequentialImgProps {
  src?: string;
  activeIndex: number;
  myIndex: number;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  overflow: hidden;
`;

const TopBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: sticky;
  z-index: 100;
  background-color: black;
`;

const Content = styled.div`
  width: 100vw;
  height: 100%;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
`;

const ChevronLeft = styled.img`
  width: 20px;
  height: 20px;
  transform: rotate(270deg);
  filter: brightness(0) invert(1);
`;

const Img = styled.img`
  max-width: 100vw;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;
