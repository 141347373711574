import React, { useState } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useParams } from 'react-router';
import { useGetAccountByIdQuery, useToggleAttItemMutation } from '../../generated/graphql';
import { SellerInfoCard } from '../../common/components/SellerInfoCard';
import { ItemCard } from '../../common/components/ItemCard';
import styled from 'styled-components';
import { NavBar } from '../../common/components/NavBar';
import { ReviewList } from '../../common/components/ReviewList';

export const SellerDetail: React.FC = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const [activeSection, setActiveSection] = useState('selling');
  const [itemsToShow, setItemsToShow] = useState(3);
  const { data, refetch } = useGetAccountByIdQuery({ variables: { accountId: ~~accountId } });
  const [toggleAttItem] = useToggleAttItemMutation();
  const seller = data?.searchAccountById;

  const handleHeartClick = async (itemId?: string) => {
    if (itemId) {
      await toggleAttItem({ variables: { itemId: ~~itemId } });
      refetch();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>판매자 정보</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        {seller && (
          <SellerInfoCard
            sellerId={seller.accountId}
            sellerName={seller.nickName ?? ''}
            addressName={seller.address ?? ''}
            avgStarPoint={seller.avgStarPoint ?? 0}
            reviewCount={seller.reviewCount ?? 0}
            refetch={refetch}
          />
        )}
        <IonSegment
          value={activeSection}
          onIonChange={e => {
            setItemsToShow(3);
            setActiveSection(e.target.value as string);
          }}
          mode={'md'}
        >
          <IonSegmentButton value={'selling'}>판매중 {seller?.ingItemList?.length ?? ''}</IonSegmentButton>
          <IonSegmentButton value={'complete'}>거래완료 {seller?.endItemList?.length ?? ''}</IonSegmentButton>
        </IonSegment>
        <div>
          {activeSection === 'selling' &&
            seller &&
            seller.ingItemList?.map((item, i) => {
              if (item && i < itemsToShow) {
                return (
                  <ItemCard
                    key={`seller-detail-ing-card-${item.itemId}`}
                    itemId={item.itemId}
                    s3Url={item?.fileList?.length ? item?.fileList[0]?.s3Url! : '/assets/defaultImage.png'}
                    title={item.title ?? ''}
                    type={item.type ?? ''}
                    diffTime={item.diffTime ?? ''}
                    price={item.price ?? ''}
                    isAttItem={item.isAttItem ?? false}
                    attCount={item.attUserCount ?? 0}
                    onHeartClick={() => handleHeartClick(item?.itemId)}
                  />
                );
              }
            })}
          {activeSection === 'complete' &&
            seller &&
            seller.endItemList?.map((item, i) => {
              if (item && i < itemsToShow) {
                return (
                  <ItemCard
                    key={`seller-detail-end-card-${item.itemId}`}
                    itemId={item.itemId}
                    s3Url={item?.fileList?.length ? item?.fileList[0]?.s3Url! : '/assets/defaultImage.png'}
                    title={item.title ?? ''}
                    type={item.type ?? ''}
                    diffTime={item.diffTime ?? ''}
                    price={item.price ?? ''}
                    isAttItem={item.isAttItem ?? false}
                    attCount={item.attUserCount ?? 0}
                    onHeartClick={() => handleHeartClick(item?.itemId)}
                  />
                );
              }
            })}
        </div>
        {activeSection === 'selling' && seller && seller.ingItemList?.length && seller.ingItemList.length - itemsToShow > 0 && (
          <ShowMoreItem onClick={() => setItemsToShow(seller!.ingItemList!.length)}>{seller.ingItemList.length}건 전체 보기</ShowMoreItem>
        )}
        {activeSection === 'complete' && seller && seller.endItemList?.length && seller.endItemList.length - itemsToShow > 0 && (
          <ShowMoreItem onClick={() => setItemsToShow(seller!.endItemList!.length)}>{seller.endItemList.length}건 전체 보기</ShowMoreItem>
        )}
        <div className={'font-16-600 n_30'}>받은 거래 후기</div>
        <ReviewList
          reviews={
            seller?.reviewList?.map(r => ({
              sellerId: r?.sellId ?? '',
              nickname: r?.buyerAccount?.nickName ?? '',
              diffTime: r?.diffTime ?? '',
              review: r?.review ?? '',
            })) ?? []
          }
        />
      </IonContent>
      <NavBar />
    </IonPage>
  );
};

const ShowMoreItem = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: var(--ion-color-primary);
  text-align: end;
  padding: 10px 0;
`;
