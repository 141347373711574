import React from 'react';
import { useGetMyAccountQuery, useGetMyBankQuery, useUpdateProfileMutation } from '../../generated/graphql';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonViewWillEnter,
} from '@ionic/react';
import { appRoutes } from '../../AppRoutes';
import styled from 'styled-components';
import { selectProfileImage } from '../../common/utils/photoUtils';

export const MyInfoEdit: React.FC = () => {
  const account = useGetMyAccountQuery({fetchPolicy: 'network-only'});
  const myBank = useGetMyBankQuery();
  const router = useIonRouter();

  const [updateProfile] = useUpdateProfileMutation();

  useIonViewWillEnter(() => {
    account.refetch();
  });

  const handleChangeProfileClick = async () => {
    const newProfile = await selectProfileImage();
    if (newProfile) {
      await updateProfile({
        variables: {
          b46: newProfile.b64,
          filename: newProfile.filename,
        },
      });
      account.refetch();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>계정 정보 관리</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <AvatarWrapper>
          <UserAvatar src={account?.data?.searchMyAccount?.file?.s3Url ?? '/assets/account-photo.png'} />
          <ChangeAvatarWrapper>
            <ChangeAvatar src={'/assets/change-profile.png'} onClick={handleChangeProfileClick} />
          </ChangeAvatarWrapper>
        </AvatarWrapper>
        <IonList>
          <IonListHeader>계좌 정보</IonListHeader>

          <IonItem detail={true} onClick={() => router.push(appRoutes.myInfo.children.editNickname.path())} button>
            <IonLabel className={'font-16-600'}>
              <div className={'font-16-600 n_10'} style={{ lineHeight: '30px' }}>
                닉네임
              </div>
              <div className={'font-16-500 n_30'} style={{ lineHeight: '30px' }}>
                {account.data?.searchMyAccount?.nickName}
              </div>
            </IonLabel>
          </IonItem>

          <IonItem detail={true} onClick={() => router.push(appRoutes.myInfo.children.editEmail.path())} button>
            <IonLabel className={'font-16-600'}>
              <div className={'font-16-600 n_10'} style={{ lineHeight: '30px' }}>
                이메일
              </div>
              <div className={'font-16-500 n_30'} style={{ lineHeight: '30px' }}>
                {account.data?.searchMyAccount?.email}
              </div>
            </IonLabel>
          </IonItem>

          <IonItem detail={true} onClick={() => router.push(appRoutes.myInfo.children.editPhone.path())} button>
            <IonLabel className={'font-16-600'}>
              <div className={'font-16-600 n_10'} style={{ lineHeight: '30px' }}>
                휴대폰 번호
              </div>
              <div className={'font-16-500 n_30'} style={{ lineHeight: '30px' }}>
                {account.data?.searchMyAccount?.phone}
              </div>
            </IonLabel>
          </IonItem>

          <IonItem detail={true} onClick={() => router.push(appRoutes.myInfo.children.editBank.path())} button>
            <IonLabel className={'font-16-600'}>
              <div className={'font-16-600 n_10'} style={{ lineHeight: '30px' }}>
                계좌번호
              </div>
              <div className={'font-16-500 n_30'} style={{ lineHeight: '30px' }}>{`${myBank.data?.searchMyBank?.bankNumber ?? ''} ${
                myBank.data?.searchMyBank?.bankName ?? ''
              } ${myBank.data?.searchMyBank?.owner ?? ''}`}</div>
            </IonLabel>
          </IonItem>

          <IonItem detail={true} onClick={() => router.push(appRoutes.myInfo.children.editAddress.path())} button>
            <IonLabel className={'font-16-600'}>
              <div className={'font-16-600 n_10'} style={{ lineHeight: '30px' }}>
                주소
              </div>
              <div className={'font-16-500 n_30'} style={{ lineHeight: '30px' }}>
                {account.data?.searchMyAccount?.address}
              </div>
            </IonLabel>
          </IonItem>

          {/* <IonListHeader>기타</IonListHeader>
          <IonItem lines={'none'}>
            <a href='src/pages/my-info/MyInfoEdit#'>서비스 이용약관</a>
          </IonItem>
          <IonItem lines={'none'}>
            <a href='src/pages/my-info/MyInfoEdit#'>개인정보처리방침</a>
          </IonItem> */}
        </IonList>
      </IonContent>
      <IonFooter className={'ion-padding'}>
        <IonButton expand={'block'} size={'large'} onClick={() => router.goBack()}>
          완료
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  padding: 16px;
`;

const UserAvatar = styled.img`
  width: 120px;
  border-radius: 6px;
  margin: auto;
`;

const ChangeAvatarWrapper = styled.div`
  padding: 6px;
  border-radius: 6px;
  background-color: #e1e3e9;
  height: 50px;
  position: absolute;
  right: calc(50% - 72px);
  bottom: 4px;
`;

const ChangeAvatar = styled.img`
  height: 100%;
`;
