import React, { useState } from 'react';
import styled from 'styled-components';

export const ReviewList: React.FC<{ reviews: Review[] }> = ({ reviews }) => {
  const [showAllReviews, setShowAllReviews] = useState(false);
  return (
    <ReviewContainer>
      {reviews.map((review, i) => {
        if (review && (showAllReviews ? true : i < 3)) {
          return (
            <Review key={`review_${review.sellerId}`}>
              <ReviewInfo>
                <div className={'font-16-600 n_30'}>{review.nickname}</div>
                <div className={'font-12-400 color-tertiary'}>{review.diffTime}</div>
              </ReviewInfo>
              <ReviewContent all={showAllReviews}>{review.review}</ReviewContent>
            </Review>
          );
        }
      })}
      {!showAllReviews && <ShowMoreItem onClick={() => setShowAllReviews(true)}>{reviews.length}건 전체 보기</ShowMoreItem>}
    </ReviewContainer>
  );
};

interface Review {
  sellerId: string | number;
  nickname: string;
  diffTime: string;
  review: string;
}

const ReviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 25px 0;
  border-top: 1px solid #aaaca8;
`;

const Review = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 10px 0;
  border-top: 0.6px solid #e1e3de;
`;

const ReviewInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ReviewContent = styled('div')<{ all: boolean }>`
  width: calc(100%);
  font-size: 14px;
  font-weight: 400;
  text-overflow: ${props => (props.all ? 'inherit' : 'ellipsis')};
  overflow: ${props => (props.all ? 'inherit' : 'hidden')};
  white-space: ${props => (props.all ? 'pre-line' : 'nowrap')};
`;

const ShowMoreItem = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: var(--ion-color-primary);
  text-align: end;
  padding: 10px 0;
`;
