import React from 'react';
import styled from 'styled-components';
import { Camera } from '@capacitor/camera';
import { useIonToast } from '@ionic/react';

export const PhotoSelectContainer: React.FC<Props> = ({ limit, photos, onChange }) => {
  const [present] = useIonToast();

  const handleSelectPhotos = async () => {
    const res = await Camera.pickImages({ quality: 100, limit });

    if (res.photos.length + photos.length > limit) {
      await present({
        message: '사진은 최대 10개까지 등록 가능해요',
        duration: 1500,
        position: 'bottom',
      });
    }

    const temp = [...photos];
    for (let i = 0; i < res.photos.length; i++) {
      if (temp.length === limit) break;
      temp.push(res.photos[i].webPath);
    }

    onChange(temp);
  };

  return (
    <PhotosContainer className={'no-scrollbar'}>
      <AddPhoto count={photos.length} onClick={handleSelectPhotos} limit={limit} />
      {photos.map(p => (
        <Photo key={p} src={p} onClick={() => onChange(photos.filter(src => p !== src))} />
      ))}
    </PhotosContainer>
  );
};

interface Props {
  limit: number;
  photos: string[];
  onChange: (photos: string[]) => void;
}

const PhotosContainer = styled.div`
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 100%;
  display: block;
`;

const AddPhoto = styled('div')<{ count: number; limit: number }>`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: var(--neutral-95);
  background-image: url('/assets/icon/camera.svg');
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: center 20px;
  margin: 4px;
  margin-right: 8px;
  display: inline-block;

  &:after {
    content: '${props => `${props.count}/${props.limit}`}';
    color: var(--neutral-70);
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    top: 60px;
    width: 60px;
    text-align: center;
    display: inline-block;
  }
`;

const Photo = styled('div')<{ src: string }>`
  width: 60px;
  height: 60px;
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  display: inline-block;
  margin: 4px;
  margin-right: 8px;

  &:after {
    content: '';
    display: block;
    background-image: url('/assets/icon/X.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    left: calc(100% - 15px);
    bottom: 4px;
    position: relative;
  }
`;
