import React from 'react';
import { useIonRouter } from '@ionic/react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import styled from 'styled-components/macro';

import { SellerInfoCard } from '../../common/components/SellerInfoCard';
import { appRoutes } from '../../AppRoutes';
// import { useGetAlarmConfigQuery, useUpdateAlarmConfigMutation } from '../../generated/graphql';
import { useMyBlockedSellersQuery, useDeleteAccountBlockMutation } from '../../generated/graphql'; // useAddAccountBlockByIdMutation

export const AccountBlock: React.FC = () => {
  const { data, refetch } = useMyBlockedSellersQuery({fetchPolicy: 'network-only'});
  const router = useIonRouter();
  // const blockSellers = useMyBlockedSellersQuery();
  // const [addAccountBlock] = useAddAccountBlockByIdMutation();
  // const [deleteAccountBlock] = useDeleteAccountBlockMutation();

  // const handleDeleteBlock = async (key: number) => {
  //   await deleteAccountBlock({ variables: { seq: key } });
  //   refetch();
  //   // await deleteAccountBlock({ variables: { alarmConfig: { [key]: val } } });
  //   // blockSellers.refetch();
  // };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>차단 회원 관리</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <Container>
          {data?.myAccountBlockList?.map(
            seller =>
              seller && (
                <SellerInfoCard
                  key={seller.seq}
                  blockId={seller.seq}
                  sellerId={seller.blockedAccount.accountId}
                  sellerName={seller.blockedAccount.nickName ?? ''}
                  addressName={seller.blockedAccount.address ?? ''}
                  avgStarPoint={seller.blockedAccount.avgStarPoint ?? 0}
                  reviewCount={seller.blockedAccount.reviewCount ?? 0}
                  onClick={() => router.push(appRoutes.sellerDetail.path(seller.blockedAccount.accountId), 'forward')}
                  // onClickBlocked={() => handleDeleteBlock(seller.seq)}
                  s3Url={seller.blockedAccount.file?.s3Url}
                  refetch={refetch}
                  onToggleBlock={refetch}
                />
              ),
          )}
        </Container>
      </IonContent>
    </IonPage>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;