import React, { useEffect, useState } from 'react';
import { SignUpData } from '../SignUp';
import styled from 'styled-components';
import { AddressSearch } from '../../address-search/AddressSearch';

export const SignUpAddress: React.FC<Props> = ({ data, validated, onChange }) => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    validated(true);
  }, []);

  const handleChange = (val: string) => {
    onChange({ ...data, address2: val });
  };

  const handleSelect = (address: string) => {
    setQuery(address);
    onChange({ ...data, address1: address });
    validated(true);
  };

  return (
    <>
      <div className={'font-20-500 n_10'}>주소를 입력하세요.</div>
      <input
        className={'large-input'}
        placeholder={'포항시 북구 흥해읍 남송리 3번지 128-12'}
        value={query}
        onChange={e => setQuery(e.target.value)}
      />
      <input
        className={'large-input'}
        placeholder={'한동대학교 효암채플 12층 2028호'}
        value={data.address2}
        onChange={e => handleChange(e.target.value)}
      />
      <AddressSearchWrapper className={'ion-content-scroll-host'}>
        <AddressSearch query={query} onSelect={handleSelect} />
      </AddressSearchWrapper>
    </>
  );
};

interface Props {
  data: SignUpData;
  validated: (validated: boolean) => void;
  onChange: (data: SignUpData) => void;
}

const AddressSearchWrapper = styled.div`
  height: calc(100vh - 360px);
  width: 100%;
  overflow: auto;
`;
