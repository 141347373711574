import React from 'react';
import { IonContent, IonItem, IonLabel, IonList } from '@ionic/react';

export type SellType = 'market' | 'farm' | 'estate' | 'job' | 'mow';

export const ModalSellNewItem: React.FC<Props> = ({ dismiss }) => {
  const handleClick = (type: SellType) => {
    dismiss(type);
  };

  return (
    <IonContent className={'ion-padding'}>
      <IonList>
        <IonItem onClick={() => handleClick('market')}>
          <IonLabel>
            <p className={'font-18-600 n_10'}>벼룩시장 판매 글</p>
            <p className={'font-14-500 n_40'}>농기계, 농자재 등 판매할 물품을 등록하세요.</p>
          </IonLabel>
        </IonItem>
        <IonItem onClick={() => handleClick('farm')}>
          <IonLabel>
            <p className={'font-18-600 n_10'}>농산물 판매 글</p>
            <p className={'font-14-500 n_40'}>농기계, 농자재 등 판매할 물품을 등록하세요.</p>
          </IonLabel>
        </IonItem>
        <IonItem onClick={() => handleClick('estate')}>
          <IonLabel>
            <p className={'font-18-600 n_10'}>부동산 거래 글</p>
            <p className={'font-14-500 n_40'}>매매할 땅, 주택, 농가를 등록하세요.</p>
          </IonLabel>
        </IonItem>
        <IonItem onClick={() => handleClick('job')}>
          <IonLabel>
            <p className={'font-18-600 n_10'}>일자리 구인/구직 글</p>
            <p className={'font-14-500 n_40'}>일손이 필요한 일을 등록하세요.</p>
          </IonLabel>
        </IonItem>
        <IonItem onClick={() => handleClick('mow')}>
          <IonLabel>
            <p className={'font-18-600 n_10'}>벌초 구인/구직 글</p>
            <p className={'font-14-500 n_40'}>벌초 의뢰 또는 대행 등을 등록하세요.</p>
          </IonLabel>
        </IonItem>
      </IonList>
    </IonContent>
  );
};

interface Props {
  dismiss: (type?: SellType) => void;
}
