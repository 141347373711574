import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../AppRoutes';
import { useSendAuthSmsMutation } from '../../generated/graphql';

export const Login = () => {
  const [phone, setPhone] = useState<string>('');
  // const [testCode, setTestCode] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  const router = useIonRouter();

  const [login] = useSendAuthSmsMutation();

  const sendSms = async () => {
    const res = await login({
      variables: {
        toPhone: phone,
        // testYn: 'Y',
      },
    });
    if (res.data?.sendAuthLoginSms?.status === 'success') {
      // setTestCode(res.data.sendAuthLoginSms!.message!);
      // setVerificationSent(true);
      router.push(appRoutes.phoneVerify.path(phone));
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>로그인</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <div className={'page-container'}>
          <div className={'font-20-500'}>
            휴대폰 번호를 입력하여
            <br />
            로그인 해 주세요.
          </div>
          <input
            className={'large-input'}
            type={'tel'}
            placeholder={'-없이 숫자만 입력'}
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
          <IonButton
            color={'primary'}
            expand={'block'}
            size={'large'}
            style={{ width: '100%' }}
            disabled={!phone}
            // onClick={() => setVerificationSent(true)}
            onClick={() => sendSms()}
          >
            인증코드 받기
          </IonButton>
          <div className={'font-14-400 n_30'}>휴대폰 번호가 변경되었나요?</div>
          <div className={'flex-row-between'}>
            <Link to={''}>이메일로 인증하기</Link>
            <Link to={appRoutes.myInfo.children.customerCenter.path()}>고객센터에 문의하기</Link>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
