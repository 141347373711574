import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { IonButton, useIonAlert } from '@ionic/react';
import { useGetPotentialItemBuyersQuery, useSearchAccountsByPhoneLazyQuery } from '../../../generated/graphql';
import { SellerInfoCard } from '../../../common/components/SellerInfoCard';

interface Account {
  id: string;
  nickname: string;
  address: string;
  stars: number;
  reviews: number;
}

export const SearchBuyerList: React.FC<{ itemId: string; onSelect: (id: string) => void }> = ({ itemId, onSelect }) => {
  const [presentAlert] = useIonAlert();
  const [searchResults, setSearchResults] = useState<Account[]>();
  const searchRefetch = useRef<() => void>();

  const potentialBuyersRes = useGetPotentialItemBuyersQuery({ variables: { itemId: ~~itemId } });
  const potentialBuyers = potentialBuyersRes.data?.searchChatRoomListByItemId;
  const [searchByPhone] = useSearchAccountsByPhoneLazyQuery();

  const handleSearch = async () => {
    if (searchResults) {
      setSearchResults(undefined);
    } else {
      await presentAlert({
        header: '연락처로 사람 찾기',
        buttons: [
          '취소',
          {
            text: '검색',
            handler: data => {
              searchByPhone({ variables: { phone: data.phone } }).then(res => {
                setSearchResults(
                  res.data?.searchAccountListByPhone?.map(account => ({
                    id: account?.accountId ?? '',
                    nickname: account?.nickName ?? '',
                    address: account?.address ?? '',
                    reviews: account?.reviewCount ?? 0,
                    stars: account?.avgStarPoint ?? 0,
                  })),
                );
                searchRefetch.current = res.refetch;
              });
            },
          },
        ],
        inputs: [
          {
            placeholder: '휴대폰 번호를 입력하세요',
            type: 'tel',
            name: 'phone',
          },
        ],
      });
    }
  };

  return (
    <Container>
      <Row>
        <div className={'font-16-600'}>구매자 선택</div>
        <IonButton className={'square-button'} fill={'outline'} onClick={handleSearch}>
          {searchResults ? '초기화' : '사람 찾기'}
        </IonButton>
      </Row>

      {!searchResults &&
        potentialBuyers &&
        potentialBuyers.map(account =>
          account ? (
            <SellerInfoCard
              key={`potential_result_${account.fromAccount.accountId}`}
              sellerId={account.fromAccount.accountId}
              sellerName={account.fromAccount.nickName ?? ''}
              addressName={account.fromAccount.address ?? ''}
              avgStarPoint={account.fromAccount.avgStarPoint ?? 0}
              reviewCount={account.fromAccount.reviewCount ?? 0}
              onClick={() => onSelect(account?.fromAccount.accountId)}
              refetch={potentialBuyersRes.refetch}
            />
          ) : null,
        )}

      {searchResults &&
        searchResults.map(account => (
          <SellerInfoCard
            key={`search_result_${account.id}`}
            sellerId={account.id}
            sellerName={account.nickname}
            addressName={account.address}
            avgStarPoint={account.stars}
            reviewCount={account.reviews}
            onClick={() => onSelect(account.id)}
            refetch={searchRefetch.current}
          />
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;
