export const generateRandomString = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const getShortAddress = (address: string): string => {
  if (!address) return '';

  const parts = address.split(' ');
  if (parts && parts.length > 1) {
    return parts[0] + ' ' + parts[1];
  }
  return '';
};

export const getCategoryLabel = (fullCategory: string): string => {
  if (!fullCategory) return '';

  const parts = fullCategory.split(' > ');
  if (parts && parts.length > 1) {
    return parts[parts.length - 1];
  }
  return '';
};

export const makeContentsShort = (contents: string): string => {
  if (!contents) return '';

  if (contents.length > 100) {
    return contents.substr(0, 100) + '...';
  }
  return contents;
};

export const isDevEnv = () => process.env.REACT_APP_ENV === 'dev';
export const getApiUrl = () => (isDevEnv() ? 'http://newindev.startlump.com/graphql' : 'https://newind.startlump.com/graphql');
