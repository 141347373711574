import React from 'react';

export const NavIcon: React.FC<Props> = ({ icon, color, active }) => {
  const getIcon = () => {
    switch (icon) {
      case 'list':
        return (
          <svg className={'tab-icon'} width='22' height='19' viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M20.75 9.50033L7.75 9.50033M20.75 3.00033L7.75 3.00033M20.75 16.0003L7.75 16.0003M3.41667 9.50033C3.41667 10.0986 2.93164 10.5837 2.33333 10.5837C1.73502 10.5837 1.25 10.0986 1.25 9.50033C1.25 8.90202 1.73502 8.41699 2.33333 8.41699C2.93164 8.41699 3.41667 8.90202 3.41667 9.50033ZM3.41667 3.00033C3.41667 3.59863 2.93164 4.08366 2.33333 4.08366C1.73502 4.08366 1.25 3.59863 1.25 3.00033C1.25 2.40202 1.73502 1.91699 2.33333 1.91699C2.93164 1.91699 3.41667 2.40202 3.41667 3.00033ZM3.41667 16.0003C3.41667 16.5986 2.93164 17.0837 2.33333 17.0837C1.73502 17.0837 1.25 16.5986 1.25 16.0003C1.25 15.402 1.73502 14.917 2.33333 14.917C2.93164 14.917 3.41667 15.402 3.41667 16.0003Z'
              stroke={color}
              strokeWidth={active ? '3' : '2'}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        );
      case 'heart-square':
        return (
          <svg className={'tab-icon'} width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M1 6.3C1 4.61984 1 3.77976 1.32698 3.13803C1.6146 2.57354 2.07354 2.1146 2.63803 1.82698C3.27976 1.5 4.11984 1.5 5.8 1.5H14.2C15.8802 1.5 16.7202 1.5 17.362 1.82698C17.9265 2.1146 18.3854 2.57354 18.673 3.13803C19 3.77976 19 4.61984 19 6.3V14.7C19 16.3802 19 17.2202 18.673 17.862C18.3854 18.4265 17.9265 18.8854 17.362 19.173C16.7202 19.5 15.8802 19.5 14.2 19.5H5.8C4.11984 19.5 3.27976 19.5 2.63803 19.173C2.07354 18.8854 1.6146 18.4265 1.32698 17.862C1 17.2202 1 16.3802 1 14.7V6.3Z'
              stroke={color}
              strokeWidth={active ? '3' : '2'}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.99658 7.56791C8.9969 6.3992 7.32987 6.08482 6.07735 7.15501C4.82482 8.22519 4.64848 10.0145 5.6321 11.2802C6.26211 12.0909 7.87558 13.5942 8.95424 14.5704C9.31269 14.8947 9.49191 15.0569 9.70659 15.122C9.89113 15.1779 10.102 15.1779 10.2866 15.122C10.5012 15.0569 10.6805 14.8947 11.0389 14.5704C12.1176 13.5942 13.731 12.0909 14.3611 11.2802C15.3447 10.0145 15.1899 8.21393 13.9158 7.15501C12.6417 6.09608 10.9963 6.3992 9.99658 7.56791Z'
              stroke={color}
              strokeWidth={active ? '3' : '2'}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        );
      case 'chatting':
        return (
          <svg className={'tab-icon'} width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M5.5 9H5.51M10 9H10.01M14.5 9H14.51M5 16.5V18.8355C5 19.3684 5 19.6348 5.10923 19.7716C5.20422 19.8906 5.34827 19.9599 5.50054 19.9597C5.67563 19.9595 5.88367 19.7931 6.29976 19.4602L8.68521 17.5518C9.17252 17.162 9.41617 16.9671 9.68749 16.8285C9.9282 16.7055 10.1844 16.6156 10.4492 16.5613C10.7477 16.5 11.0597 16.5 11.6837 16.5H14.2C15.8802 16.5 16.7202 16.5 17.362 16.173C17.9265 15.8854 18.3854 15.4265 18.673 14.862C19 14.2202 19 13.3802 19 11.7V6.3C19 4.61984 19 3.77976 18.673 3.13803C18.3854 2.57354 17.9265 2.1146 17.362 1.82698C16.7202 1.5 15.8802 1.5 14.2 1.5H5.8C4.11984 1.5 3.27976 1.5 2.63803 1.82698C2.07354 2.1146 1.6146 2.57354 1.32698 3.13803C1 3.77976 1 4.61984 1 6.3V12.5C1 13.43 1 13.895 1.10222 14.2765C1.37962 15.3117 2.18827 16.1204 3.22354 16.3978C3.60504 16.5 4.07003 16.5 5 16.5ZM6 9C6 9.27614 5.77614 9.5 5.5 9.5C5.22386 9.5 5 9.27614 5 9C5 8.72386 5.22386 8.5 5.5 8.5C5.77614 8.5 6 8.72386 6 9ZM10.5 9C10.5 9.27614 10.2761 9.5 10 9.5C9.72386 9.5 9.5 9.27614 9.5 9C9.5 8.72386 9.72386 8.5 10 8.5C10.2761 8.5 10.5 8.72386 10.5 9ZM15 9C15 9.27614 14.7761 9.5 14.5 9.5C14.2239 9.5 14 9.27614 14 9C14 8.72386 14.2239 8.5 14.5 8.5C14.7761 8.5 15 8.72386 15 9Z'
              stroke={color}
              strokeWidth={active ? '3' : '2'}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        );
      case 'user':
        return (
          <svg className={'tab-icon'} width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M9.99985 12.9442C6.82977 12.9442 4.01065 14.4182 2.21585 16.7055C1.82956 17.1978 1.63641 17.444 1.64273 17.7766C1.64761 18.0337 1.81521 18.3579 2.02522 18.5166C2.29704 18.722 2.67372 18.722 3.42708 18.722H16.5726C17.326 18.722 17.7027 18.722 17.9745 18.5166C18.1845 18.3579 18.3521 18.0337 18.357 17.7766C18.3633 17.444 18.1701 17.1978 17.7839 16.7055C15.9891 14.4182 13.1699 12.9442 9.99985 12.9442Z'
              stroke={color}
              strokeWidth={active ? '3' : '2'}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M9.99985 10.0553C12.4851 10.0553 14.4999 8.11524 14.4999 5.722C14.4999 3.32877 12.4851 1.38867 9.99985 1.38867C7.51457 1.38867 5.49985 3.32877 5.49985 5.722C5.49985 8.11524 7.51457 10.0553 9.99985 10.0553Z'
              stroke={color}
              strokeWidth={active ? '3' : '2'}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        );
    }
  };

  return <>{getIcon()}</>;
};

interface Props {
  icon: 'list' | 'heart-square' | 'chatting' | 'user';
  color: string;
  active: boolean;
}
