import { IonChip, IonSearchbar, useIonModal } from '@ionic/react';
import { FleaMarketItem } from './FleaMarketItem';
import { allAreas, ModalSearchFilter } from '../../../common/components/modals/ModalSearchFilter';
import React, { useEffect, useRef, useState } from 'react';
import {
  Category,
  useGetMyAccountQuery,
  useGetSearchItemListByParamQuery,
  useItemUpByIdMutation,
  useSearchCategoryListQuery,
  useToggleAttItemMutation,
} from '../../../generated/graphql';

export const FleaMarket: React.FC<Props> = ({ parentId, type }) => {
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [selectedAreas, setSelectedAreas] = useState<string[]>(allAreas);
  const { data } = useSearchCategoryListQuery();
  const filteredCategories = useRef<(Category | null)[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [includeSoldItems, setIncludeSoldItems] = useState(false);
  const user = useGetMyAccountQuery().data?.searchMyAccount;

  const [toggleAttItem] = useToggleAttItemMutation();

  const [pullItemUp] = useItemUpByIdMutation();

  const searchResults = useGetSearchItemListByParamQuery({
    variables: {
      itemSearchParam: {
        type,
        category: selectedCategories.map(c => c && c.categoryId),
        title: searchTerm,
        status: includeSoldItems ? ['ING', 'RES', 'END'] : ['ING'],
      },
    },
    fetchPolicy: 'network-only',
  });

  const [present, dismiss] = useIonModal(ModalSearchFilter, {
    onDismiss: (
      data: {
        categories: (Category | null)[];
        areas: string[];
      } | null,
      role: string,
      includeSold: boolean,
    ) => {
      if (data && role === 'confirm') {
        const selected = data.categories.filter((c): c is Category => c !== null);
        console.log('selected', selected);
        setSelectedCategories(selected);
        setSelectedAreas(data.areas);
      }

      dismiss(data, role);
    },
    categories: selectedCategories,
    areas: selectedAreas,
    parentCategoryId: parentId,
  });

  useEffect(() => {
    const categories = data?.searchCategoryList?.filter((c): c is Category => !!c && c.parentId === parentId) ?? [];
    setSelectedCategories(categories);
    filteredCategories.current = data?.searchCategoryList?.filter(c => c?.parentId === parentId) ?? [];
  }, [data, parentId]);

  const openSearchModal = () => {
    present();
  };

  const handleRemoveCategory = (category: Category | null) => {
    if (selectedCategories?.length === 1) {
      const categories = data?.searchCategoryList?.filter((c): c is Category => !!c && c.parentId === parentId) ?? [];
      setSelectedCategories(categories);
    } else {
      setSelectedCategories(prev => prev?.filter(c => c?.categoryId !== category?.categoryId));
    }
  };

  const handleRemoveArea = (val: string) => {
    if (selectedAreas.length === 1) {
      setSelectedAreas(allAreas);
    } else {
      setSelectedAreas(prev => prev.filter(area => area !== val));
    }
  };

  const handleRegisterInterest = async (itemId?: string) => {
    if (itemId) {
      await toggleAttItem({ variables: { itemId: ~~itemId } });
      void searchResults.refetch();
    }
  };

  const handleItemUpById = async (itemId?: string) => {
    if (itemId) {
      await pullItemUp({ variables: { itemId: ~~itemId } });
      void searchResults.refetch();
    }
  };

  return (
    <div className={'ion-padding-top flex-col-center-20'}>
      <IonSearchbar
        mode={'ios'}
        placeholder={'원하는 물품을 검색해보세요'}
        value={searchTerm}
        onIonInput={e => setSearchTerm(e.detail.value as string)}
        debounce={250}
      />
      <div className={'flex-row-start'} style={{ width: '100%', gap: 0 }}>
        <div>
          {selectedCategories?.length === filteredCategories.current.length && (
            <IonChip outline={true} mode={'ios'} color={'tertiary'} className={'font-12-500'}>
              물품 전체
            </IonChip>
          )}
          {selectedCategories?.length !== filteredCategories.current.length &&
            selectedCategories?.map((c, i) => (
              <IonChip
                outline={true}
                key={`selected_type_${i}`}
                mode={'ios'}
                color={'tertiary'}
                className={'font-12-500'}
                onClick={() => handleRemoveCategory(c)}
              >
                {c?.name}
              </IonChip>
            ))}

          {selectedAreas.length === 7 && (
            <IonChip outline={true} mode={'ios'} color={'tertiary'} className={'font-12-500'}>
              지역 전체
            </IonChip>
          )}
          {selectedAreas.length !== 7 &&
            selectedAreas.map((area, i) => (
              <IonChip
                outline={true}
                key={`selected_area_${i}`}
                mode={'ios'}
                color={'tertiary'}
                className={'font-12-500'}
                onClick={() => handleRemoveArea(area)}
              >
                {area}
              </IonChip>
            ))}
        </div>
        <div
          style={{ flexGrow: '2', textAlign: 'end', minWidth: 'max-content' }}
          className={'color-tertiary font-14-500'}
          onClick={openSearchModal}
        >
          상세선택
        </div>
      </div>

      {searchResults.data?.searchItemListByParam?.map(item => (
        <FleaMarketItem
          key={`item_${item?.itemId}`}
          item={item}
          onRegisterInterest={() => handleRegisterInterest(item?.itemId)}
          onItemUp={() => handleItemUpById(item?.itemId)}
          isOwnItem={item?.account.accountId === user?.accountId}
        />
      ))}
    </div>
  );
};

interface Props {
  parentId: string;
  type: string;
}
