import React, { createContext, useContext, useEffect, useState } from 'react';
import { getSavedAuthToken, KEY_SAVED_AUTH_TOKEN } from '../utils/storage';
import { useIonRouter } from '@ionic/react';
import { appRoutes } from '../../AppRoutes';
import { useLocation } from 'react-router-dom';

export const AuthContext = createContext(false);

export const useIsAuthenticated = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const router = useIonRouter();
  const location = useLocation();

  useEffect(() => {
    const res = getSavedAuthToken();
    if (res) {
      setIsAuthenticated(true);
    }

    const handleEvent = (auth: boolean) => {
      setIsAuthenticated(auth);
    };

    window.addEventListener(KEY_SAVED_AUTH_TOKEN, ((e: CustomEvent) => {
      handleEvent(e.detail);
    }) as EventListener);

    // Todo cleanup
  }, []);

  useEffect(() => {
    for (const [k, v] of Object.entries(appRoutes)) {
      // Find current route
      if (location.pathname.includes(v.key)) {
        // If route requires authentication and not logged in redirect to landing page
        if (v.auth && !isAuthenticated) {
          return router.push(appRoutes.landingPage.path(), 'forward', 'replace');
        }

        // If route is related login/signup and user is logged in redirect to allItems
        if ((k === 'landingPage' || k === 'login' || k === 'signUp' || k === 'phoneVerify') && isAuthenticated) {
          return router.push(appRoutes.allItems.path());
        }

        break;
      }
    }
  }, [isAuthenticated, location]);

  return <AuthContext.Provider value={isAuthenticated}>{children}</AuthContext.Provider>;
};
