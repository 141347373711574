import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloLink, ApolloProvider, concat, fromPromise, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getSavedAuthToken, getSavedRefreshToken, setSavedAuthToken, setSavedRefreshToken } from './common/utils/storage';
import { getApiUrl } from './common/utils/utils';
import { toast } from 'react-toastify';

// const httpLink = new HttpLink({ uri: 'https://vil-admin.shinbaram.net/graphql' });
// const httpLink = new HttpLink({ uri: 'http://newindev.startlump.com/graphql' });
// const httpLink = new HttpLink({ uri: 'http://localhost:8080/graphql' });
const httpLink = new HttpLink({ uri: getApiUrl() });

const authLink = setContext((_, { headers }) => {
  const token = getSavedAuthToken();
  if (token) {
    return {
      headers: {
        ...headers,
        'X-AUTH-TOKEN': token,
      },
    };
  } else {
    return { header: { ...headers } };
  }
});

const toastLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const data = response?.data;
    if (!data) return response;

    for (const [k] of Object.entries(data)) {
      if (!!data[k] && 'status' in data[k] && 'message' in data[k] && data[k].message.length) {
        if (data[k].status === 'success') {
          toast.success(data[k].message);
        }
        if (data[k].status === 'fail') {
          toast.error(data[k].message);
        }
      }
    }

    return response;
  });
});

const getNewToken = async () => {
  const myHeaders = new Headers();
  myHeaders.append('X-AUTH-TOKEN', getSavedAuthToken() ?? '');
  myHeaders.append('Content-Type', 'application/json');

  const token = getSavedAuthToken();
  const refreshToken = getSavedRefreshToken();

  if (!token || !refreshToken) return Promise.reject();

  try {
    const query = `mutation refreshToken($token: String, $refreshToken: String) {\n refreshToken(token: $token, refreshToken: $refreshToken) {\n message\n status\n data\n detailStatus\n}\n}`;
    const variables = { token, refreshToken };
    const response = await fetch(getApiUrl(), {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ query, variables }),
      redirect: 'follow',
    });

    const data = await response.json();
    const parsed = JSON.parse(data.data.refreshToken.data);
    setSavedRefreshToken(parsed.refreshToken);
    setSavedAuthToken(parsed.token);
    return parsed.token;
  } catch (e) {
    console.error(e);
  }
};

let isRefreshing = false;
let pendingRequests: (() => void)[] = [];

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // console.log(graphQLErrors, networkError, operation);
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      console.error(err);
      switch (err.extensions.errorCode) {
        case 401: {
          // error code is set to UNAUTHENTICATED
          // when AuthenticationError thrown in resolver
          let forward$;

          if (!isRefreshing) {
            isRefreshing = true;
            forward$ = fromPromise(
              getNewToken()
                .then(accessToken => {
                  // Store the new tokens for your auth link
                  resolvePendingRequests();
                  return accessToken;
                })
                .catch(error => {
                  console.error(error);
                  pendingRequests = [];
                  setSavedAuthToken(null);
                  setSavedRefreshToken(null);
                  // window.location.href = '/';
                  return;
                })
                .finally(() => {
                  isRefreshing = false;
                }),
            ).filter(value => Boolean(value));
          } else {
            // Will only emit once the Promise is resolved
            forward$ = fromPromise(
              new Promise<void>(resolve => {
                pendingRequests.push(() => resolve());
              }),
            );
          }

          return forward$.flatMap(() => forward(operation));
        }
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry
  }
});

const client = new ApolloClient({
  link: concat(errorLink, concat(authLink, concat(toastLink, httpLink))),
  cache: new InMemoryCache(),
  defaultOptions: {},
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
