import React from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useParams } from 'react-router';
import { useGetAccountByIdQuery } from '../../generated/graphql';
import { ReviewList } from '../../common/components/ReviewList';

export const SellerReviews: React.FC = () => {
  const { sellerId } = useParams<{ sellerId: string }>();
  const seller = useGetAccountByIdQuery({ variables: { accountId: ~~sellerId } }).data?.searchAccountById;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>{seller?.nickName ?? ''} 님이 받은 거래 후기</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        {seller && (
          <ReviewList
            reviews={
              seller.reviewList?.map(r => ({
                sellerId: r?.buyerAccount?.accountId ?? '',
                nickname: r?.buyerAccount?.nickName ?? '',
                diffTime: r?.diffTime ?? '',
                review: r?.review ?? '',
              })) ?? []
            }
          />
        )}
      </IonContent>
    </IonPage>
  );
};
