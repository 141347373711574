import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { IonInfiniteScrollCustomEvent } from '@ionic/core/dist/types/components';

const API_KEY = 'KakaoAK 64005d0c4d619fb5b3190f6cdeaea05c';

interface Props {
  query: string;
  onSelect: (address: string) => void;
}

interface KakaoResult {
  address_name: string;
  place_name: string;
  road_address_name: string;
  id: string;
}

export const AddressSearch: React.FC<Props> = ({ query, onSelect }) => {
  const [results, setResults] = useState<KakaoResult[]>([]);
  const [selected, setSelected] = useState(false);

  const page = useRef(1);
  const infiniteScroll = useRef<HTMLIonInfiniteScrollElement>(null);

  useEffect(() => {
    window.addEventListener('scroll', e => console.log(e), false);
  }, []);

  useEffect(() => {
    setSelected(false);
    page.current = 1;
    if (infiniteScroll.current) {
      infiniteScroll.current.disabled = false;
    }
    kakaoSearch();
  }, [query]);

  const handleSelect = (address: string) => {
    setSelected(true);
    setResults([]);
    onSelect(address);
  };

  const kakaoSearch = async (e?: IonInfiniteScrollCustomEvent<void>) => {
    if (selected) return;
    fetch(`https://dapi.kakao.com/v2/local/search/keyword.json?query=${query}&page=${page.current}`, {
      headers: {
        Authorization: API_KEY,
      },
    })
      .then(res => {
        if (res.ok && res.status === 200) {
          res
            .json()
            .then(json => {
              console.log(json);
              if (e) {
                setResults(prev => [...prev, ...json.documents]);
                e.target.complete();
                if (json.meta.is_end) {
                  e.target.disabled = true;
                }
              } else {
                setResults(json.documents as KakaoResult[]);
              }
              page.current = page.current + 1;
            })
            .catch(e => console.error(e));
        }
      })
      .catch(e => console.error(e));
  };

  return (
    <Container>
      {results.map(r => (
        <IonCard
          key={r.id}
          style={{ margin: '20px auto', width: '98% ' }}
          onClick={() => handleSelect(`${r.road_address_name} ${r.place_name}`)}
        >
          <IonCardHeader>
            <IonCardTitle>{r.place_name}</IonCardTitle>
            <IonCardSubtitle>{r.road_address_name}</IonCardSubtitle>
          </IonCardHeader>
        </IonCard>
      ))}
      <IonInfiniteScroll
        ref={infiniteScroll}
        threshold={'0'}
        onIonInfinite={e => {
          console.log('infinite');
          kakaoSearch(e);
        }}
      >
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 60px;
`;
