import React, { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonCheckbox, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { getSavedSearchOptions, setSavedSearchOptions } from '../../utils/storage';
import { Category, useSearchCategoryListQuery } from '../../../generated/graphql';

export const ModalSearchFilter: React.FC<Props> = ({ onDismiss, categories, areas, parentCategoryId }) => {
  const [selectedCategories, setSelectedCategories] = useState<(Category | null)[]>(categories);
  const [selectedAreas, setSelectedAreas] = useState<string[]>(areas);
  const [saveOptions, setSaveOptions] = useState(false);
  const [includeSoldItems, setIncludeSoldItems] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState<(Category | null)[]>([]);

  const { data } = useSearchCategoryListQuery();
  useEffect(() => {
    if (data?.searchCategoryList) {
      // console.log(data.searchCategoryList, parentCategoryId)
      setFilteredCategories(data?.searchCategoryList?.filter(c => c?.parentId === parentCategoryId));
    }
  }, [data]);

  useEffect(() => {
    const res = getSavedSearchOptions();
    if (res && res.shouldSave && !categories.length && !areas.length) {
      setSaveOptions(true);
      setSelectedCategories(res.selectedTypes);
      setSelectedAreas(res.selectedAreas);
    }
  }, []);

  const handleToggleType = (type: Category | null) => {
    if (selectedCategories.includes(type)) {
      setSelectedCategories(prevState => prevState.filter(t => t !== type));
    } else {
      setSelectedCategories(prevState => [...prevState, type]);
    }
  };

  const handleToggleArea = (area: string) => {
    if (selectedAreas.includes(area)) {
      setSelectedAreas(prevState => prevState.filter(a => a !== area));
    } else {
      setSelectedAreas(prevState => [...prevState, area]);
    }
  };

  useEffect(() => {
    if (saveOptions) {
      setSavedSearchOptions({ shouldSave: true, selectedTypes: selectedCategories, selectedAreas });
    } else {
      const res = getSavedSearchOptions();
      if (res) {
        setSavedSearchOptions({ ...res, shouldSave: false });
      } else {
        setSavedSearchOptions({ selectedTypes: [], selectedAreas: [], shouldSave: false });
      }
    }
  }, [saveOptions, selectedCategories, selectedAreas]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonButton color={'tertiary'} onClick={() => onDismiss(null, 'cancel', includeSoldItems)}>
              취소
            </IonButton>
          </IonButtons>
          <IonTitle>원하는 항목을 선택하세요</IonTitle>
          <IonButtons slot='end'>
            <IonButton
              color={'primary'}
              onClick={() =>
                onDismiss(
                  {
                    categories: selectedCategories,
                    areas: selectedAreas,
                  },
                  'confirm',
                  includeSoldItems,
                )
              }
            >
              확인
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding flex-col-center-20'>
        <div className={'font-16-600 n_30 ion-align-self-start'} style={{ margin: '14px 0' }}>
          물품 종류
        </div>
        <IonButton
          className={'square-button'}
          expand={'block'}
          fill={'outline'}
          size={'default'}
          color={selectedCategories?.length === filteredCategories.length ? 'primary' : 'tertiary'}
          onClick={() => setSelectedCategories(filteredCategories)}
        >
          전체선택
        </IonButton>

        <div className={'grid-container'} style={{ marginBottom: '20px' }}>
          {filteredCategories.map((type, i) => (
            <IonButton
              className={'square-button light-contrast-button'}
              fill={'outline'}
              key={`item_type_${i}`}
              color={selectedCategories?.includes(type) ? 'primary' : 'tertiary'}
              onClick={() => handleToggleType(type)}
            >
              {type?.name}
            </IonButton>
          ))}
        </div>

        <div className={'font-16-600 n_30 ion-align-self-start'} style={{ margin: '14px 0' }}>
          지역
        </div>
        <IonButton
          className={'square-button'}
          expand={'block'}
          fill={'outline'}
          size={'default'}
          color={selectedAreas?.length === areas?.length ? 'primary' : 'tertiary'}
          onClick={() => setSelectedAreas(areas)}
        >
          전체선택
        </IonButton>
        <div className={'grid-container'}>
          {allAreas.map((area, i) => (
            <IonButton
              className={'square-button'}
              fill={'outline'}
              key={`item_type_${i}`}
              onClick={() => handleToggleArea(area)}
              color={selectedAreas.includes(area) ? 'primary' : 'tertiary'}
            >
              {area}
            </IonButton>
          ))}
        </div>
        <hr />
        <IonItem lines={'none'}>
          <IonCheckbox slot={'start'} checked={saveOptions} onIonChange={() => setSaveOptions(prevState => !prevState)} />
          <IonLabel color={includeSoldItems ? 'primary' : 'tertiary'}>선택한 내용 저장해두기</IonLabel>
        </IonItem>
        <hr />
        {/*<IonItem lines={'none'}>*/}
        {/*  <IonCheckbox slot={'start'} checked={includeSoldItems} onIonChange={() => setIncludeSoldItems(prevState => !prevState)} />*/}
        {/*  <IonLabel color={includeSoldItems ? 'primary' : 'tertiary'}>판매완료글 제외하기</IonLabel>*/}
        {/*</IonItem>*/}
        <hr />
      </IonContent>
    </IonPage>
  );
};

interface Props {
  onDismiss: (data: { categories: (Category | null)[]; areas: string[] } | null, role: string, includeSold: boolean) => void;
  categories: (Category | null)[];
  areas: string[];
  parentCategoryId: string;
}

export const allAreas = ['서울/경기', '강원도', '충청도', '전라도', '경상도', '제주도', '기타'];
