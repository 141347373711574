import React, { useEffect, useMemo, useState } from 'react';
import { useAddOftenPhraseMutation, useDeleteOftenPhraseMutation, useGetOftenPhrasesQuery } from '../../generated/graphql';
import styled from 'styled-components/macro';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonTextarea,
  useIonAlert,
} from '@ionic/react';
import { trash } from 'ionicons/icons';

export const OftenPhrasesModal: React.FC<{ dismiss: (phrase?: string) => void }> = ({ dismiss }) => {
  const [input, setInput] = useState('');
  const phrasesResult = useGetOftenPhrasesQuery();
  const phrases = useMemo(() => phrasesResult.data?.searchMyOftenMessageList ?? [], [phrasesResult]);
  const [addPhrase, addResult] = useAddOftenPhraseMutation();
  const [deletePhrase, deleteResult] = useDeleteOftenPhraseMutation();

  const [presentAlert] = useIonAlert();

  const handleAddPhrase = async () => {
    if (phrases?.length >= 10) {
      await presentAlert({
        message: '문구는 10깨까지 저장이 가능합니다',
        buttons: ['확인'],
      });
      return;
    }
    if (input.length) {
      await addPhrase({ variables: { oftenPhrase: { message: input, account: {} } } });
      setInput('');
      phrasesResult.refetch();
    }
  };

  const handleDeletePhrase = async (id: string) => {
    await deletePhrase({ variables: { id: ~~id } });
    phrasesResult.refetch();
  };

  return (
    <IonContent className={'no-scrollbar'}>
      <Container>
        {phrases && phrases.length > 0 && (
          <PhraseWrapper>
            <IonList>
              {phrases.map(phrase =>
                phrase ? (
                  <IonItemSliding key={phrase.messageId}>
                    <IonItem onClick={() => dismiss(phrase.message ?? '')}>
                      <Phrase>{phrase.message}</Phrase>
                    </IonItem>

                    <IonItemOptions>
                      <IonItemOption color={'danger'} onClick={() => handleDeletePhrase(phrase.messageId)}>
                        <IonIcon size={'large'} icon={trash} />
                      </IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                ) : null,
              )}
            </IonList>
          </PhraseWrapper>
        )}
        {!phrases ||
          (phrases.length === 0 && (
            <NoPhrases className={'font-16-400'}>
              자주쓰는 문구를 저장해 놓으면 편리합니다. <br /> <br />
              예) 직거래만 합니다. 제가 있는 곳으로 와주셔야 합니다.
            </NoPhrases>
          ))}
        <Footer>
          <IonTextarea
            rows={3}
            placeholder={'문구를 입력하세요'}
            value={input}
            onIonInput={e => setInput((e.detail?.value?.trim() as string) ?? '')}
          />
          <IonButton color={'primary'} onClick={handleAddPhrase}>
            저장
          </IonButton>
        </Footer>
      </Container>
    </IonContent>
  );
};

const Container = styled.div`
  --_footer-height: 104px;
  width: 100%;
  height: 60vh;
`;

const PhraseWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: calc(60vh - var(--_footer-height));
  padding-top: 20px;
  overflow: auto;
`;

const Phrase = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  word-break: break-word;
`;

const NoPhrases = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  max-height: calc(60vh - var(--_footer-height));
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: keep-all;
  white-space: break-spaces;
`;

const Footer = styled.div`
  margin: auto;
  width: 100%;
  height: --_footer-height;
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid black !important;
  gap: 0.5rem;

  > ion-textarea textarea {
    border-radius: 8px !important;
  }
`;
