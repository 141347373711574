import React, { useState } from 'react';
import { WriteReview } from './components/WriteReview';
import { useParams } from 'react-router';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonAlert, useIonRouter } from '@ionic/react';
import { useGetItemByIdQuery, usePostBuyerReviewMutation } from '../../generated/graphql';
import { ItemCard } from '../../common/components/ItemCard';

export const WriteBuyerReview: React.FC = () => {
  const { itemId, sellId } = useParams<{ itemId: string; sellId: string }>();
  const [point, setPoint] = useState(0);

  const [present] = useIonAlert();
  const router = useIonRouter();

  const item = useGetItemByIdQuery({ variables: { itemId: ~~itemId } }).data?.searchItemById;
  const [postReview] = usePostBuyerReviewMutation();

  const handlePostReview = async (review: string) => {
    if (review && review.length) {
      const result = await postReview({ variables: { sellId: ~~sellId, review, point } });
      if (!result.errors) {
        await present('거래후기 쓰기가 완료되었습니다', [{ text: '확인', handler: () => router.goBack() }]);
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>거래후기 쓰기</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        {item && (
          <ItemCard
            itemId={itemId}
            title={item.title ?? ''}
            type={item.type}
            diffTime={item.diffTime ?? ''}
            price={item.price ?? ''}
            isAttItem={item.isAttItem ?? false}
            attCount={item.attUserCount ?? 0}
          />
        )}
        <WriteReview point={point} onPointChange={setPoint} nickName={item?.account.nickName ?? ''} onComplete={handlePostReview} />
      </IonContent>
    </IonPage>
  );
};
