import React, { useState } from 'react';
import { IonContent, IonPage, IonSegment, IonSegmentButton } from '@ionic/react';
import { NavBar } from '../../common/components/NavBar';
import { MyAttItemList } from './components/MyAttItemList';
import { MyAttSellersList } from './components/MyAttSellersList';

type Section = 'items' | 'sellers';

export const MyInterests: React.FC = () => {
  const [activeSection, setActiveSection] = useState<Section>('items');

  return (
    <IonPage>
      <IonContent className={'ion-padding'}>
        <IonSegment value={activeSection} onIonChange={e => setActiveSection(e.detail.value as Section)} mode={'md'}>
          <IonSegmentButton value={'items'}>관심글</IonSegmentButton>
          <IonSegmentButton value={'sellers'}>관심판매자</IonSegmentButton>
        </IonSegment>

        {activeSection === 'items' && <MyAttItemList />}
        {activeSection === 'sellers' && <MyAttSellersList />}

      </IonContent>
      <NavBar />
    </IonPage>
  );
};
