import { Category } from '../../generated/graphql';
import dayjs from 'dayjs';

interface SavedSearchOptions {
  shouldSave: boolean;
  selectedTypes: (Category | null)[];
  selectedAreas: string[];
}

const KEY_SAVED_SEARCH_OPTIONS = 'KEY_SAVED_SEARCH_OPTIONS';
export const getSavedSearchOptions = (): SavedSearchOptions | null => {
  const res = localStorage.getItem(KEY_SAVED_SEARCH_OPTIONS);
  if (res) {
    return JSON.parse(res) as SavedSearchOptions;
  }
  return null;
};
export const setSavedSearchOptions = (data: SavedSearchOptions) => localStorage.setItem(KEY_SAVED_SEARCH_OPTIONS, JSON.stringify(data));

export const KEY_SAVED_AUTH_TOKEN = 'KEY_SAVED_AUTH_TOKEN';
export const setSavedAuthToken = (token: string | null) => {
  window.dispatchEvent(new CustomEvent<boolean>(KEY_SAVED_AUTH_TOKEN, { detail: !!token }));
  if (token) {
    localStorage.setItem(KEY_SAVED_AUTH_TOKEN, token);
  } else {
    localStorage.removeItem(KEY_SAVED_AUTH_TOKEN);
  }
};

export const getSavedAuthToken = () => localStorage.getItem(KEY_SAVED_AUTH_TOKEN);

const KEY_SAVED_REFRESH_TOKEN = 'KEY_SAVED_REFRESH_TOKEN';
export const setSavedRefreshToken = (token: string | null) =>
  token ? localStorage.setItem(KEY_SAVED_REFRESH_TOKEN, token) : localStorage.removeItem(KEY_SAVED_REFRESH_TOKEN);
export const getSavedRefreshToken = () => localStorage.getItem(KEY_SAVED_REFRESH_TOKEN);

const KEY_SAVED_FCM_TOKEN = 'KEY_SAVED_FCM_TOKEN';
export const setSavedFcmToken = (token: string | null) =>
  token ? localStorage.setItem(KEY_SAVED_FCM_TOKEN, token) : localStorage.removeItem(KEY_SAVED_FCM_TOKEN);
export const getSavedFcmToken = () => localStorage.getItem(KEY_SAVED_FCM_TOKEN);

const KEY_NO_SHOW_NOTICE_IDS = 'no_show_notice_ids';
export const getSavedNoShowNoticeIds = (): string[] => {
  const res = localStorage.getItem(KEY_NO_SHOW_NOTICE_IDS);
  if (res) return JSON.parse(res);
  return [];
};

export const setSavedNoShowNoticeId = (id: string) => {
  const ids = getSavedNoShowNoticeIds();
  ids.push(id);
  localStorage.setItem(KEY_NO_SHOW_NOTICE_IDS, JSON.stringify(ids));
};

const KEY_SHOWN_INSTALL_APP = 'shown_install_app';
export const setLastShownInstallApp = () => localStorage.setItem(KEY_SHOWN_INSTALL_APP, dayjs().format('YYYY-MM-DD').toString());
export const getLastShownInstallApp = () => localStorage.getItem(KEY_SHOWN_INSTALL_APP);
