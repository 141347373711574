import React from 'react';
import { useGetMyAttItemListQuery, useToggleAttItemMutation } from '../../../generated/graphql';
import { ItemCard } from '../../../common/components/ItemCard';
import { NoAttItem } from './NoAttItem';
import { useIonRouter } from '@ionic/react';
import { appRoutes } from '../../../AppRoutes';

export const MyAttItemList: React.FC = () => {
  const { data, refetch } = useGetMyAttItemListQuery({ fetchPolicy: 'network-only' });
  const [toggleAttItem] = useToggleAttItemMutation();

  const router = useIonRouter();

  const handleToggleLikeItem = async (itemId?: string) => {
    if (itemId) {
      await toggleAttItem({ variables: { itemId: ~~itemId } });
      refetch();
    }
  };

  return (
    <>
      {data && data.searchMyAttItemList && data?.searchMyAttItemList.length > 0 && (
        <>
          {data.searchMyAttItemList.map(item =>
            item ? (
              <ItemCard
                onClick={() => router.push(appRoutes.fleaMarketDetail.path(item?.item?.itemId ?? ''))}
                key={`interest_item_${item.item?.itemId}`}
                itemId={item.item?.itemId ?? ''}
                s3Url={item.item?.fileList?.at(0)?.s3Url ?? '/assets/defaultImage.png'}
                title={item.item?.title ?? ''}
                type={item.item?.type ?? ''}
                diffTime={item.item?.diffTime ?? ''}
                price={item.item?.price ?? ''}
                attCount={item.item?.attUserCount ?? 0}
                isAttItem={true}
                onHeartClick={() => handleToggleLikeItem(item?.item?.itemId)}
              />
            ) : null,
          )}
        </>
      )}
      {(!data || !data.searchMyAttItemList || data.searchMyAttItemList.length === 0) && <NoAttItem />}
    </>
  );
};
