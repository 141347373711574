import React, { useEffect, useRef, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonModal,
  useIonRouter,
} from '@ionic/react';
import { useParams } from 'react-router';
import { SellType } from '../../common/components/modals/ModalSellNewItem';
import styled from 'styled-components';
import {
  useDeleteItemMutation,
  useGetItemByIdLazyQuery,
  useGetRootCategoriesQuery,
  usePatchUpdateItemMutation,
  usePostAddItemMutation,
  useSearchCategoryListQuery,
} from '../../generated/graphql';
import { PhotoSelectContainer } from '../../common/components/PhotoSelectContainer';
import { fileToB64, imageResize } from '../../common/utils/photoUtils';
import { generateRandomString } from '../../common/utils/utils';
import { OftenPhrasesModal } from './OftenPhrasesModal';
import { PreviousItemsModal } from './PreviousItemsModal';

const modalOptions = {
  breakpoints: [0, 0.6],
  initialBreakpoint: 0.6,
  backdropBreakpoint: 0.2,
  cssClass: 'rounded-modal',
  backdropDismiss: true,
};

export const SellNewItem: React.FC = () => {
  // type is non-null when selling new item
  // itemId is non-null when editing an item
  const { type, itemId } = useParams<{ type?: SellType; itemId?: string }>();

  const [pageTitle, setPageTitle] = useState('');

  const [photos, setPhotos] = useState<string[]>([]);
  const [itemName, setItemName] = useState<string>();
  const [categoryId, setCategoryId] = useState<string>();
  const [price, setPrice] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [editItemType, setEditItemType] = useState<string>();
  const editItem = useRef<any>();

  const router = useIonRouter();
  const [presentAlert] = useIonAlert();

  const [postAddItem, { data, loading }] = usePostAddItemMutation();
  const [updateItem] = usePatchUpdateItemMutation();
  const [deleteItem] = useDeleteItemMutation();
  const categories = useSearchCategoryListQuery();
  const rootCategories = useGetRootCategoriesQuery();
  const [parentRootCategory, setParentRootCategory] = useState<string>();

  const [presentOftenPhrasesModal, dismissOftenPhraseModal] = useIonModal(OftenPhrasesModal, {
    dismiss: (phrase?: string) => {
      dismissOftenPhraseModal();
      if (phrase) setDescription(phrase);
    },
  });

  const [presentPreviousItemsModal, dismissPreviousItemModal] = useIonModal(PreviousItemsModal, {
    type,
    dismiss: (item: any) => {
      dismissPreviousItemModal();
      if (item) {
        setPhotos(item.fileList?.map((file: any) => file.s3Url) ?? []);
        setItemName(item.title);
        setCategoryId(item.category);
        setPrice(item.price);
        setDescription(item.contents);
      }
    },
  });

  useEffect(() => {
    if (rootCategories.data?.searchRootCategory) {
      setParentRootCategory(rootCategories.data.searchRootCategory.find(rc => rc?.description === type || editItemType)?.categoryId);
    }
  }, [rootCategories, type, editItemType]);

  const [getItem] = useGetItemByIdLazyQuery();
  useEffect(() => {
    if (itemId) {
      getItem({ variables: { itemId: ~~itemId } }).then(res => {
        const item = res.data?.searchItemById;
        console.log(item);
        if (item) {
          editItem.current = item;
          setEditItemType(item.type);
          setPhotos(item.fileList?.map(fl => fl!.s3Url as string) ?? []);
          setItemName(item.title ?? '');
          setPageTitle(`${item.title} 수정하기`);
          setCategoryId(item.category ?? undefined);
          setPrice(item.price ?? undefined);
          setDescription(item.contents ?? undefined);
        }
      });
    }
  }, [itemId]);

  useEffect(() => {
    if (type) {
      switch (type) {
        case 'market':
          setPageTitle('벼룩시장 글쓰기');
          break;
        case 'farm':
          setPageTitle('농산물 글쓰기');
          break;
        case 'estate':
          setPageTitle('부동산 글쓰기');
          break;
        case 'job':
          setPageTitle('일자리 글쓰기');
      }
    }

    if (editItemType) {
      setPageTitle(`${itemName} 수정하기`);
    }
  }, [type, editItemType]);

  const handleSubmit = async () => {
    const fileList = [];

    for (const src of photos) {
      // skip original images when editing
      if (src.includes('amazonaws.com') || src.includes('newindata.startlump.com')) continue;
      const resized = await imageResize(src, false);
      const b64 = await fileToB64(resized.image.file);
      fileList.push({ base64String: b64, fileRealName: `${generateRandomString(10)}.jpeg` });
    }

    // if new item
    if (type) {
      const res = await postAddItem({
        variables: {
          itemParam: {
            type,
            category: categoryId!,
            title: itemName,
            price: price,
            contents: description,
            fileList,
          },
        },
      });
      // console.log(fileList);
      if (res.data?.addItem?.status === 'success') {
        return router.goBack();
      } else {
        // todo - handle error
      }
    }

    // if edit item
    if (itemId && editItemType && categoryId && editItem.current) {
      const res = await updateItem({
        variables: {
          item: {
            itemId,
            category: categoryId,
            type: editItemType,
            title: itemName,
            price,
            contents: description,
            fileList,
          },
          deleteFileIds: editItem.current.fileList.filter((f: any) => !photos.includes(f.s3Url)).map((f: any) => f.fileId),
        },
      });
      if (res.data?.updateItem?.status === 'success') {
        return router.goBack();
      } else {
        // todo - handle error
      }
    }
  };

  const handleDeleteItem = async () => {
    await deleteItem({ variables: { itemId: ~~itemId! } });
    router.goBack();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonButtons slot='end'>
            {itemId && (
              <IonButton
                color={'danger'}
                onClick={() =>
                  presentAlert({
                    message: '정말 삭제하시겠습니까?',
                    mode: 'ios',
                    buttons: [
                      { text: '취소', role: 'cancel' },
                      { text: '네', role: 'destructive', handler: handleDeleteItem },
                    ],
                  })
                }
              >
                삭제
              </IonButton>
            )}
            <IonButton
              disabled={!categoryId || !itemName || !description || (type != 'job' && !photos) || loading}
              className={'font-16-600'}
              onClick={() =>
                presentAlert({
                  message: '저장하시겠습니까? 작성하신 내용이 이용 약관에 위배되는 경우, 불이익을 받을 수 있습니다.',
                  mode: 'ios',
                  buttons: [
                    { text: '네', role: 'confirm', handler: handleSubmit },
                    { text: '취소', role: 'cancel' },
                  ],
                })
              }
            >
              {itemId ? '수정' : '완료'}
            </IonButton>
          </IonButtons>
          <IonTitle>{pageTitle}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <Container>
          <PhotoSelectContainer limit={10} photos={photos} onChange={setPhotos} />

          <InputWrap>
            <IonLabel slot={'start'}>물품명</IonLabel>
            <IonInput
              className={'ion-text-right'}
              placeholder={'물품명 입력'}
              value={itemName}
              onIonInput={e => setItemName(e.detail.value!)}
              mode={'ios'}
            />
            <IonLabel>물품종류</IonLabel>
            <IonSelect
              value={categoryId}
              interface={'popover'}
              placeholder={''}
              justify={'end'}
              onIonChange={e => setCategoryId(e.detail.value)}
            >
              {categories.data?.searchCategoryList
                ?.filter(c => c?.parentId === parentRootCategory)
                .map(c => (
                  <IonSelectOption key={`category_${c?.name}`} value={c?.categoryId}>
                    {c?.name}
                  </IonSelectOption>
                ))}
            </IonSelect>
            <IonLabel slot={'start'}>가격</IonLabel>
            <IonInput
              className={'ion-text-right'}
              value={price}
              type={'text'}
              placeholder={'가격 입력 (선택사항)'}
              onIonInput={e => setPrice(e.detail.value!)}
              mode={'ios'}
            />
          </InputWrap>

          <TextArea
            placeholder={'판매하려는 상품에 대해 자세히 설명해주세요.\n' + '(크기, 맛, 특징 등)\n'}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </Container>
      </IonContent>
      <IonFooter>
        <FooterButtonContainer>
          <FooterButton className={'font-14-500'} onClick={() => presentOftenPhrasesModal(modalOptions)}>
            자주 쓰는 문구
          </FooterButton>
          <Divider />
          <FooterButton className={'font-14-500'} onClick={() => presentPreviousItemsModal(modalOptions)}>
            내가 쓴 다른 글
          </FooterButton>
          {/*<Divider />*/}
          {/*<FooterButton className={'font-14-500'}>판매자 변경</FooterButton>*/}
        </FooterButtonContainer>
      </IonFooter>
    </IonPage>
  );
};

const Container = styled.div`
  --_padding-start: calc(var(--padding-start) + var(--ion-safe-area-left, 0px));
`;

const InputWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  row-gap: 0.5rem;
  column-gap: 1rem;
  padding-inline: var(--_padding-start);

  > *:nth-child(even) {
    border-bottom: 1px solid rgba(190, 190, 190, 0.8);
  }

  > ion-input {
    padding-inline: 1rem;
  }

  > ion-select {
    padding-left: 0;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 280px;
  outline: none;
  border: none;
  background: white;
  margin-top: 1rem;
  padding-left: var(--_padding-start);
`;

const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled.div`
  width: 1px;
  height: 28px;
  border-left: 1px solid var(--neutral-70);
`;

const FooterButton = styled('div')`
  color: black;
  margin: 20px 0;
  padding: 0 20px;
  text-align: center;
  width: 100%;
`;
