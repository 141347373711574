import React from 'react';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { checkmarkCircle, checkmarkCircleOutline } from 'ionicons/icons';
import moment from 'moment';

interface Props {
  selectionMode: boolean;
  selected: boolean;
  partner: string;
  lastMessage: string;
  time: string;
  avatar?: string | null;
  onClick: () => void;
}

const fallbackSrc = '/assets/icon/account.svg';

export const ChatRoomCard: React.FC<Props> = ({ selectionMode, selected, partner, lastMessage, time, avatar, onClick }) => {
  return (
    <Container selectionMode={selectionMode} onClick={onClick}>
      {selectionMode && (
        <Checkmark>
          <IonIcon
            style={{ width: 21, height: 21 }}
            icon={selected ? checkmarkCircle : checkmarkCircleOutline}
            color={selected ? 'primary' : 'dark'}
          />
        </Checkmark>
      )}
      <Avatar src={avatar ?? fallbackSrc} />
      <Content>
        <TopRow>
          <div>{partner}</div>
          <div>{moment(time).format('HH:mm')}</div>
        </TopRow>
        <BottomRow>{lastMessage}</BottomRow>
      </Content>
    </Container>
  );
};

const Container = styled('div')<{ selectionMode: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 17px 0;
`;

const Checkmark = styled.div`
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  width: 100%;

  > :nth-child(1) {
    flex-shrink: 1;
    flex-grow: 1;
  }

  > :nth-child(2) {
    font-size: 12px;
    color: var(--tertiary-60);
  }
`;

const BottomRow = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;
