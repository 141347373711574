import React, { useEffect, useState } from 'react';
import { IonButton, IonSelect, IonSelectOption } from '@ionic/react';
import { SignUpData } from '../SignUp';
import { useCheckPhoneVerificationMutation, useSendPhoneVerificationMutation } from '../../../generated/graphql';

export const SignUpPhone: React.FC<Props> = ({ data, validated, onChange }) => {
  const [phone, setPhone] = useState(data.phone);
  const [verificationCode, setVerificationCode] = useState('');
  const [isValidated, setIsValidated] = useState(false);

  const [sendAuthCode] = useSendPhoneVerificationMutation();
  const [checkAuthCode] = useCheckPhoneVerificationMutation();

  const handleSendCode = async () => {
    await sendAuthCode({ variables: { smsParams: { toPhone: phone } } });
  };

  const handleCheckCode = async () => {
    const res = await checkAuthCode({ variables: { phone, code: verificationCode } });
    if (res.data?.checkAuthJoinSms?.status === 'success') {
      setIsValidated(true);
    }
  };

  useEffect(() => {
    onChange({ ...data, phone: phone.trim() });
    validated(isValidated && phone.trim().length >= 10);
  }, [phone, isValidated]);

  return (
    <>
      <div className={'font-20-500 n_10'}>통신사를 선택하세요.</div>
      <IonSelect className={'large-input'} interface={'action-sheet'} value={'SKT'}>
        <IonSelectOption value={'SKT'}>SKT</IonSelectOption>
        <IonSelectOption value={'LG'}>LG</IonSelectOption>
        <IonSelectOption value={'LG'}>KT</IonSelectOption>
        <IonSelectOption value={'LG'}>기타</IonSelectOption>
      </IonSelect>

      <div className={'font-20-500 n_10'}>휴대폰 번호를 입력하세요.</div>
      <input className={'large-input'} placeholder={'-없이 숫자만 입력'} value={phone} onChange={e => setPhone(e.target.value)} />

      <IonButton expand={'block'} size={'large'} style={{ width: '100%' }} color={'dark'} disabled={!phone} onClick={handleSendCode}>
        인증코드 다시 받기 (5분)
      </IonButton>

      <div className={'inline-input-button'}>
        <input
          className={'large-input'}
          placeholder={'인증코드 입력'}
          value={verificationCode}
          onChange={e => setVerificationCode(e.target.value)}
        />
        <IonButton size={'large'} disabled={!verificationCode} onClick={handleCheckCode}>
          확인
        </IonButton>
      </div>
    </>
  );
};

interface Props {
  data: SignUpData;
  validated: (validated: boolean) => void;
  onChange: (data: SignUpData) => void;
}
