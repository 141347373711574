import React from 'react';
import { Star } from 'react-feather';
import { IonIcon } from '@ionic/react';
import { heart, heartOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../AppRoutes';
import { getShortAddress } from '../utils/utils';
import { useAddAccountBlockByIdMutation, useDeleteAccountBlockMutation, useGetMyAccountQuery, useToggleAttSellerMutation } from '../../generated/graphql';
import styled from 'styled-components/macro';
import { useUnauthenticatedRedirect } from '../hooks/useUnauthenticatedRedirect';

const fallbackSrc = 'assets/icon/account.svg';

export const SellerInfoCard: React.FC<Props> = props => {
  const {
    sellerId,
    s3Url,
    sellerName,
    addressName,
    avgStarPoint,
    reviewCount,
    sellingItems,
    isAttSeller,
    blockId,
    onClick,
    onToggleBlock,
    refetch,
  } = props;
  const [toggleAttSeller] = useToggleAttSellerMutation();

  const [addAccountBlock] = useAddAccountBlockByIdMutation();
  const [deleteAccountBlock] = useDeleteAccountBlockMutation();

  const user = useGetMyAccountQuery().data?.searchMyAccount;

  const redirect = useUnauthenticatedRedirect();

  const handleClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).id === 'heart' || !onClick) return;
    onClick();
  };

  // todo - server returns success but value doesn't change
  const handleToggleAttSeller = async (e: React.MouseEvent) => {
    e.stopPropagation();

    redirect(async () => {
      await toggleAttSeller({ variables: { accountId: ~~sellerId } });
      if (refetch) {
        refetch();
      }
    });
  };

  const cancelSellerBlocked = async (e: React.MouseEvent) => {
    e.stopPropagation();

    redirect(async () => {
      if (blockId == undefined || blockId == null || blockId == 0) return;

      await deleteAccountBlock({ variables: { seq: ~~blockId } });
      if (onToggleBlock) {
        onToggleBlock();
      }
    });
  };

  const setSellerBlocked = async (e: React.MouseEvent) => {
    e.stopPropagation();
    redirect(async () => {
      await addAccountBlock({ variables: { blockedId: ~~sellerId } });
      if (onToggleBlock) {
        onToggleBlock();
      }
    });
  };

  return (
    <>
      <Container onClick={handleClick}>
        <Img src={s3Url ?? fallbackSrc} />
        <Name>
          <div className={'font-16-600'}>{sellerName}</div>
          <div className={'font-12-400'}>{getShortAddress(addressName)}</div>
        </Name>
        <HeartWrap>
          {user && blockId !== undefined && blockId !== null && blockId > 0 && <div onClick={cancelSellerBlocked}>차단 해제</div>}
          {user && blockId == 0 && <div onClick={setSellerBlocked}>차단</div>}
          {user && isAttSeller !== undefined && isAttSeller !== null && (
            <IonIcon
              id={'heart'}
              icon={isAttSeller ? heart : heartOutline}
              color={'primary'}
              className={'s-20'}
              onClick={handleToggleAttSeller}
            />
          )}
        </HeartWrap>
        <InfoWrap>
          <div className={'flex-row'} style={{ gap: '6px' }}>
            <Star size={14} />
            {avgStarPoint}
          </div>
          <span>|</span>
          <Link to={appRoutes.sellerReviews.path(sellerId)}>거래후기 {reviewCount}건</Link>
          {sellingItems && (
            <>
              <span>|</span>
              <span className={'color-primary'}>판매중 {reviewCount ?? 0}건</span>
            </>
          )}
        </InfoWrap>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 60px 1fr 60px;
  grid-template-areas: 'img name heart' 'img info info';
  row-gap: 0.5rem;
  column-gap: 1rem;
  cursor: pointer;
`;

const Img = styled.img`
  width: 100%;
  height: 60px;
  aspect-ratio: 1 / 1;
  grid-area: img;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  grid-area: name;
`;

const HeartWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  word-break: keep-all;
  white-space: nowrap;
  grid-area: heart;
  gap: 0.5rem;
`;

const InfoWrap = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  grid-area: info;
`;

interface Props {
  sellerId: string | number;
  s3Url?: string | null;
  sellerName: string;
  addressName: string;
  avgStarPoint: number;
  reviewCount: number;
  sellingItems?: number;
  isAttSeller?: boolean | null;
  blockId?: number | null;
  onClick?: () => void;
  onToggleBlock?: () => void;
  refetch?: () => void;
}
