import React from 'react';
import { useGetRecentlyViewedItemsQuery, useToggleAttItemMutation } from '../../generated/graphql';
import { ItemCard } from './ItemCard';
import { useIonRouter } from '@ionic/react';
import { appRoutes } from '../../AppRoutes';

export const RecentlyViewedItemList: React.FC = () => {
  const { data, refetch } = useGetRecentlyViewedItemsQuery();
  const [toggleAttItem] = useToggleAttItemMutation();

  const router = useIonRouter();

  const handleHeartClick = async (itemId: string | undefined) => {
    if (itemId) {
      await toggleAttItem({ variables: { itemId: ~~itemId } });
      refetch();
    }
  };

  return (
    <>
      {data &&
        data.searchMyViewItemList &&
        data.searchMyViewItemList.map(
          item =>
            item && (
              <ItemCard
                key={`recent_item_${item.item?.itemId}`}
                itemId={item.item?.itemId ?? ''}
                s3Url={item.item?.fileList?.at(0)?.s3Url ?? '/assets/defaultImage.png'}
                title={item.item?.title ?? ''}
                type={item.item?.type ?? ''}
                diffTime={item.item?.diffTime ?? ''}
                price={item.item?.price ?? ''}
                isAttItem={item.item?.isAttItem ?? false}
                attCount={item.item?.attUserCount ?? 0}
                onHeartClick={() => handleHeartClick(item.item?.itemId)}
                onClick={() => router.push(appRoutes.fleaMarketDetail.path(item?.item?.itemId ?? ''))}
              />
            ),
        )}
    </>
  );
};
