import { IonReactRouter } from '@ionic/react-router';
import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router-dom';
import Home from './pages';
import AllItems from './pages/all-items/AllItems';
import { LandingPage } from './pages/landing-page/LandingPage';
import { Login } from './pages/login/Login';
import { PhoneVerify } from './pages/login/PhoneVerify';
import SignUp from './pages/sign-up/SignUp';
import { FleaMarketItemDetail } from './pages/all-items/FleaMarketItemDetail';
import { SellType } from './common/components/modals/ModalSellNewItem';
import { SellNewItem } from './pages/sell-new-item/SellNewItem';
import { CreateNewJob } from './pages/create-new-job/CreateNewJob';
import { MyInfo } from './pages/my-info/MyInfo';
import { MyInfoEdit } from './pages/my-info/MyInfoEdit';
import { MyInfoManage } from './pages/my-info/MyInfoManage';
import { MyInfoEditNickName } from './pages/my-info/MyInfoEditNickName';
import { MyInfoEditPhone } from './pages/my-info/MyInfoEditPhone';
import { MyInfoEditBank } from './pages/my-info/MyInfoEditBank';
import { MyInfoEditAddress } from './pages/my-info/MyInfoEditAddress';
import { MyInfoEditEmail } from './pages/my-info/MyInfoEditEmail';
import { MyInfoAlertSettings } from './pages/my-info/MyInfoAlertSettings';
import { PremiumHome } from './pages/my-info/premium/PremiumHome';

import { AccountBlock } from './pages/my-info/AccountBlock';

import { PremiumRegister } from './pages/my-info/premium/PremiumRegister';
import { MyInterests } from './pages/my-interests/MyInterests';
import React, { useEffect, useState } from 'react';
import { AuthProvider } from './common/providers & services/AuthProvider';
import { SellerDetail } from './pages/seller-detail/SellerDetail';
import { Chatting } from './pages/chatting/Chatting';
import { ChatRoom } from './pages/chatting/ChatRoom';
import { PinchZoomImageViewer } from './pages/PinchZoomImageViewer/PinchZoomImageViewer';
import { CustomerCenter } from './pages/my-info/customer-center/CustomerCenter';
import { QuestionDetail } from './pages/my-info/customer-center/QuestionDetail';
import { SellerReviews } from './pages/seller-reviews/SellerReviews';
import { WriteBuyerReview } from './pages/write-review/WriteBuyerReview';
import { WriteSellerReview } from './pages/write-review/WriteSellerReview';
import { FCMService } from './common/providers & services/FCMService';
import { BackButtonService } from './common/providers & services/BackButtonService';
import { Device } from '@capacitor/device';
import { Redirect } from 'react-router';

export const appRoutes = {
  landingPage: {
    route: '/landing-page',
    key: '/landing-page',
    auth: false,
    path: () => '/landing-page',
  },
  login: {
    route: '/login',
    key: '/login',
    auth: false,
    path: () => `/login`,
  },
  signUp: {
    route: '/sign-up',
    key: '/sign-up',
    auth: false,
    path: () => '/sign-up',
  },
  phoneVerify: {
    route: '/phone-verify/:phoneNumber',
    key: '/phone-verify',
    auth: false,
    path: (phoneNumber: string) => `/phone-verify/${phoneNumber}`,
    // route: '/unprotected/phone-verify/:phoneNumber/:testCode',
    // path: (phoneNumber: string, testCode: string) => `/unprotected/phone-verify/${phoneNumber}/${testCode}`,
  },
  allItems: {
    route: '/allItems',
    key: '/allItems',
    auth: false,
    path: () => '/allItems',
  },
  chatting: {
    route: '/chatting',
    key: '/chatting',
    auth: true,
    path: () => '/chatting',
    children: {
      room: {
        route: '/chatting/room/:roomId',
        path: (roomId: string | number) => `/chatting/room/${roomId}`,
      },
    },
  },
  myInterests: {
    route: '/myInterests',
    key: '/myInterests',
    auth: true,
    path: () => '/myInterests',
  },
  sellerDetail: {
    route: '/sellerDetail/:accountId',
    key: '/sellerDetail',
    auth: true,
    path: (accountId: string) => `/sellerDetail/${accountId}`,
  },
  myInfo: {
    route: '/myInfo',
    key: '/myInfo',
    auth: true,
    path: () => '/myInfo',
    children: {
      manage: {
        route: '/myInfo/manage',
        path: () => '/myInfo/manage',
      },
      edit: {
        route: '/myInfo/edit',
        path: () => '/myInfo/edit',
      },
      editNickname: {
        route: '/myInfo/editNickname',
        path: () => '/myInfo/editNickname',
      },
      editPhone: {
        route: '/myInfo/editPhone',
        path: () => '/myInfo/editPhone',
      },
      editBank: {
        route: '/myInfo/editBank',
        path: () => '/myInfo/editBank',
      },
      editAddress: {
        route: '/myInfo/editAddress',
        path: () => '/myInfo/editAddress',
      },
      editEmail: {
        route: '/myInfo/editEmail',
        path: () => '/myInfo/editEmail',
      },
      alertSettings: {
        route: '/myInfo/alertSettings',
        path: () => '/myInfo/alertSettings',
      },
      premiumHome: {
        route: '/myInfo/premiumHome',
        path: () => '/myInfo/premiumHome',
      },
      accountBlock: {
        route: '/myInfo/accountBlock',
        path: () => '/myInfo/accountBlock',
      },
      premiumRegister: {
        route: '/myInfo/premiumRegister',
        path: () => '/myInfo/premiumRegister',
      },
      customerCenter: {
        route: '/customerCenter',
        path: () => `/customerCenter`,
      },
      customerCenterQuestion: {
        route: '/customerCenter/:questionId',
        path: (questionId: string | number) => `/customerCenter/${questionId}`,
      },
      editItem: {
        route: '/edit-item/:itemId',
        path: (itemId: string) => `edit-item/${itemId}`,
      },
    },
  },

  fleaMarketDetail: {
    route: '/fleaMarketDetail/:itemId',
    key: '/fleaMarketDetail/',
    auth: false,
    path: (id: string) => `/fleaMarketDetail/${id}`,
  },
  sellNewItem: {
    route: '/fleaMarket/sell/:type',
    key: '/fleaMarket/sell/',
    auth: true,
    path: (type: SellType) => `fleaMarket/sell/${type}`,
  },
  createNewJob: {
    route: '/fleaMarket/job',
    key: '/fleaMarket/job',
    auth: true,
    path: () => `fleaMarket/job`,
  },
  fullScreenImage: {
    route: '/fullscreen-image',
    key: '/fullscreen-image',
    auth: false,
    path: (index: number, srcs: string[]) => `/fullscreen-image?index=${index}&srcs=${JSON.stringify(srcs)}`,
  },
  sellerReviews: {
    route: '/seller-reviews/:sellerId',
    key: '/seller-reviews/',
    auth: true,
    path: (sellerId: string | number) => `/seller-reviews/${sellerId}`,
  },
  writeBuyerReview: {
    route: '/write-buyer-review/:itemId/:sellId',
    key: '/write-buyer-review/',
    auth: true,
    path: (itemId: string, sellId: number) => `/write-buyer-review/${itemId}/${sellId}`,
  },
  writeSellerReview: {
    route: '/write-seller-review/:itemId',
    key: '/write-seller-review',
    auth: true,
    path: (itemId: string) => `/write-seller-review/${itemId}`,
  },
};

export const AppRoutes = () => {
  const [isNative, setIsNative] = useState(false);

  useEffect(() => {
    Device.getInfo().then(({ platform }) => setIsNative(platform !== 'web'));
  }, []);

  return (
    <>
      <IonReactRouter>
        <IonRouterOutlet>
          <AuthProvider>
            {isNative && <FCMService />}
            <BackButtonService />

            <Route path={appRoutes.landingPage.route} component={LandingPage} />
            <Route path={appRoutes.login.route} component={Login} />
            <Route path={appRoutes.signUp.route} component={SignUp} />
            <Route path={appRoutes.phoneVerify.route} component={PhoneVerify} />
            <Route path={appRoutes.allItems.route} component={AllItems} />
            <Route path={appRoutes.myInterests.route} component={MyInterests} />
            <Route path={appRoutes.sellerDetail.route} component={SellerDetail} />
            <Route path={appRoutes.fleaMarketDetail.route} component={FleaMarketItemDetail} />
            <Route path={appRoutes.sellNewItem.route} component={SellNewItem} />
            <Route path={appRoutes.createNewJob.route} component={CreateNewJob} />
            <Route exact path={appRoutes.chatting.route} component={Chatting} />
            <Route path={appRoutes.chatting.children.room.route} component={ChatRoom} />
            <Route exact path={appRoutes.myInfo.route} component={MyInfo} />
            <Route exact path={appRoutes.myInfo.children.manage.route} component={MyInfoManage} />
            <Route exact path={appRoutes.myInfo.children.edit.route} component={MyInfoEdit} />
            <Route exact path={appRoutes.myInfo.children.editNickname.route} component={MyInfoEditNickName} />
            <Route exact path={appRoutes.myInfo.children.editPhone.route} component={MyInfoEditPhone} />
            <Route exact path={appRoutes.myInfo.children.editBank.route} component={MyInfoEditBank} />
            <Route exact path={appRoutes.myInfo.children.editAddress.route} component={MyInfoEditAddress} />
            <Route exact path={appRoutes.myInfo.children.editEmail.route} component={MyInfoEditEmail} />
            <Route exact path={appRoutes.myInfo.children.alertSettings.route} component={MyInfoAlertSettings} />
            <Route exact path={appRoutes.myInfo.children.premiumHome.route} component={PremiumHome} />
            <Route exact path={appRoutes.myInfo.children.accountBlock.route} component={AccountBlock} />
            <Route exact path={appRoutes.myInfo.children.premiumRegister.route} component={PremiumRegister} />
            <Route exact path={appRoutes.myInfo.children.customerCenter.route} component={CustomerCenter} />
            <Route exact path={appRoutes.myInfo.children.customerCenterQuestion.route} component={QuestionDetail} />
            <Route exact path={appRoutes.myInfo.children.editItem.route} component={SellNewItem} />
            <Route path={appRoutes.fullScreenImage.route} component={PinchZoomImageViewer} />
            <Route path={appRoutes.sellerReviews.route} component={SellerReviews} />
            <Route path={appRoutes.writeBuyerReview.route} component={WriteBuyerReview} />
            <Route path={appRoutes.writeSellerReview.route} component={WriteSellerReview} />
            {/* <Redirect exact from={'/'} to={appRoutes.allItems.route} /> */}
            <Route exact path={'/'} component={Home} />
          </AuthProvider>
        </IonRouterOutlet>
      </IonReactRouter>
    </>
  );
};
