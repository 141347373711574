import React, { useState } from 'react';
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import { useParams } from 'react-router';
import { appRoutes } from '../../AppRoutes';
import { useSignInBySmsMutation } from '../../generated/graphql';
import { setSavedAuthToken, setSavedRefreshToken } from '../../common/utils/storage';

export const PhoneVerify: React.FC = () => {
  const { phoneNumber } = useParams<{ phoneNumber: string }>();
  const [code, setCode] = useState('');

  const [checkCode] = useSignInBySmsMutation();

  const router = useIonRouter();

  const testVerificationCode = async () => {
    if (code) {
      const { data } = await checkCode({ variables: { phone: phoneNumber, authData: code } });
      console.log(data);
      if (data?.checkAuthLoginSms?.data) {
        const auth = JSON.parse(data.checkAuthLoginSms.data);
        setSavedAuthToken(auth.token);
        setSavedRefreshToken(auth.refreshToken);
        router.push(appRoutes.allItems.route, 'forward', 'replace');
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>휴대폰 인증</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <div className={'page-container'}>
          <input className={'large-input'} type='text' value={phoneNumber} disabled={true} />
          <IonButton expand={'block'} color={'dark'} size={'large'} style={{ width: '100%' }}>
            인증코드 다시 받기 (5분)
          </IonButton>
          <div></div>
          <input className={'large-input'} type='text' value={code} placeholder={'인증코드 입력'} onChange={e => setCode(e.target.value)} />
          <IonButton expand={'block'} size={'large'} disabled={!code} style={{ width: '100%' }} onClick={testVerificationCode}>
            인증코드 확인
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};
