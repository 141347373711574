import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonActionSheet,
  useIonRouter,
} from '@ionic/react';
import {
  useAddAttAccountMutation,
  useBlockUserMutation,
  useDeleteChatRoomMutation,
  useGetChatRoomByIdQuery,
  useGetChatRoomsQuery,
  useGetMyAccountQuery,
  useSendChatMessageMutation,
  useTurnOffChatAlertsMutation,
} from '../../generated/graphql';
import { useParams } from 'react-router';
import styled, { css } from 'styled-components';
import { imageOutline, paperPlaneOutline } from 'ionicons/icons';
import { openNativeGallery, urlToBase64 } from '../../common/utils/photoUtils';
import { appRoutes } from '../../AppRoutes';
import { App } from '@capacitor/app';
import { PluginListenerHandle } from '@capacitor/core';

const fallbackSrc = '/assets/icon/account.svg';
export type ChatOption = 'register' | 'block' | 'alerts' | 'leave';

export const ChatRoom: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>();

  const [srcs, setSrcs] = useState<string[]>([]);

  const input = useRef<HTMLIonTextareaElement>(null);
  const router = useIonRouter();

  const room = useGetChatRoomsQuery().data?.searchMyChatRoomList?.find(r => r?.roomId === roomId);
  const me = useGetMyAccountQuery();
  const [sendMessage] = useSendChatMessageMutation();
  const [deleteChatRoom] = useDeleteChatRoomMutation();
  const [blockUser] = useBlockUserMutation();
  const [turnOffAlerts] = useTurnOffChatAlertsMutation();
  const [addAttAccount] = useAddAttAccountMutation();
  const timeout = useRef<NodeJS.Timeout>();
  const listener = useRef<PluginListenerHandle>();

  const { data, refetch } = useGetChatRoomByIdQuery({ variables: { id: ~~roomId } });

  useEffect(() => {
    // Focus input on load
    if (input.current) {
      input.current.focus();
    }

    // Stop timeout in background + Restart on resume
    listener.current = App.addListener('appStateChange', state => {
      if (state.isActive && !timeout.current) {
        setUpTimeOut();
      } else if (!state.isActive && timeout.current) {
        clearTimeout(timeout.current);
      }
    });

    return () => {
      if (timeout.current) clearTimeout(timeout.current);
      if (listener.current) listener.current?.remove();
    };
  }, []);

  const setUpTimeOut = () => {
    if (!timeout.current) {
      timeout.current = setTimeout(() => {
        refetch();
      }, 10000);
    }
  };

  const messages = useMemo(() => {
    let temp: any[] = [];
    if (data && data.searchChatMessageListByRoomId) {
      temp = [...data.searchChatMessageListByRoomId];
    }
    console.log(temp);
    return temp.reverse();
  }, [data]);

  const [presentActionSheet] = useIonActionSheet();

  const handleMoreClick = () => {
    presentActionSheet({
      header: '더보기',
      buttons: [
        {
          text: '관심판매자로 등록하기',
          handler: () => {
            addAttAccount({ variables: { id: ~~room!.fromAccount.accountId } });
          },
        },
        {
          text: '차단하기',
          handler: () => blockUser({ variables: { accountId: ~~room!.fromAccount.accountId } }), // todo - status indicator? Toast?
        },
        {
          text: '알림끄기',
          handler: () => turnOffAlerts({ variables: { accountId: ~~room!.fromAccount.accountId } }), // todo - is this a toggle? Is it right to turn off for a user, not a chat? Need some indicator or toast?
        },
        {
          text: '채팅방 나가기',
          handler: () => deleteChatRoom({ variables: { roomId: ~~roomId } }).then(() => router.goBack()),
        },
        {
          text: '취소',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });
  };

  const handleSend = async () => {
    const promises: Promise<any>[] = [];
    if (srcs.length) {
      srcs.forEach(src => {
        urlToBase64(src).then(b64 => {
          promises.push(sendMixedTypeMessage('IMG', b64));
        });
      });
    }
    if (input.current && input.current.value?.trim().length) {
      promises.push(sendMixedTypeMessage('TEXT', input.current.value));
    }

    await Promise.allSettled(promises);
    setSrcs([]);
    if (input.current) {
      input.current.value = '';
    }
    refetch();
  };

  const sendMixedTypeMessage = async (type: 'TEXT' | 'IMG', message: string) => {
    if (room && input.current) {
      await sendMessage({
        variables: {
          message: {
            chatRoom: {
              roomId: ~~room.roomId,
              fromAccount: {},
              item: { itemId: room.item.itemId, category: room.item.category, type: room.item.type },
            },
            message,
            messageType: type,
          },
        },
      });
    }
  };

  const handleOpenGallery = async () => {
    const res = await openNativeGallery(5);
    if (res.length) {
      setSrcs(res);
    }
  };

  const handleImageClick = (message: any) => {
    if (message.messageType === 'TEXT') return;

    let index = 0;
    const srcs: string[] = [];
    messages.forEach(m => {
      if (m.messageType === 'IMG') {
        srcs.push(m.message);
        if (m.messageId === message.messageId) {
          index = srcs.length;
        }
      }
    });
    router.push(`${appRoutes.fullScreenImage.path(index, srcs)}`);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonButtons slot='end'>
            <a style={{ textDecoration: 'none', fontSize: '17px' }} href={`tel:${room?.partnerPhone}`} className={`color-primary`}>
              전화하기
            </a>
            <IonButton onClick={handleMoreClick}>더보기</IonButton>
          </IonButtons>
          <IonTitle>{room?.item.title ?? ''}</IonTitle>
          {/* <IonTitle>{room?.fromAccount.nickName ?? ''}</IonTitle> */}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Container>
          <MessageContainer className={'ion-padding'}>
            {messages.map(
              m =>
                m.message?.trim().length > 0 && (
                  <ChatRow
                    key={m.messageId}
                    isOwn={me.data?.searchMyAccount?.accountId === m.senderAccount.accountId}
                    avatar={m.senderAccount.file?.s3Url}
                  >
                    <Bubble
                      isOwn={me.data?.searchMyAccount?.accountId === m.senderAccount.accountId}
                      src={m.messageType === 'IMG' ? m.message : undefined}
                      onClick={() => handleImageClick(m)}
                    >
                      {m.messageType === 'TEXT' && m.message}
                    </Bubble>
                    <TimeSent>{m.diffTime}</TimeSent>
                  </ChatRow>
                ),
            )}
          </MessageContainer>
          <InputBar>
            {srcs.length > 0 && (
              <PhotosContainer>
                {srcs.map(src => (
                  <Photo key={src} src={src} />
                ))}
              </PhotosContainer>
            )}
            <InputWrapper>
              <IonIcon size={'large'} icon={imageOutline} color={'primary'} onClick={handleOpenGallery} />
              <IonTextarea ref={input} rows={3} />
              <IonIcon size={'large'} icon={paperPlaneOutline} color={'primary'} onClick={handleSend} />
            </InputWrapper>
          </InputBar>
        </Container>
      </IonContent>
    </IonPage>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #70de9cff;
  overflow: hidden;
`;

const InputBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PhotosContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  max-width: 100%;
  display: block;
`;

const Photo = styled('div')<{ src: string }>`
  width: 60px;
  height: 60px;
  background-image: url(${props => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  display: inline-block;
  margin: 4px;
  margin-right: 8px;

  &:after {
    content: '';
    display: block;
    background-image: url('/assets/icon/X.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    left: calc(100% - 15px);
    bottom: 4px;
    position: relative;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  gap: 10px;
  padding: 10px 15px;
`;

const MessageContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  gap: 15px;
  flex-grow: 1;
`;

const ChatRow = styled('div')<{ isOwn: boolean; avatar?: string | null }>`
  display: flex;
  flex-direction: ${props => (props.isOwn ? 'row-reverse' : 'row')};
  gap: 10px;

  ${({ isOwn, avatar }) =>
    !isOwn &&
    css`
      &:before {
        content: '';
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-image: url(${avatar ?? fallbackSrc});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    `}
`;

const Bubble = styled('div')<{ isOwn: boolean; src?: string }>`
  background-color: ${props => (props.isOwn ? 'var(--ion-color-secondary-shade)' : 'white')};
  font-size: 14px;
  color: black;
  // color: ${props => (props.isOwn ? 'white' : 'black')};
  border: 1px solid ${props => (props.isOwn ? 'var(--ion-color-secondary-shade)' : 'white')};
  padding: 5px 15px;
  margin-top: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: ${props => (props.isOwn ? '10px' : '0')};
  border-top-right-radius: ${props => (props.isOwn ? '0' : '10px')};
  font-weight: 200;
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isOwn ? 'flex-end' : 'flex-start')};
  min-height: 32px;
  box-shadow: 0px 0px 1px 1px #e3d9d96b;
  word-break: break-word;

  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      border: none;
      height: 50vw;
      width: 50vw;
    `}
`;

const TimeSent = styled.div`
  font-size: 12px;
  color: #383636;
  margin-top: 10px;
`;
