import React from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from '@ionic/react';
import { appRoutes } from '../../AppRoutes';
import { setSavedAuthToken } from '../../common/utils/storage';
import { useGetMyAccountQuery, useLeaveServiceMutation } from '../../generated/graphql';
import { Device } from '@capacitor/device';

export const MyInfoManage: React.FC = () => {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const [leaveService] = useLeaveServiceMutation();
  const accountId = useGetMyAccountQuery().data?.searchMyAccount?.accountId;

  const handleGoToStore = async () => {
    const device = await Device.getInfo();
    if (device.platform === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.uos.project_new_windy');
    }

    if (device.platform === 'ios') {
      // todo
    }
  };

  const handleLogout = () => {
    setSavedAuthToken(null);
    router.push(appRoutes.landingPage.route, 'forward', 'replace');
    window.location.reload();
  };

  const handleLeave = async () => {
    if (accountId) {
      await leaveService({ variables: { id: ~~accountId } });
      handleLogout();
    }
  };

  const openServiceAgreement = () => {
    window.open("http://xn--4i2by9fe3g.net/m/etc/agreement.html");
  };
  const openPrivacyPolicy = () => {
    window.open("http://xn--4i2by9fe3g.net/m/etc/privacy.html");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>계정 정보 관리</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <IonList>
          <IonItem routerLink={appRoutes.myInfo.children.edit.path()} mode={'ios'}>
            <IonLabel style={{ padding: '16px 10px' }}>개인정보관리</IonLabel>
          </IonItem>
          <IonItem routerLink={appRoutes.myInfo.children.alertSettings.path()} mode={'ios'}>
            <IonLabel style={{ padding: '16px 10px' }}>알림설정</IonLabel>
          </IonItem>
          <IonItem routerLink={appRoutes.myInfo.children.edit.path()} mode={'ios'}>
            <IonLabel style={{ padding: '16px 10px' }}>방해금지 시간설정</IonLabel>
          </IonItem>
          <IonItem routerLink={appRoutes.myInfo.children.premiumHome.path()} mode={'ios'}>
            <IonLabel style={{ padding: '16px 10px' }}>프리미엄 회원 신청</IonLabel>
          </IonItem>
          <IonItem routerLink={appRoutes.myInfo.children.accountBlock.path()} mode={'ios'}>
            <IonLabel style={{ padding: '16px 10px' }}>차단 회원 관리</IonLabel>
          </IonItem>
          <IonItem routerLink={appRoutes.myInfo.children.customerCenter.path()} mode={'ios'}>
            <IonLabel style={{ padding: '16px 10px' }}>고객센터 문의하기</IonLabel>
          </IonItem>
          <IonItem onClick={openServiceAgreement} mode={'ios'}>
            <IonLabel style={{ padding: '16px 10px' }}>서비스 이용 약관 확인</IonLabel>
          </IonItem>
          <IonItem onClick={openPrivacyPolicy} mode={'ios'}>
            <IonLabel style={{ padding: '16px 10px' }}>개인정보 처리방침 확인</IonLabel>
          </IonItem>
          <IonItem mode={'ios'} onClick={handleGoToStore}>
            <IonLabel style={{ padding: '16px 10px' }}>최신 버전으로 업데이트</IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel
              style={{ padding: '16px 10px' }}
              onClick={() => {
                presentAlert({
                  message: '로그아웃 하시겠습니까?',
                  mode: 'ios',
                  buttons: [
                    {
                      text: '예',
                      role: 'destructive',
                      handler: () => handleLogout(),
                    },
                    {
                      text: '아니요',
                      role: 'cancel',
                    },
                  ],
                });
              }}
            >
              로그아웃
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel
              style={{ padding: '16px 10px' }}
              onClick={() => {
                presentAlert({
                  message: '신바람빌리지를 탈퇴하시겠습니까?\n' + '추후 재사용을 원하시는 경우\n' + '회원가입 절차를 다시 거치셔야합니다.',
                  mode: 'ios',
                  buttons: [
                    {
                      text: '예',
                      role: 'destructive',
                      handler: () => handleLeave(),
                    },
                    {
                      text: '아니요',
                      role: 'cancel',
                    },
                  ],
                });
              }}
            >
              탈퇴하기
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter className={'ion-padding'}>
        <IonButton expand={'block'} size={'large'} onClick={() => router.goBack()}>
          완료
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
