import React from 'react';
import { IonButton, IonContent, IonPage, useIonRouter } from '@ionic/react';
import { AppLogo } from '../../common/components/AppLogo';
import { appRoutes } from '../../AppRoutes';
import Slider from 'react-slick';

const slides: { imgSrc: string; text: string }[] = [
  {
    imgSrc: 'assets/landing-page/img1.png',
    text: '당장 필요한 농기계, 농자재를\n 빠르게 찾아 거래할 수 있어요',
  },
  {
    imgSrc: '/assets/landing-page/img2.png',
    text: '신선하고 맛 좋은 우리 농산물을\n 구매할 수 있어요 ',
  },
  {
    imgSrc: '/assets/landing-page/img3.png',
    text: '원하는 시골집, 주택, 토지를\n 둘러볼 수 있어요',
  },
  {
    imgSrc: '/assets/landing-page/img4.png',
    text: '바쁜 시기에 필요한 일손을\n 구할 수 있어요',
  },
];

export const LandingPage: React.FC = () => {
  const router = useIonRouter();

  return (
    <IonPage className={'ion-padding'}>
      <IonContent className={'ion-text-center flex-col-center-20'}>
        <div className={'flex-row-center'} style={{ marginTop: '75px' }}>
          <AppLogo />
        </div>
        <Slider infinite={true} arrows={false} dots={true} slidesToShow={1} slidesToScroll={1}>
          {slides.map((s, i) => (
            <Slide key={`slide_${i}`} imgSrc={s.imgSrc} text={s.text} />
          ))}
        </Slider>
        <div className={'font-20-600 n_30'} style={{ marginTop: '50px' }}>
          신바람 빌리지에 오신것을 환영합니다.
        </div>
        <IonButton expand={'block'} color={'primary'} size={'large'} style={{ margin: '25px 0' }} routerLink={appRoutes.signUp.path()}>
          가입하기
        </IonButton>
        <div className={'font-16-500 color-tertiary'} style={{ margin: '25px 0' }}>
          이미 가입한 적이 있나요?
        </div>
        <div className={'font-18-600 color-primary'} onClick={() => router.push(appRoutes.login.path())}>
          로그인
        </div>
      </IonContent>
    </IonPage>
  );
};

const Slide: React.FC<Props> = ({ imgSrc, text }) => {
  return (
    <div className={'flex-col-center-20'} style={{background: '#fff'}}>
      <img src={imgSrc} style={{ height: '242px' }} />
      <div className={'font-20-400'} style={{ textAlign: 'center', whiteSpace: 'break-spaces', background: '#fff' }}>
        {text}
      </div>
    </div>
  );
};

interface Props {
  imgSrc: string;
  text: string;
}
