import styled from 'styled-components';
import React from 'react';
import { useGetMyAccountQuery } from '../../../generated/graphql';
import { RecentlyViewedItemList } from '../../../common/components/RecentlyViewedItemList';

export const NoAttItem: React.FC = () => {
  const user = useGetMyAccountQuery();

  return (
    <>
      <Container className={'font-16-500 n_70'}>
        관심 등록된 글이 아직 없어요.
        <br />
        관심있는 물품에 ♡를 눌러 주세요
      </Container>
      <UserHeader className={'font-16-600 n_30'}>{user.data?.searchMyAccount?.nickName} 님이 최근 본 글</UserHeader>
      <RecentlyViewedItemList />
    </>
  );
};

const Container = styled.div`
  width: 100vw;
  margin-left: calc(var(--padding-start) * -1);
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #f5f6f8;
  padding: 73px 0;
`;

const UserHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--neutral-90);
  padding: 15px 0;
`;
