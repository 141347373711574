import React from 'react';
import './flea_market_styles.css';
import { IonButton, IonIcon, useIonRouter } from '@ionic/react';
import { appRoutes } from '../../../AppRoutes';
import { FleaMarketItemButtons } from './FleaMarketItemButtons';
import { Item, useGetChatRoomsLazyQuery, useGetMyAccountQuery, useSendChatMessageMutation } from '../../../generated/graphql';
import { heart, heartOutline } from 'ionicons/icons';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Maybe } from 'graphql/jsutils/Maybe';
import { makeContentsShort } from '../../../common/utils/utils';
import { useUnauthenticatedRedirect } from '../../../common/hooks/useUnauthenticatedRedirect';

interface Props {
  item: Item | null;
  onRegisterInterest: () => void;
  onItemUp: () => void;
  isOwnItem: boolean;
}

export const FleaMarketItem: React.FC<Props> = ({ item, onRegisterInterest, onItemUp, isOwnItem }) => {
  const router = useIonRouter();
  const userAccountId = useGetMyAccountQuery().data?.searchMyAccount?.accountId;
  const redirect = useUnauthenticatedRedirect();

  const [startChat, result] = useSendChatMessageMutation();
  const [getChatRooms] = useGetChatRoomsLazyQuery();

  const handleStartChat = async () => {
    if (!item) return;
    let roomId = await findRoomId();
    if (!roomId && !result.loading) {
      await startChat({
        variables: {
          message: {
            chatRoom: {
              item: {
                itemId: item.itemId,
                category: item.category,
                type: item.type,
              },
              fromAccount: {},
            },
            message: '',
          },
        },
      });
      roomId = await findRoomId();
    }
    if (roomId) {
      router.push(appRoutes.chatting.children.room.path(roomId));
    }
  };

  const findRoomId = async () => {
    const chatRooms = await getChatRooms({ fetchPolicy: 'network-only' });
    return chatRooms.data?.searchMyChatRoomList?.find(room => room?.item.itemId === item!.itemId)?.roomId;
  };

  const moveToDetailView = (item: Item) => {
    router.push(appRoutes.fleaMarketDetail.path(item.itemId));
  };

  // const pullItemUp = (item: Item) => {
  // }
  const handleItemUpClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onItemUp();
  };

  return (
    <>
      {item && (
        <>
          <Container>
            {item.fileList && item.fileList.length > 0 && (
              <ImgWrap>
                <img src={item.fileList[0]!.s3Url!} onClick={() => moveToDetailView(item)} style={{ maxHeight: '360px' }} />
              </ImgWrap>
            )}

            <Header onClick={() => moveToDetailView(item)}>
              <Title>{item.title}</Title>
              <PriceWrap>
                <IonButton color={'primary'} mode={'ios'} size={'small'} fill={'outline'} style={{ margin: 0 }}>
                  {item.statusValue}
                </IonButton>
                <div className={'font-20-600'}>{item.price}</div>
              </PriceWrap>
            </Header>

            <InfoRow>
              <InfoWrap>
                <Nickname nickname={item.account.nickName} isPremium={!!item.account?.isPremium} />
                <div>{item.addressName}</div>
                <div>{item.regDate?.substring(0, item.regDate?.lastIndexOf(':'))}</div>

                {userAccountId === item.account.accountId && (
                  <span style={{ marginRight: '4px' }} onClick={handleItemUpClick}>
                    끌어올리기
                  </span>
                )}
              </InfoWrap>
              <HeartWrap>
                <IonIcon icon={item.isAttItem ? heart : heartOutline} style={{ fontSize: '20px' }} color={'primary'} />
                <span className={'font-16-400 color-primary'}>{item.attUserCount}</span>
              </HeartWrap>
            </InfoRow>

            <div>
              <div className={'n_30'} onClick={() => moveToDetailView(item)}>
                {makeContentsShort(item?.contents ?? '')}
              </div>
            </div>

            <FleaMarketItemButtons
              phoneNumber={item.phone ?? ''}
              onRegisterInterest={onRegisterInterest}
              onChat={handleStartChat}
              isOwnItem={isOwnItem}
            />
          </Container>
        </>
      )}
    </>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  border-bottom: 6px solid #f9fafa;
  width: 100%;
`;

const ImgWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: lightblue;
`;

const Header = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: white;
  column-gap: 0.5rem;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  word-break: keep-all;
  flex: 2 1 auto;
`;

const PriceWrap = styled.div`
  min-width: min-content;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 2 auto;
  flex-wrap: wrap-reverse;
  column-gap: 10px;
  row-gap: 5px;

  > div {
    flex-shrink: 0;
    color: var(--neutral-10);
  }
`;

const InfoRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
`;

const InfoWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: var(--neutral-30);
  line-height: 21px;
  height: 30px;

  div + div {
    &:before {
      margin-right: 4px;
      content: '|';
    }
  }
`;

const HeartWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

const Nickname = styled('div')<{ isPremium: boolean; nickname?: Maybe<string> }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  font-size: 13px;

  flex-shrink: 0;

  ${({ isPremium }) =>
    isPremium &&
    css`
      &:before {
        content: '';
        width: 16px;
        height: 16px;
        background-image: url('/assets/premium/premium_badge.png');
        background-size: cover;
        background-repeat: no-repeat;
      }
    `}
  &:after {
    content: '${props => props.nickname ?? ''}';
  }
`;
