import React, { useRef, useState } from 'react';
import { IonButton, IonIcon, IonTextarea } from '@ionic/react';
import styled from 'styled-components';
import { star, starOutline } from 'ionicons/icons';

const defaultReviews = [
  '시간 약속을 잘 안 지켜요',
  '대답이 느려요',
  '물건 설명이 부족해요',
  '친절하고 매너가 좋아요',
  '시간 약속을 잘 지켜요',
  '응답이 빨라요',
];

interface Props {
  point: number;
  onPointChange: (point: number) => void;
  nickName: string;
  onComplete: (review: string) => void;
}

export const WriteReview: React.FC<Props> = ({ point, onPointChange, nickName, onComplete }) => {
  const [review, setReview] = useState<string[]>([]);
  const input = useRef<HTMLIonTextareaElement>(null);

  const handleDefaultReviewClick = (val: string) => {
    if (review.includes(val)) setReview(prev => prev.filter(r => r !== val));
    else setReview(prev => [...prev, val]);
  };

  return (
    <>
      <div className={'font-18-700 ion-text-center ion-padding'}>
        {nickName} 님과 거래가 어떠셨나요? <br />
        점수를 매겨주세요.
      </div>

      <StarContainer>
        <IonIcon
          size={'large'}
          icon={point >= 1 ? star : starOutline}
          color={'warning'}
          onClick={() => (point !== 1 ? onPointChange(1) : onPointChange(0))}
        />
        <IonIcon
          size={'large'}
          icon={point >= 2 ? star : starOutline}
          color={'warning'}
          onClick={() => (point !== 2 ? onPointChange(2) : onPointChange(0))}
        />
        <IonIcon
          size={'large'}
          icon={point >= 3 ? star : starOutline}
          color={'warning'}
          onClick={() => (point !== 3 ? onPointChange(3) : onPointChange(0))}
        />
        <IonIcon
          size={'large'}
          icon={point >= 4 ? star : starOutline}
          color={'warning'}
          onClick={() => (point !== 4 ? onPointChange(4) : onPointChange(0))}
        />
        <IonIcon
          size={'large'}
          icon={point >= 5 ? star : starOutline}
          color={'warning'}
          onClick={() => (point !== 5 ? onPointChange(5) : onPointChange(0))}
        />
      </StarContainer>

      <hr />

      <div className={'font-14-500 ion-text-center'}>
        {nickName} 님과 남기고 싶은 메시지 <br />
        남겨주신 거래후기는 상대방의 프로필에 공개됩니다.
      </div>

      <ButtonWrapper>
        {defaultReviews
          .filter((r, i) => {
            if (point <= 2 && i <= 2) return true;
            else if (point > 2 && i > 2) return true;
            return false;
          })
          .map((r, i) => (
            <IonButton
              key={r}
              fill={review.indexOf(r) >= 0 ? 'solid' : 'outline'}
              expand={'block'}
              className={'square-button font-12-600'}
              onClick={() => handleDefaultReviewClick(r)}
            >
              {r}
            </IonButton>
          ))}
      </ButtonWrapper>

      <hr />

      <IonTextarea ref={input} placeholder={'더 남기고 싶은 말씀이 있으면\n 적어주세요.'} rows={6} autoGrow={true} />
      <hr />
      <IonButton
        size={'large'}
        expand={'block'}
        fill={'outline'}
        style={{ margin: '0 auto 40px', width: '90%' }}
        disabled={!point || !review}
        onClick={() => onComplete(review.join('. '))}
      >
        후기 보내기
      </IonButton>
    </>
  );
};

const StarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
  gap: 12px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px 0;
`;
