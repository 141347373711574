import React, { useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import styled from 'styled-components';
import { useUpdateNickNameMutation } from '../../generated/graphql';

export const MyInfoEditNickName: React.FC = () => {
  const [nickName, setNickName] = useState('');
  const [updateNickName] = useUpdateNickNameMutation();
  const router = useIonRouter();

  const handleUpdateNickName = async () => {
    await updateNickName({ variables: { nickName } });
    router.goBack();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>계정 정보 관리</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <Container>
          <div className={'font-20-500 n_10'}>닉네임을 입력하세요.</div>
          <input
            type='text'
            className={'large-input'}
            placeholder={'닉네임'}
            value={nickName}
            onChange={e => setNickName(e.target.value)}
          />
          <div className={'font-14-400 color-tertiary'}>
            닉네임은 N자까지 가능합니다. <br />
            띄어쓰기나 특수문자는 불가능합니다.
          </div>
        </Container>
      </IonContent>
      <IonFooter className={'ion-padding'}>
        <Buttons>
          <IonButton size={'large'} fill={'outline'} expand={'block'} style={{ flexGrow: 1 }}>
            취소
          </IonButton>
          <IonButton size={'large'} expand={'block'} style={{ flexGrow: 1 }} disabled={!nickName.length} onClick={handleUpdateNickName}>
            저장
          </IonButton>
        </Buttons>
      </IonFooter>
    </IonPage>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 22px;
`;
