import React, { useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import styled from 'styled-components';
import {
  useCheckPhoneVerificationMutation,
  useGetMyAccountQuery,
  useSendPhoneVerificationMutation,
  useUpdatePhoneMutation,
} from '../../generated/graphql';

export const MyInfoEditPhone: React.FC = () => {
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [codeVerified, setCodeVerified] = useState(false);

  const router = useIonRouter();

  const account = useGetMyAccountQuery();
  const [updatePhone] = useUpdatePhoneMutation();
  const [sendVerificationCode] = useSendPhoneVerificationMutation();
  const [checkVerificationCode] = useCheckPhoneVerificationMutation();

  const handleSendVerificationCode = async () => {
    await sendVerificationCode({ variables: { smsParams: { toPhone: phone } } });
  };

  const handleCheckVerificationCode = async () => {
    const res = await checkVerificationCode({ variables: { phone, code: verificationCode } });
    if (res.data?.checkAuthJoinSms?.status === 'success') {
      setCodeVerified(true);
    }
  };

  const handleUpdatePhone = async () => {
    await updatePhone({ variables: { phone } });
    await account.refetch();
    router.goBack();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>계정 정보 관리</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <Container>
          <div className={'font-20-500 n_10'}>통신사를 선택하세요.</div>
          <IonSelect className={'large-input'} interface={'action-sheet'} value={'SKT'}>
            <IonSelectOption value={'SKT'}>SKT</IonSelectOption>
            <IonSelectOption value={'LG'}>LG</IonSelectOption>
          </IonSelect>

          <div className={'font-20-500 n_10'}>휴대폰 번호를 입력하세요.</div>
          <input className={'large-input'} placeholder={'-없이 숫자만 입력'} value={phone} onChange={e => setPhone(e.target.value)} />
          <IonButton
            expand={'block'}
            size={'large'}
            style={{ width: '100%' }}
            color={'dark'}
            disabled={!phone}
            onClick={handleSendVerificationCode}
          >
            인증코드 다시 받기 (5분)
          </IonButton>
          <div className={'inline-input-button'}>
            <input
              className={'large-input'}
              placeholder={'인증코드 입력'}
              value={verificationCode}
              onChange={e => setVerificationCode(e.target.value)}
            />
            <IonButton size={'large'} disabled={!verificationCode} onClick={handleCheckVerificationCode}>
              확인
            </IonButton>
          </div>
        </Container>
      </IonContent>
      <IonFooter>
        <IonButton
          className={'font-18-600'}
          expand={'full'}
          size={'large'}
          color={'light'}
          onClick={handleUpdatePhone}
          disabled={!codeVerified}
        >
          다음
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
