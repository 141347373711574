import React, { useEffect, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonPopover,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonModal,
  useIonRouter,
} from '@ionic/react';
import Slider from 'react-slick';
import { SliderArrowLeft, SliderArrowRight } from '../../common/components/SliderArrows';
import { Eye } from 'react-feather';
import { SellerInfoCard } from '../../common/components/SellerInfoCard';
import { FleaMarketItemButtons } from './components/FleaMarketItemButtons';
import {
  useDeleteItemMutation,
  useGetChatRoomsQuery,
  useGetItemByIdQuery,
  useGetMyAccountQuery,
  useItemUpByIdMutation,
  useSendChatMessageMutation,
  useToggleAttItemMutation,
} from '../../generated/graphql';
import { useParams } from 'react-router';
import { Map, MapMarker } from 'react-kakao-maps-sdk';

import { heart, heartOutline, shareSocialOutline } from 'ionicons/icons';
import { appRoutes } from '../../AppRoutes';
import { ChatOption } from '../chatting/ChatRoom';
import { FleaMarketItemDetailOptionsModal } from './components/FleaMarketItemDetailOptionsModal';
import { Clipboard } from '@capacitor/clipboard';
import styled, { css } from 'styled-components';
import { Maybe } from 'graphql/jsutils/Maybe';
import { getCategoryLabel } from '../../common/utils/utils';
import { useUnauthenticatedRedirect } from '../../common/hooks/useUnauthenticatedRedirect';
import { Device } from '@capacitor/device';
import { toast } from 'react-toastify';
import { Share } from '@capacitor/share';

const modalOptions = {
  breakpoints: [0, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9],
  initialBreakpoint: 0.9,
  backdropBreakpoint: 0.2,
  cssClass: 'rounded-modal',
  backdropDismiss: true,
};

export const FleaMarketItemDetail: React.FC = () => {
  const { itemId } = useParams<{ itemId: string }>();
  const [firstImage, setFirstImage] = useState('');

  const item = useGetItemByIdQuery({ variables: { itemId: parseInt(itemId, 10) }, fetchPolicy: 'network-only' });
  const [toggleAttItem] = useToggleAttItemMutation();
  const [startChat, result] = useSendChatMessageMutation();
  const [morePopover, setMorePopover] = useState(false);
  const [nextUrl, setNexturl] = useState('');
  const { data, refetch } = useGetChatRoomsQuery({ nextFetchPolicy: 'network-only' });
  const router = useIonRouter();
  const user = useGetMyAccountQuery().data?.searchMyAccount;

  const redirect = useUnauthenticatedRedirect();

  const [pullItemUp] = useItemUpByIdMutation();
  const [deleteItem] = useDeleteItemMutation();

  const [presentAlert] = useIonAlert();

  const [present, dismiss] = useIonModal(FleaMarketItemDetailOptionsModal, {
    dismiss: (type?: ChatOption) => {
      dismiss();
    },
    itemId: item.data?.searchItemById?.itemId,
  });

  useEffect(() => {
    if (item.data?.searchItemById?.fileList) {
      let count = 0;
      item.data.searchItemById.fileList.map((file, idx) => {
        if (count == 0) {
          setFirstImage(file?.s3Url ?? '');
          count++;
        }
      });
    }
  }, [item]);

  useEffect(() => {
    if (!morePopover && nextUrl) {
      router.push(nextUrl);
    }
  }, [morePopover]);

  const handleToggleLikeItem = async () => {
    await toggleAttItem({ variables: { itemId: ~~itemId } });
    await item.refetch({ itemId: parseInt(itemId!, 10) });
  };

  const handleStartChat = async () => {
    if (!item) return;
    let roomId = findRoomId();
    if (!roomId && !result.loading && item.data?.searchItemById) {
      await startChat({
        variables: {
          message: {
            chatRoom: {
              item: {
                itemId: item.data?.searchItemById.itemId,
                category: item.data.searchItemById.fullCategory ?? '',
                type: item.data.searchItemById.type,
              },
              fromAccount: {},
            },
            message: '',
          },
        },
      });
      await refetch();
      roomId = data?.searchMyChatRoomList?.find(room => room?.item.itemId === itemId)?.roomId;
    }
    if (roomId) {
      router.push(appRoutes.chatting.children.room.path(roomId));
    }
  };

  const handleItemUpById = async (itemId?: string) => {
    if (itemId) {
      await pullItemUp({ variables: { itemId: ~~itemId } });
    }
  };

  const handleDeleteItem = async () => {
    await deleteItem({ variables: { itemId: ~~itemId! } });
    router.goBack();
  };

  const handleUpdateItem = (itemId: string) => {
    setNexturl('/edit-item/' + itemId);
    setMorePopover(false);
  };

  const findRoomId = () => data?.searchMyChatRoomList?.find(room => room?.item.itemId === itemId)?.roomId;

  const handleImageClick = (index: number) => {
    router.push(appRoutes.fullScreenImage.path(index, item.data?.searchItemById?.fileList?.map(file => file?.s3Url ?? '') ?? []));
  };

  const handleCopyText = async () => {
    if (item.data?.searchItemById?.contents) {
      await Clipboard.write({ string: item.data?.searchItemById?.contents });
      await presentAlert({
        header: '본문이 복사되었습니다.',
        buttons: ['확인'],
      });
    }
  };

  const handleShare = async () => {
    const url = `https://mobile.신바람.com?itemId=${itemId}`;
    const { platform } = await Device.getInfo();
    if (platform === 'web') {
      await navigator.clipboard.writeText(url);
      toast.success('링크가 복사되었습니다.');
    } else {
      await Share.share({
        title: '신바람 빌리지',
        text: item.data?.searchItemById?.title ?? '',
        url,
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonButtons slot={'end'}>
            <>
              <IonIcon
                icon={shareSocialOutline}
                style={{ fontSize: '24px', marginRight: '10px', cursor: 'pointer' }}
                id={'share-action-sheet'}
                onClick={handleShare}
              />
              {user && (
                <>
                  <IonButton onClick={() => setMorePopover(true)} id={'more-btn'}>
                    더보기
                  </IonButton>
                  <IonPopover isOpen={morePopover} onDidDismiss={() => setMorePopover(false)} trigger={'more-btn'}>
                    <IonContent>
                      <IonList style={{ padding: 0 }}>
                        {user?.accountId !== item.data?.searchItemById?.account.accountId && (
                          <>
                            <IonItem
                              onClick={() =>
                                setTimeout(() => {
                                  handleCopyText();
                                  setMorePopover(false);
                                }, 200)
                              }
                            >
                              본문 복사하기
                            </IonItem>
                            <IonItem
                              onClick={() => {
                                setMorePopover(false);
                                redirect(() => present(modalOptions));
                              }}
                            >
                              신고하기
                            </IonItem>
                          </>
                        )}

                        {user?.accountId === item.data?.searchItemById?.account.accountId && (
                          <>
                            <IonItem
                              onClick={() => {
                                handleItemUpById(item.data?.searchItemById?.itemId);
                                setMorePopover(false);
                              }}
                            >
                              끌올
                            </IonItem>
                            <IonItem
                              onClick={() => {
                                handleUpdateItem(item.data?.searchItemById?.itemId!);
                                // setMorePopover(false);
                                // console.log(" morePopover " + morePopover);
                                // router.push('/edit-item/' + item.data?.searchItemById?.itemId);
                              }}
                            >
                              수정
                            </IonItem>
                            <IonItem
                              color={'danger'}
                              onClick={() => {
                                handleDeleteItem();
                                setMorePopover(false);
                              }}
                            >
                              삭제
                            </IonItem>
                          </>
                        )}
                      </IonList>
                    </IonContent>
                  </IonPopover>
                </>
              )}
            </>
          </IonButtons>
          <IonTitle>{getCategoryLabel(item.data?.searchItemById?.fullCategory ?? '벼룩시장')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'flea-market-detail'}>
        {item.data?.searchItemById && (
          <>
            {item.data.searchItemById.fileList!.length == 1 && (
              <div>
                <img key={firstImage} className={'slider-image'} src={firstImage} onClick={() => handleImageClick(0)} />
              </div>
            )}
            {item.data.searchItemById.fileList!.length > 1 && (
              <Slider
                className={'flea-market-detail_slide'}
                arrows={true}
                nextArrow={<SliderArrowRight />}
                prevArrow={<SliderArrowLeft />}
                dots={true}
              >
                {item.data.searchItemById.fileList?.map((file, i) => (
                  <img
                    key={file?.s3Url}
                    className={'slider-image'}
                    src={file?.s3Url ?? '/assets/defaultImage.png'}
                    onClick={() => handleImageClick(i)}
                  />
                ))}
              </Slider>
            )}
            <div className={'ion-padding'}>
              <div className={'flex-row-between'}>
                <div className={'flex-row'} style={{ gap: '11px' }}>
                  <IonButton color={'primary'} mode={'ios'} size={'small'} fill={'outline'}>
                    {item.data.searchItemById.statusValue}
                  </IonButton>
                  <div className={'font-18-600 n_30'}>{item.data.searchItemById.title}</div>
                </div>
                <div className={'font-18-600 n_30'}>{item.data.searchItemById.price}</div>
              </div>

              <InfoRow style={{ width: '100%', marginTop: '6px' }}>
                <Nickname isPremium={item.data.searchItemById.account.isPremium} nickname={item.data.searchItemById.account.nickName} />
                <div>{item.data.searchItemById.shortAddress}</div>
                <div>{item.data.searchItemById.regDate?.substring(0, item.data.searchItemById.regDate?.lastIndexOf(':'))}</div>
                {user && (
                  <div className={'flex-row-end'} style={{ flexGrow: '1' }}>
                    <div
                      style={{
                        textAlign: 'end',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'end',
                      }}
                    >
                      <Eye size={20} color={'var(--ion-color-primary)'} />
                      <span className={'font-16-400 color-primary'}>{item.data.searchItemById.readCount}</span>
                    </div>

                    <div
                      style={{
                        textAlign: 'end',
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'end',
                      }}
                      onClick={handleToggleLikeItem}
                    >
                      <IonIcon icon={item.data.searchItemById.isAttItem ? heart : heartOutline} color={'primary'} className={'s-20'} />
                      <span className={'font-16-400 color-primary'}>{item.data.searchItemById.attUserCount}</span>
                    </div>
                  </div>
                )}
              </InfoRow>

              <hr />

              <div className={'ion-padding-top ion-padding-bottom font-14-400 n_30'} style={{ whiteSpace: 'pre-wrap' }}>
                {item.data.searchItemById.contents}
              </div>

              {item.data.searchItemById.latitude && item.data.searchItemById.longitude && (
                <Map
                  center={{
                    lat: parseFloat(item.data.searchItemById.latitude),
                    lng: parseFloat(item.data.searchItemById.longitude),
                  }}
                  style={{ width: '100%', height: '400px' }}
                  level={4}
                >
                  <MapMarker
                    position={{
                      lat: parseFloat(item.data.searchItemById.latitude),
                      lng: parseFloat(item.data.searchItemById.longitude),
                    }}
                  ></MapMarker>
                </Map>
              )}
              <hr />

              <div className={'font-14-600 n_30'}>판매자 정보</div>

              <hr />

              <SellerInfoCard
                sellerId={item.data.searchItemById.account.accountId}
                s3Url={item.data.searchItemById.account.file?.s3Url}
                sellerName={item.data.searchItemById.sellerName!}
                addressName={item.data.searchItemById.addressName!}
                avgStarPoint={item.data.searchItemById.account.avgStarPoint!}
                reviewCount={item.data.searchItemById.account.reviewCount!}
                sellingItems={item.data.searchItemById.account.ingItemList?.length}
                isAttSeller={item.data.searchItemById.isAttUser}
                blockId={0}
                refetch={item.refetch}
                onToggleBlock={router.goBack}
                onClick={() => router.push(appRoutes.sellerDetail.path(item.data?.searchItemById?.account.accountId!), 'forward')}
              />
            </div>
          </>
        )}
      </IonContent>
      <IonFooter>
        <FleaMarketItemButtons
          large={true}
          onRegisterInterest={handleToggleLikeItem}
          phoneNumber={item.data?.searchItemById?.account.phone ?? ''}
          onChat={handleStartChat}
          isOwnItem={user?.accountId === item.data?.searchItemById?.account.accountId}
        />
      </IonFooter>
    </IonPage>
  );
};
const Nickname = styled('div')<{ isPremium?: boolean | null; nickname?: Maybe<string> }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;

  flex-shrink: 0;

  ${({ isPremium }) =>
    isPremium &&
    css`
      &:before {
        content: '';
        width: 16px;
        height: 16px;
        background-image: url('/assets/premium/premium_badge.png');
        background-size: cover;
        background-repeat: no-repeat;
      }
    `}
  &:after {
    content: '${props => props.nickname ?? ''}';
  }
`;

const InfoRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  color: var(--neutral-30);
  line-height: 21px;

  div + div {
    &:before {
      margin-right: 4px;
      content: '|';
    }
  }
`;
