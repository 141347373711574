import React, { useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';
import { patternEmail } from '../../../common/utils/regularExpressions';
import { useCheckAuthMailMutation, useSendAuthMailMutation } from '../../../generated/graphql';
import { SignUpData } from '../SignUp';

export const SignUpEmail: React.FC<Props> = ({ data, validated, onChange, isEnteringEmail }) => {
  const [email, setEmail] = useState('');
  const [isValidated, setIsValidated] = useState(true);
  const [code, setCode] = useState('');

  const [sendAuthCode] = useSendAuthMailMutation();
  const [checkAuthCode] = useCheckAuthMailMutation();

  useEffect(() => {
    setEmail(data.email);
  }, [data]);

  const handleSendAuthCode = () => {
    sendAuthCode({ variables: { mailParam: { toMail: email } } });
  };

  useEffect(() => {
    if (code.trim().length > 4) {
      handleCheckAuthCode();
    }
  }, [code]);

  const handleCheckAuthCode = async () => {
    const res = await checkAuthCode({ variables: { email, authData: code.trim() } });
    if (res.data?.checkAuthMail?.status === 'success') {
      setIsValidated(true);
    }
  };

  useEffect(() => {
    if (isValidated) {
      onChange({ ...data, email });
    }
    validated(isValidated);
  }, [email, isValidated]);

  return (
    <>
      <div className={'font-20-500 n_10'}>
        이메일 주소를 입력하세요. <span style={{ fontSize: 'smaller' }}>(선택사항)</span>
      </div>
      <div className={'font-14-500 n_30'}>추후 휴대폰 번호가 변경되면 이메일 인증이 필요합니다.</div>
      <input
        className={'large-input'}
        placeholder={'이메일 입력'}
        value={email}
        type={'email'}
        onChange={e => {
          setEmail(e.target.value);
          e.target.value.length ? setIsValidated(false) : setIsValidated(true);
          e.target.value.length ? isEnteringEmail(true) : isEnteringEmail(false);
        }}
      />
      <IonButton
        expand={'block'}
        size={'large'}
        style={{ width: '100%' }}
        disabled={!patternEmail.test(email.trim())}
        onClick={handleSendAuthCode}
      >
        인증코드 받기
      </IonButton>
      <input className={'large-input'} placeholder={'인증코드 입력'} onChange={e => setCode(e.target.value)} />
    </>
  );
};

interface Props {
  data: SignUpData;
  validated: (validated: boolean) => void;
  onChange: (data: SignUpData) => void;
  isEnteringEmail: (val: boolean) => void;
}
