import { NavIcon } from './NavIcon';
import { useLocation } from 'react-router-dom';
import { appRoutes } from '../../AppRoutes';
import React from 'react';
import { useIonModal, useIonRouter } from '@ionic/react';
import { ModalSellNewItem, SellType } from './modals/ModalSellNewItem';
import { useGetMyAccountQuery } from '../../generated/graphql';

const modalOptions = {
  breakpoints: [0, 0.5],
  initialBreakpoint: 0.5,
  backdropBreakpoint: 0.2,
  cssClass: 'rounded-modal',
  backdropDismiss: true,
};

export const NavBar: React.FC = () => {
  const router = useIonRouter();
  const location = useLocation();

  const account = useGetMyAccountQuery().data?.searchMyAccount;

  const [present, dismiss] = useIonModal(ModalSellNewItem, {
    dismiss: (type?: SellType) => {
      if (type) {
        if (type === 'job') {
          router.push(appRoutes.createNewJob.path(), 'forward');
        } else {
          router.push(appRoutes.sellNewItem.path(type), 'forward');
        }
      }
      dismiss();
    },
  });

  const handleAddItemClick = () => {
    // if (account && !account.address) {
    //   router.push(appRoutes.myInfo.children.editAddress.path());
    // } else {
    //
    // }
    present(modalOptions);
  };

  return (
    <div className={'tab-bar-mask'}>
      <div className={'tab-bar'}>
        <div onClick={() => router.push(appRoutes.allItems.route)} className={'tab-button'}>
          <NavIcon
            icon={'list'}
            color={location.pathname === appRoutes.allItems.route ? 'var(--ion-color-primary)' : '#9AA0A9'}
            active={location.pathname === appRoutes.allItems.route}
          />
          <div style={{ color: location.pathname === appRoutes.allItems.route ? 'var(--ion-color-primary)' : '#9AA0A9' }}>전체 글</div>
        </div>

        <div onClick={() => router.push(appRoutes.myInterests.route)} className={'tab-button'}>
          <NavIcon
            icon={'heart-square'}
            color={location.pathname === appRoutes.myInterests.route ? 'var(--ion-color-primary)' : '#9AA0A9'}
            active={location.pathname === appRoutes.myInterests.route}
          />
          <div style={{ color: location.pathname === appRoutes.myInterests.route ? 'var(--ion-color-primary)' : '#9AA0A9' }}>관심 글</div>
        </div>

        <div className={'tab-button'} id={'tab-button3'} onClick={handleAddItemClick}>
          <div className={'tab-icon'} style={{ backgroundImage: 'url(assets/icon/plus.svg)' }} />
        </div>

        <div onClick={() => router.push(appRoutes.chatting.route)} className={'tab-button'}>
          <NavIcon
            icon={'chatting'}
            color={location.pathname === appRoutes.chatting.route ? 'var(--ion-color-primary)' : '#9AA0A9'}
            active={location.pathname === appRoutes.chatting.route}
          />
          <div style={{ color: location.pathname === appRoutes.chatting.route ? 'var(--ion-color-primary)' : '#9AA0A9' }}>채팅</div>
        </div>

        <div onClick={() => router.push(appRoutes.myInfo.route)} className={'tab-button'}>
          <NavIcon
            icon={'user'}
            color={location.pathname === appRoutes.myInfo.route ? 'var(--ion-color-primary)' : '#9AA0A9'}
            active={location.pathname === appRoutes.myInfo.route}
          />
          <div style={{ color: location.pathname === appRoutes.myInfo.route ? 'var(--ion-color-primary)' : '#9AA0A9' }}>내 정보</div>
        </div>
      </div>
    </div>
  );
};
