import React, { useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import styled from 'styled-components';
import { AddressSearch } from '../address-search/AddressSearch';
import { useUpdateAddressMutation } from '../../generated/graphql';

export const MyInfoEditAddress: React.FC = () => {
  const [query, setQuery] = useState('');
  const [addressDetail, setAddressDetail] = useState('');
  const [updateAddress] = useUpdateAddressMutation();

  const handleSelect = (address: string) => {
    setQuery(address);
  };

  const handleUpdateAddress = async () => {
    await updateAddress({ variables: { address: query, addressDetail } });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>계정 정보 관리</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'} scrollY={false}>
        <Container>
          <div className={'font-20-500 n_10'}>주소를 입력하세요.</div>
          <IonInput
            className={'large-input'}
            placeholder={'포항시 북구 흥해읍 남송리 3번지 128-12'}
            value={query}
            onIonInput={e => setQuery(e.detail.value as string)}
            debounce={450}
          />
          <input
            className={'large-input'}
            placeholder={'한동대학교 효암채플 12층 2028호'}
            value={addressDetail}
            onChange={e => setAddressDetail(e.target.value)}
          />
        </Container>
        <AddressSearchWrapper className={'ion-content-scroll-host'}>
          <AddressSearch query={query} onSelect={handleSelect} />
        </AddressSearchWrapper>
      </IonContent>
      <IonFooter>
        <IonButton className={'font-18-600'} expand={'full'} size={'large'} color={'light'} onClick={handleUpdateAddress} disabled={!query}>
          다음
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const AddressSearchWrapper = styled.div`
  height: calc(100vh - 360px);
  overflow: auto;
`;
