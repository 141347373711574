import React, { useContext, useEffect, useRef } from 'react';
import { useIonRouter } from '@ionic/react';
import { App } from '@capacitor/app';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

export const BackButtonService: React.FC = () => {
  const router = useIonRouter();
  const location = useLocation();
  const isAuthenticated = useContext(AuthContext);
  const backPressed = useRef(false);

  useEffect(() => {
    App.addListener('backButton', () => {
      if (!router.canGoBack()) {
        App.exitApp();
      } else {
        backPressed.current = true;
      }
      console.log(router.routeInfo);
    });
  }, []);

  useEffect(() => {
    if (location.pathname.includes('unprotected') && isAuthenticated && backPressed.current) {
      App.exitApp();
    }
    backPressed.current = false;
  }, [location]);

  return null;
};
