import React, { useEffect, useState } from 'react';
import { WriteReview } from './components/WriteReview';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonAlert, useIonRouter } from '@ionic/react';
import { ItemCard } from '../../common/components/ItemCard';
import { useGetAccountByIdLazyQuery, useGetItemByIdQuery, usePostSellerReviewMutation } from '../../generated/graphql';
import { useParams } from 'react-router';
import { SearchBuyerList } from './components/SearchBuyerList';

export const WriteSellerReview: React.FC = () => {
  const { itemId } = useParams<{ itemId: string }>();
  const [buyerAccountId, setBuyerAccountId] = useState<string>();
  const [buyerNickname, setBuyerNickname] = useState('');
  const [point, setPoint] = useState(0);

  const item = useGetItemByIdQuery({ variables: { itemId: ~~itemId } }).data?.searchItemById;
  const [findAccount] = useGetAccountByIdLazyQuery();
  const [postReview] = usePostSellerReviewMutation();
  const [present] = useIonAlert();
  const router = useIonRouter();

  useEffect(() => {
    if (buyerAccountId) {
      findAccount({ variables: { accountId: ~~buyerAccountId } }).then(res =>
        setBuyerNickname(res.data?.searchAccountById?.nickName ?? ''),
      );
    }
  }, [buyerAccountId]);

  const handlePostReview = async (review: string) => {
    if (buyerAccountId) {
      const result = await postReview({
        variables: {
          buyerAccountId: ~~buyerAccountId,
          sellerReview: review,
          sellerPoint: point,
          itemId: ~~itemId,
        },
      });
      if (!result.errors) {
        await present('거래후기 쓰기가 완료되었습니다', [{ text: '확인', handler: () => router.goBack() }]);
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>거래후기 쓰기</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        {item && (
          <ItemCard
            itemId={itemId}
            title={item.title ?? ''}
            type={item.type}
            diffTime={item.diffTime ?? ''}
            price={item.price ?? ''}
            isAttItem={item.isAttItem ?? false}
            attCount={item.attUserCount ?? 0}
          />
        )}
        {!buyerAccountId && <SearchBuyerList itemId={itemId} onSelect={setBuyerAccountId} />}
        {buyerAccountId && <WriteReview point={point} onPointChange={setPoint} nickName={buyerNickname} onComplete={handlePostReview} />}
      </IonContent>
    </IonPage>
  );
};
