import { useIsAuthenticated } from '../providers & services/AuthProvider';
import { useIonRouter } from '@ionic/react';
import { useCallback } from 'react';
import { appRoutes } from '../../AppRoutes';

export const useUnauthenticatedRedirect = () => {
  const authenticated = useIsAuthenticated();
  const router = useIonRouter();

  return useCallback(
    (action: () => void) => {
      if (!authenticated) {
        router.push(appRoutes.landingPage.path());
      } else {
        action();
      }
    },
    [authenticated, router],
  );
};
