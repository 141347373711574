import React from 'react';
import { useMyAttSellersQuery } from '../../../generated/graphql';
import { SellerInfoCard } from '../../../common/components/SellerInfoCard';
import { useIonRouter } from '@ionic/react';
import { appRoutes } from '../../../AppRoutes';
import styled from 'styled-components/macro';

export const MyAttSellersList: React.FC = () => {
  const { data, refetch } = useMyAttSellersQuery({fetchPolicy: 'network-only'});
  const router = useIonRouter();

  console.log(data?.searchMyAttAccountList);
  return (
    <Container>
      {data?.searchMyAttAccountList?.map(
        seller =>
          seller && (
            <SellerInfoCard
              isAttSeller={true}
              key={seller.attAccountId}
              sellerId={seller.attAccountTo.accountId}
              sellerName={seller.attAccountTo.nickName ?? ''}
              addressName={seller.attAccountTo.address ?? ''}
              avgStarPoint={seller.attAccountTo.avgStarPoint ?? 0}
              reviewCount={seller.attAccountTo.reviewCount ?? 0}
              onClick={() => router.push(appRoutes.sellerDetail.path(seller.attAccountTo.accountId), 'forward')}
              s3Url={seller.attAccountTo.file?.s3Url}
              refetch={refetch}
            />
          ),
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
