import React, { useEffect, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter
} from '@ionic/react';
import styled from 'styled-components';
import { useCheckAuthMailMutation, useSendAuthMailMutation, useUpdateEmailMutation } from '../../generated/graphql';
import {patternEmail} from "../../common/utils/regularExpressions";

export const MyInfoEditEmail: React.FC = () => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [codeValidated, setCodeValidated] = useState(false);

  const [updateEmail] = useUpdateEmailMutation();
  const [sendAuthCode] = useSendAuthMailMutation();
  const [checkAuthCode] = useCheckAuthMailMutation();

  const router = useIonRouter();

  const handleSendAuthCode = () => {
    sendAuthCode({ variables: { mailParam: { toMail: email } } });
  };

  useEffect(() => {
    if (verificationCode.trim().length >= 6) {
      handleCheckAuthCode();
    }
  }, [verificationCode]);

  const handleCheckAuthCode = async () => {
    const res = await checkAuthCode({ variables: { email, authData: verificationCode.trim() } });
    if (res.data?.checkAuthMail?.status === 'success') {
      setCodeValidated(true);
    }
  };

  const handleUpdateEmail = async () => {
    const res = await updateEmail({ variables: { email } });
    if (res.data?.updateEmail?.status === 'success') {
      router.goBack();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>계정 정보 관리</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <Container>
          <div className={'font-20-500 n_10'}>
            이메일 주소를 입력하세요. <span style={{ fontSize: 'smaller' }}>(선택사항)</span>
          </div>
          <div className={'font-14-500 n_30'}>추후 휴대폰 번호가 변경되면 이메일 인증이 필요합니다.</div>
          <input
            className={'large-input'}
            placeholder={'이메일 입력'}
            value={email}
            type={'email'}
            onChange={e => setEmail(e.target.value)}
          />
          <IonButton expand={'block'} size={'large'} style={{ width: '100%' }} disabled={!email || !patternEmail.test(email)} onClick={handleSendAuthCode}>
          인증코드 다시 받기 (5분)
          </IonButton>
          <input
            className={'large-input'}
            placeholder={'인증코드 입력'}
            value={verificationCode}
            onChange={e => setVerificationCode(e.target.value)}
          />

        </Container>
      </IonContent>
      <IonFooter>
        <IonButton
          className={'font-18-600'}
          expand={'full'}
          size={'large'}
          color={'light'}
          disabled={!codeValidated}
          onClick={handleUpdateEmail}
        >
          다음
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
