import React, { useState } from 'react';
import { IonButton, IonContent, IonIcon, IonTextarea } from '@ionic/react';
import { useGetReportOptionsQuery, usePostReportMutation } from '../../../generated/graphql';
import styled from 'styled-components';
import { checkmark } from 'ionicons/icons';

export const FleaMarketItemDetailOptionsModal: React.FC<{ itemId?: string; dismiss: () => void }> = ({ itemId, dismiss }) => {
  const reportOptions = useGetReportOptionsQuery().data?.searchCodeByGrpId;
  const [content, setContent] = useState<string | null>();

  const [postReport] = usePostReportMutation();

  const handlePostReport = () => {
    if (itemId && content) {
      postReport({ variables: { itemId: ~~itemId, content } });
    }
    dismiss();
  };

  return (
    <>
      <IonContent className={'ion-padding'}>
        <Container>
          <Title className={'font-20-500'}>
            이 게시글을 신고하시려는 이유를
            <br />
            선택해주세요.
          </Title>
          {reportOptions &&
            reportOptions.map(option => (
              <Item key={`report_${option?.codeId}`} onClick={() => setContent(option!.name)}>
                <div>{option?.name}</div>
                {content === option?.name && <IonIcon icon={checkmark} color={'primary'} style={{ width: '20px', fontSize: '20px' }} />}
              </Item>
            ))}
          <TextareaWrapper>
            <IonTextarea
              rows={5}
              placeholder={'이 게시글을 신고하시려는 이유를\n' + '입력해주세요.'}
              onFocus={() => setContent('')}
              style={{ lineHeight: '25px' }}
              value={content}
              onIonInput={e => setContent(e.detail.value as string)}
            />
          </TextareaWrapper>
        </Container>
        <IonButton color={'primary'} expand={'block'} disabled={!content} onClick={handlePostReport} size={'large'}>
          신바람빌리지에 신고하기
        </IonButton>
      </IonContent>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const Item = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding: 19px 10px;
  border-bottom: 0.6px solid #e1e3de;
  color: var(--neutral-30);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TextareaWrapper = styled.div`
  width: 100%;
  padding: 15px 20px;
  border-top: 0.6px solid #e1e3de;
  border-bottom: 0.6px solid #e1e3de;
`;
