import React from 'react';

export const AppLogo: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width ?? 170} height={height ?? 45} viewBox='0 0 170 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_164_12381)'>
        <path
          d='M0.183425 21.0744C-0.179579 20.6526 0.0199097 19.9888 0.549698 19.8187H0.562779C1.10892 19.6422 1.64198 19.4394 2.17504 19.2301C3.17248 18.8345 4.16011 18.4094 5.1412 17.9745C5.25566 17.9221 5.37012 17.8731 5.48785 17.8208C7.92095 16.7711 10.2887 15.571 12.5746 14.2271C15.2824 12.6346 17.8757 10.8426 20.3219 8.86758C22.6504 6.99061 24.7957 4.29942 27.4577 2.8966C27.7259 2.75599 28.03 2.5042 28.3309 2.45842C28.7299 2.39956 29.2793 2.70367 29.2335 3.20398C29.1746 3.8547 28.9621 4.46292 28.7364 5.0744C28.6154 5.4014 28.4813 5.7284 28.344 6.04886C28.0627 6.69304 27.7521 7.32415 27.4119 7.9389C26.7383 9.16515 25.9959 10.3521 25.332 11.5849C25.2208 11.7942 25.1097 12.0035 25.0017 12.216C24.8513 12.5136 24.9102 12.8733 25.1391 13.1185C25.3386 13.3278 25.5381 13.5371 25.7441 13.7366C27.4087 15.3291 29.0798 16.9085 30.7411 18.5042C30.9406 18.6939 31.1237 18.9162 31.2611 19.1549C31.5227 19.6029 31.5816 20.0967 31.2807 20.5414C31.0027 20.9534 30.5416 20.8782 30.1328 20.803C28.7037 20.5414 27.7848 19.4721 26.7677 18.5336C25.9926 17.8175 25.2143 17.1112 24.4229 16.4147C23.7656 15.8326 22.8728 14.6456 22.1173 15.5318C21.3619 16.418 19.6515 17.9745 18.3467 19.1091C17.0876 20.2013 15.6716 21.1136 14.1607 21.82C11.1422 23.2359 7.73455 23.8473 4.41519 23.4811C2.75061 23.298 1.26916 22.3333 0.193236 21.0842L0.183425 21.0744V21.0744Z'
          fill='#057C65'
        />
        <path
          d='M81.2833 13.6392C81.087 12.3999 80.8974 11.19 80.7011 9.96052C79.2491 10.4903 78.2517 11.2358 77.3491 12.4359C77.0253 12.8675 76.7245 13.7439 76.0312 13.5967C75.691 13.5248 75.4588 13.1749 75.4327 12.8283C75.4065 12.4817 75.5537 12.1416 75.7434 11.8473C76.0639 11.3503 76.4301 10.8532 76.8324 10.4216C77.3883 9.81991 78.0326 9.30653 78.7324 8.88143C79.0856 8.66888 79.4486 8.47595 79.8247 8.30591C80.1288 8.16858 80.4951 8.10645 80.8319 8.10645C81.4075 8.10645 81.9144 8.52827 82.0321 9.08417C82.2742 10.2156 82.2349 11.3306 82.6175 12.4392C82.9249 13.3319 83.893 15.0257 83.3828 15.9806C83.1375 16.4416 82.4802 16.6444 82.0485 16.8765C81.1132 17.3441 80.2302 17.8771 79.2622 18.2761C77.8233 18.8679 74.8244 19.6135 74.7982 19.62C74.7917 19.62 75.0893 20.3296 75.1547 20.3918C75.3346 20.5585 75.7008 20.6403 75.9298 20.7057C76.2012 20.7841 76.4792 20.8365 76.7604 20.8822C78.7128 21.206 80.8483 21.3237 82.7549 20.7089C83.4253 20.4931 84.063 20.1857 84.6451 19.7868C84.74 19.7214 84.8446 19.6691 84.9493 19.6266C85.0376 19.5906 85.1488 19.5383 85.2469 19.5546C85.3057 19.5644 85.3613 19.5971 85.4104 19.6331C85.4889 19.6952 85.5706 19.7933 85.5543 19.9013C85.5346 20.0157 85.4758 20.14 85.4234 20.2446C85.2697 20.552 85.0376 20.7809 84.7759 21C84.2036 21.4774 83.543 21.8142 82.8366 22.0464C82.6797 22.0987 82.5194 22.1445 82.3592 22.187C80.8614 22.5859 79.2393 22.7756 77.6892 22.7134C77.2117 22.6938 76.7375 22.6382 76.2699 22.5369C75.8022 22.4355 75.2888 22.308 74.9029 22.0529C74.396 21.7194 74.0199 21.2485 73.6929 20.7482C73.3593 20.2381 73.1467 19.6495 73.0879 19.0413C73.0225 18.3513 73.1337 17.4978 73.5228 16.9027C73.8956 16.3304 74.4908 15.9021 75.0958 15.6012C75.2038 15.5489 75.3117 15.4966 75.4229 15.4508C76.2568 15.1009 77.1561 14.9374 78.0424 14.7576C78.6278 14.6399 79.2132 14.5156 79.7822 14.3259C80.0569 14.2344 80.3185 14.0872 80.5932 14.0088C80.8516 13.9335 81.0707 13.8191 81.28 13.6392H81.2833Z'
          fill='#057C65'
        />
        <path
          d='M126.367 22.6798C126.322 21.277 126.272 19.9036 126.227 18.5106C124.539 18.903 123.326 19.5897 122.158 20.8029C121.74 21.2378 121.285 22.1697 120.533 21.9147C120.164 21.7871 119.954 21.3686 119.974 20.9762C119.993 20.587 120.203 20.2306 120.455 19.9298C120.88 19.4229 121.357 18.9194 121.864 18.4943C122.564 17.9024 123.352 17.4184 124.189 17.0424C124.611 16.8527 125.043 16.6892 125.484 16.5519C125.841 16.4407 126.256 16.4244 126.632 16.4669C127.273 16.5454 127.777 17.0849 127.832 17.7193C127.947 19.0109 127.751 20.247 128.025 21.5288C128.244 22.5654 129.088 24.583 128.388 25.5738C128.052 26.0512 127.293 26.1885 126.779 26.388C125.677 26.7804 124.618 27.2513 123.486 27.5619C121.805 28.023 118.365 28.4383 118.339 28.4415C118.332 28.4415 118.568 29.2721 118.63 29.3473C118.806 29.5566 119.202 29.6972 119.447 29.8018C119.738 29.9261 120.043 30.0242 120.347 30.1125C122.472 30.7436 124.834 31.1654 127.038 30.7436C127.813 30.5932 128.565 30.3414 129.268 29.9784C129.383 29.9196 129.504 29.8738 129.628 29.8411C129.732 29.8149 129.86 29.7691 129.968 29.8051C130.033 29.8247 130.092 29.8672 130.141 29.9163C130.223 29.9948 130.298 30.1158 130.262 30.2335C130.226 30.361 130.141 30.4885 130.069 30.5964C129.857 30.9169 129.566 31.1393 129.245 31.3453C128.542 31.7965 127.764 32.081 126.946 32.2412C126.763 32.2772 126.58 32.3066 126.393 32.3328C124.673 32.5682 122.842 32.5584 121.128 32.2772C120.602 32.1889 120.079 32.0647 119.575 31.8848C119.071 31.705 118.515 31.4957 118.12 31.1589C117.603 30.7174 117.25 30.1419 116.952 29.5435C116.648 28.932 116.498 28.2453 116.514 27.5652C116.537 26.7869 116.779 25.855 117.292 25.2468C117.786 24.6614 118.506 24.2658 119.218 24.014C119.346 23.9682 119.474 23.929 119.604 23.893C120.582 23.6183 121.602 23.5595 122.613 23.4842C123.28 23.4352 123.951 23.3763 124.608 23.2423C124.925 23.1769 125.236 23.0493 125.553 23.0003C125.851 22.9545 126.112 22.8531 126.367 22.6831V22.6798Z'
          fill='#221B08'
        />
        <path
          d='M55.1241 21.9183C54.7742 21.9608 54.4373 21.8398 54.1463 21.467C53.8323 21.0681 53.9762 20.6724 54.2379 20.3062C55.1666 19.0015 56.1608 17.8145 56.8574 16.3495C56.8443 16.379 56.5173 16.379 56.4747 16.3855C56.3407 16.3986 56.2033 16.4149 56.0692 16.4313C55.5198 16.5 54.9671 16.5555 54.4177 16.6144C54.1365 16.6438 53.8585 16.67 53.5773 16.6994C53.368 16.719 53.0736 16.6929 52.9232 16.8695C52.6714 17.1703 52.6125 17.5725 52.6092 17.9518C52.6092 18.2069 52.6158 18.4652 52.5602 18.7137C52.4981 18.9949 52.3247 19.3645 51.9912 19.2991C51.7066 19.2435 51.5856 18.9721 51.4973 18.7235C51.324 18.2363 51.1867 17.7327 51.0395 17.239C50.9643 16.9872 50.8858 16.7354 50.7942 16.4901C50.7125 16.2612 50.5228 16.1828 50.307 16.101C50.0421 16.0029 49.7674 15.9408 49.4894 15.8852C48.7895 15.7446 48.1126 15.5451 47.4716 15.2279C47.1151 15.0514 46.7652 14.8584 46.4316 14.6393C46.2681 14.5314 46.0981 14.4202 45.9836 14.26C44.839 12.6937 50.6798 13.5897 50.6798 13.5897C50.6798 13.5897 50.9741 11.4544 51.7917 10.6859C51.8015 10.6761 51.8113 10.6696 51.8211 10.6598C52.0893 10.4407 52.4882 10.493 52.7139 10.7546C53.453 11.6277 52.8185 12.6741 52.9297 13.8382C54.336 14.1685 55.8403 14.0736 57.3087 14.1259C58.5285 14.1652 57.6946 8.15824 57.688 7.75603C57.6749 7.03664 58.6037 6.58538 59.0812 7.21321C59.3853 7.61215 59.49 8.36425 59.5848 8.84493C60.3239 12.5269 59.5979 16.343 57.6946 19.5639C57.276 20.2735 56.151 21.7973 55.1274 21.9216L55.1241 21.9183Z'
          fill='#057C65'
        />
        <path
          d='M113.158 22.2359C112.697 22.2915 112.256 22.1312 111.87 21.644C111.455 21.1175 111.648 20.5944 111.991 20.1137C113.214 18.3937 114.525 16.8273 115.441 14.898C115.425 14.934 114.993 14.9373 114.934 14.9438C114.754 14.9634 114.578 14.9831 114.398 15.006C113.672 15.0942 112.946 15.1727 112.22 15.2447C111.85 15.2806 111.481 15.3199 111.111 15.3558C110.837 15.3853 110.447 15.346 110.251 15.5782C109.918 15.9739 109.842 16.5036 109.839 17.0072C109.836 17.344 109.849 17.6841 109.777 18.0111C109.695 18.3806 109.466 18.8678 109.025 18.7828C108.649 18.7076 108.489 18.3511 108.374 18.0241C108.145 17.38 107.965 16.7194 107.769 16.0654C107.668 15.7319 107.563 15.4016 107.445 15.0746C107.337 14.7738 107.089 14.6692 106.801 14.5612C106.454 14.4304 106.088 14.352 105.725 14.2768C104.8 14.0904 103.913 13.8255 103.063 13.4069C102.592 13.1748 102.134 12.9164 101.693 12.6287C101.477 12.4881 101.255 12.3409 101.101 12.1316C99.5933 10.0683 107.295 11.2455 107.295 11.2455C107.295 11.2455 107.681 8.43003 108.76 7.41961C108.773 7.40653 108.786 7.39672 108.799 7.38691C109.152 7.09588 109.679 7.16455 109.973 7.51444C110.948 8.66547 110.111 10.0454 110.254 11.579C112.109 12.0139 114.094 11.8897 116.027 11.9583C117.632 12.0107 116.537 4.09077 116.527 3.56104C116.511 2.61274 117.734 2.01761 118.365 2.84491C118.767 3.37138 118.901 4.35891 119.032 4.99656C120.006 9.8492 119.052 14.885 116.543 19.1294C115.991 20.0646 114.509 22.0757 113.158 22.2392V22.2359Z'
          fill='#221B08'
        />
        <path
          d='M94.0864 25.9767C94.1158 26.0061 94.1682 26.0454 94.2434 26.029C96.0617 25.6203 97.808 25.1821 99.5478 24.2698C100.15 23.9558 101.108 23.1678 100.869 24.4365C100.768 24.9663 100.303 25.3521 99.8846 25.6399C98.9984 26.2514 97.9257 26.6111 96.9021 26.9119C95.4174 27.3468 93.9098 27.7 92.3629 27.8144C91.6696 27.8667 90.9731 27.8667 90.2798 27.8144C89.9266 27.7882 89.5734 27.749 89.2202 27.6934C88.8899 27.6444 88.589 27.543 88.2783 27.4155C87.7289 27.1931 87.4771 26.6372 87.585 26.0486C87.9546 24.0016 88.3372 21.9546 88.6839 19.9011C89.23 16.6769 93.3735 17.6317 95.6234 18.1843C95.5188 18.1614 95.3389 18.6912 95.3095 18.7697C95.11 19.3321 95.1525 19.9174 95.0773 20.4995C94.989 21.1862 94.8843 21.8729 94.7535 22.553C94.5769 23.4523 94.3284 24.3352 94.1485 25.2344C94.1158 25.3914 93.9621 25.8459 94.0864 25.9701V25.9767ZM92.497 25.944C92.5788 25.8655 92.6475 25.7543 92.7063 25.5941C92.9254 24.9891 93.0432 24.3253 93.1674 23.6942C93.2525 23.2561 93.8804 19.0607 93.8804 19.0607C93.8804 19.0607 92.5265 19.1359 92.1242 19.2013C91.0614 19.3779 90.9763 20.4177 90.8096 21.2875C90.5774 22.4974 90.2928 23.6975 90.1064 24.9139C90.0705 25.1428 89.9168 25.6039 89.9887 25.8263C90.1653 26.3952 91.1726 26.2481 91.601 26.1925C92.0032 26.1402 92.2943 26.1435 92.5036 25.9407L92.497 25.944Z'
          fill='#057C65'
        />
        <path
          d='M68.0255 12.9582C68.1531 13.0072 68.3755 12.9582 68.5161 12.9582C69.5691 12.9222 70.632 12.9287 71.6392 13.3081C71.6981 13.3309 71.9335 13.3865 71.9532 13.4454C71.9695 13.4879 71.8714 13.5925 71.8485 13.6252C71.7798 13.7266 71.7112 13.8476 71.6261 13.9359C71.3318 14.2335 70.9165 14.3316 70.524 14.4395C69.4612 14.7338 67.2864 15.1327 67.2864 15.1327C67.2864 15.1327 67.1 15.4107 67.0477 15.5316C66.9725 15.7115 66.8973 15.8881 66.8221 16.0679C66.6782 16.4145 66.5376 16.7612 66.3937 17.1078C66.1124 17.801 65.8279 18.4975 65.5303 19.1842C65.4682 19.3281 65.4028 19.4752 65.3373 19.6191C64.4576 19.4949 64.0489 18.8801 63.7807 18.1542C63.2345 16.6663 63.1855 15.1523 63.6662 13.6351C64.075 12.35 64.8174 11.212 65.6905 10.1983C66.1222 9.69473 66.5735 9.12249 67.172 8.80857C67.4761 8.64834 67.813 8.54697 68.1563 8.51427C68.4343 8.48484 68.8464 8.45214 69.072 8.65161C69.4383 8.98188 69.1015 9.53778 68.9674 9.89093C68.9608 9.91382 68.418 11.539 68.2021 12.3042C68.14 12.52 68.0582 12.7391 68.0255 12.9614V12.9582Z'
          fill='#057C65'
        />
        <path
          d='M42.6055 0.499821C42.6643 0.506361 42.7265 0.522711 42.7919 0.552141C43.6879 0.967428 43.7435 2.64493 43.8187 3.44934C44.0509 5.94106 43.8384 8.6813 43.495 11.1567C43.2105 13.2233 42.7003 15.2605 42.2882 17.3108C42.177 17.8634 42.007 18.3801 41.5524 18.7005C41.1175 18.6417 41.0913 18.3637 41.1011 18.0858C41.1894 15.787 41.3039 13.4882 41.3725 11.1894C41.4478 8.67803 41.4641 6.16669 41.5491 3.65535C41.5655 3.12888 41.6767 0.398452 42.6055 0.499821V0.499821Z'
          fill='#057C65'
        />
        <path
          d='M39.5051 22.4771C39.5248 22.5164 39.3253 22.7257 39.2958 22.7649C39.1585 22.9513 39.0375 23.1475 38.923 23.3502C38.681 23.7819 38.4783 24.2429 38.3442 24.7171C38.1676 25.3482 38.1153 26.012 38.1709 26.666C38.1774 26.7347 38.1676 26.8458 38.197 26.9112C38.2428 27.0126 38.197 26.9734 38.3115 26.993C38.4652 27.0191 38.6679 26.9668 38.8216 26.9439C39.2664 26.872 39.6785 26.7216 40.0938 26.545C41.0585 26.133 42.0004 25.6719 42.952 25.2305C43.2529 25.0899 43.5603 24.946 43.8612 24.8021C44.0181 24.7269 44.1718 24.6517 44.3256 24.5699C44.4694 24.4915 44.6035 24.4064 44.7638 24.3705C44.9306 24.3312 45.1987 24.4162 45.3066 24.5438C45.4048 24.6582 45.4048 24.9329 45.3361 25.0899C45.2576 25.2664 45.0777 25.4234 44.9077 25.5313C43.3935 26.4796 41.8794 27.4541 40.1821 28.0459C39.7766 28.1865 39.3449 28.3141 38.9165 28.4023C38.4881 28.4906 38.0695 28.6312 37.6901 28.4056C37.2355 28.1342 36.7385 27.9642 36.3362 27.6045C36.0026 27.3069 35.7345 26.9407 35.5644 26.5287C35.378 26.0872 35.3846 25.5509 35.4205 25.0801C35.535 23.5955 36.0452 22.2156 36.8824 20.9762C37.3435 20.2928 38.161 20.2209 38.6549 20.8618C39.0244 21.3392 39.2337 21.9409 39.5084 22.4771H39.5051Z'
          fill='#057C65'
        />
        <path
          d='M86.9476 6.49426C86.9673 6.48445 86.9869 6.47791 87.0098 6.47464C87.0817 6.46483 87.1504 6.48118 87.2191 6.49753C87.7293 6.63487 88.2263 6.87358 88.6319 7.21039C89.2205 7.69761 90.0021 9.12332 90.0021 9.12332L90.0152 9.13313C90.0152 9.13313 90.944 9.22469 91.4083 9.30317C92.0788 9.41762 92.7786 9.55169 93.2953 10.0226C93.3672 10.088 93.4327 10.1599 93.4817 10.2417C93.5177 10.3038 93.5406 10.3757 93.5635 10.4444C93.5537 10.4117 92.6641 10.5229 92.5758 10.5327C92.2357 10.5621 91.8956 10.5948 91.5588 10.6275C91.3887 10.6439 91.2187 10.6635 91.0519 10.6831C90.957 10.6929 90.63 10.7583 90.63 10.7583C90.63 10.7583 90.7314 11.203 90.7608 11.3404C90.9047 11.9747 91.1173 12.6876 91.0192 13.3416C90.9799 13.5836 90.8066 14.3291 90.4763 14.3226C90.182 14.3193 89.9694 14.182 89.7994 13.94C89.6751 13.7634 89.6031 13.5574 89.4919 13.3743C89.3644 13.1617 89.2434 12.9459 89.1257 12.7301C88.1969 11.0591 87.3728 9.2999 86.8692 7.45236C86.8038 7.20712 86.6206 6.65449 86.9476 6.49099V6.49426Z'
          fill='#057C65'
        />
        <path
          d='M126.81 1.81152C126.868 1.81479 126.931 1.82787 126.996 1.85076C127.892 2.15814 127.948 3.41054 128.023 4.00895C128.255 5.86303 128.042 7.90349 127.699 9.74776C127.415 11.2879 126.904 12.8019 126.492 14.329C126.381 14.741 126.211 15.1236 125.757 15.3623C125.322 15.3198 125.295 15.1105 125.305 14.9045C125.394 13.1943 125.508 11.4841 125.577 9.77065C125.652 7.90349 125.668 6.03306 125.753 4.16264C125.77 3.77024 125.881 1.73958 126.81 1.81479V1.81152Z'
          fill='#221B08'
        />
        <path
          d='M141.473 13.338C141.735 12.1739 141.99 11.0359 142.248 9.88164C140.777 9.8424 139.635 10.1498 138.416 10.8986C137.977 11.1667 137.405 11.8371 136.843 11.4643C136.565 11.2812 136.483 10.8921 136.584 10.5749C136.686 10.2577 136.931 10.0092 137.202 9.81624C137.66 9.48924 138.161 9.17859 138.667 8.93661C139.371 8.5998 140.119 8.37091 140.888 8.24011C141.274 8.17471 141.666 8.1322 142.055 8.11258C142.373 8.09623 142.716 8.17144 143.017 8.28916C143.527 8.48862 143.828 9.04125 143.736 9.57426C143.553 10.6599 143.128 11.6343 143.079 12.7461C143.04 13.6454 143.301 15.4831 142.513 16.1469C142.134 16.4674 141.48 16.4183 141.019 16.4739C140.028 16.5589 139.06 16.7224 138.062 16.7355C136.581 16.7551 133.667 16.3627 133.644 16.3595C133.638 16.3595 133.654 17.0919 133.69 17.1671C133.791 17.3764 134.086 17.5759 134.266 17.7165C134.478 17.88 134.707 18.0239 134.939 18.1645C136.552 19.1357 138.403 19.9891 140.303 20.1101C140.97 20.1526 141.643 20.1069 142.297 19.9597C142.405 19.9368 142.513 19.9237 142.625 19.9237C142.716 19.9237 142.831 19.9139 142.912 19.9662C142.961 19.9957 143.001 20.0447 143.03 20.0938C143.079 20.1755 143.115 20.2932 143.063 20.3783C143.007 20.4731 142.909 20.5614 142.827 20.6333C142.585 20.8491 142.297 20.9734 141.99 21.0748C141.316 21.2971 140.617 21.3658 139.91 21.32C139.753 21.3102 139.593 21.2971 139.436 21.2775C137.971 21.1042 136.47 20.7053 135.119 20.1069C134.704 19.9237 134.302 19.7079 133.926 19.4529C133.549 19.1978 133.137 18.9068 132.886 18.5471C132.555 18.0762 132.388 17.5268 132.271 16.9709C132.153 16.4052 132.173 15.8068 132.333 15.2509C132.516 14.6166 132.915 13.9004 133.468 13.5113C133.997 13.1353 134.674 12.9652 135.315 12.9096C135.43 12.8998 135.544 12.8933 135.659 12.8933C136.519 12.8769 137.372 13.047 138.219 13.2007C138.779 13.302 139.341 13.3969 139.91 13.4296C140.185 13.4459 140.466 13.4067 140.738 13.4296C140.993 13.4557 141.228 13.4296 141.477 13.3413L141.473 13.338Z'
          fill='#221B08'
        />
        <path
          d='M169.87 14.8392C169.896 15.1335 169.915 15.4245 169.928 15.7188C169.977 16.6834 170.085 17.7723 169.879 18.737C169.801 19.0967 169.503 20.1856 169.16 20.1431C168.856 20.1038 168.656 19.8749 168.519 19.4956C168.417 19.2177 168.375 18.9005 168.29 18.6192C168.192 18.289 168.1 17.9522 168.012 17.6154C167.315 15.0157 166.74 12.2984 166.511 9.47966C166.481 9.10361 166.38 8.26323 166.746 8.05395C166.766 8.04087 166.789 8.03433 166.815 8.03106C166.89 8.02125 166.962 8.05395 167.031 8.08992C167.538 8.35152 168.015 8.76354 168.385 9.31289C168.8 9.92765 169.052 10.7975 169.291 11.579C169.598 12.5927 169.778 13.7143 169.876 14.8392H169.87Z'
          fill='#221B08'
        />
        <path
          d='M148.05 9.17578C148.109 9.17905 148.171 9.19213 148.236 9.21502C149.132 9.5224 149.188 10.7748 149.263 11.3732C149.495 13.2273 149.283 15.2677 148.939 17.112C148.655 18.6522 148.145 20.1662 147.733 21.6933C147.621 22.1053 147.451 22.4879 146.997 22.7266C146.562 22.6841 146.536 22.4748 146.545 22.2688C146.634 20.5586 146.748 18.8484 146.817 17.1349C146.892 15.2677 146.908 13.3973 146.993 11.5269C147.01 11.1345 147.121 9.10384 148.05 9.17905V9.17578Z'
          fill='#221B08'
        />
        <path
          d='M163.695 9.77758C163.695 9.77758 163.659 9.28708 162.792 9.62716C162.792 9.62716 158.992 10.2681 154.1 9.4375C154.1 9.4375 153.046 9.66313 153.648 10.6409C153.648 10.6409 154.1 11.4322 155.604 11.6186C155.604 11.6186 156.752 11.6971 158.227 11.6186C157.903 12.4099 157.667 13.2765 157.34 14.0187C156.824 15.1959 156.219 16.3241 155.538 17.3966C154.963 18.3024 154.335 19.1657 153.655 19.9799C153.622 20.0191 153.589 20.0584 153.56 20.0976C153.288 20.4312 153.011 20.7614 152.726 21.0819C152.572 21.2519 152.419 21.422 152.255 21.5789H152.252C152.095 21.7359 152.144 22.0269 152.35 22.1316H152.356C152.968 22.4357 153.674 22.5599 154.306 22.3506C155.561 21.9321 156.67 21.1244 157.494 20.0682C157.906 19.5385 158.246 18.9499 158.501 18.3155C158.763 17.6582 159.087 16.7786 159.201 16.2979C159.312 15.827 159.845 16.1377 160.189 16.2521C160.598 16.3895 161.006 16.5301 161.415 16.6772C161.948 16.8702 162.475 17.1285 163.034 16.9911C163.194 16.9519 163.371 16.9029 163.397 16.6968C163.423 16.4745 163.315 16.2914 163.139 16.1638C163.047 16.0952 162.939 16.0396 162.834 16.0003C161.948 15.6635 161.062 15.3333 160.176 14.9965C160.065 14.954 159.957 14.9082 159.845 14.8591C159.718 14.8035 159.633 14.6727 159.633 14.5321C159.633 14.4308 159.633 14.3327 159.636 14.2313C159.649 13.6427 159.702 13.0574 159.718 12.4688C159.728 12.1745 159.724 11.8769 159.708 11.5793C159.708 11.5466 159.702 11.5172 159.702 11.4845C160.885 11.3308 162.102 11.0463 163.024 10.5297C163.024 10.5297 163.74 10.1896 163.701 9.77758H163.695Z'
          fill='#221B08'
        />
      </g>
      <defs>
        <clipPath id='clip0_164_12381'>
          <rect width='170' height='32' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

interface Props {
  width?: number;
  height?: number;
}
