import React, { useEffect, useState } from 'react';
import { patternAlphanumericKorean } from '../../../common/utils/regularExpressions';
import { SignUpData } from '../SignUp';
import { openNativeGallery } from '../../../common/utils/photoUtils';

export const SignUpNickname: React.FC<Props> = ({ data, onChange, validated }) => {
  const [photoSrc, setPhotoSrc] = useState<string>();

  const handleChange = (val: string) => {
    onChange({ ...data, nickname: val.trim() });
    validated(patternAlphanumericKorean.test(val.trim()) && val.trim().length >= 3);
  };

  const handleSelectImg = async () => {
    const srcs = await openNativeGallery(1);
    if (srcs.length) {
      setPhotoSrc(srcs[0]);
    }
  };

  useEffect(() => {
    if (photoSrc) {
      onChange({ ...data, photoSrc });
    }
  }, [photoSrc]);

  return (
    <>
      <img src={photoSrc ?? 'assets/account-photo.png'} alt='' onClick={handleSelectImg} />
      <div className={'font-20-500 n_10'}>닉네임을 입력하세요.</div>
      <input
        type='text'
        className={'large-input'}
        placeholder={'닉네임'}
        value={data.nickname}
        onChange={e => handleChange(e.target.value)}
      />
      <div className={'font-14-400 color-tertiary'}>
        닉네임은 N자까지 가능합니다. <br />
        띄어쓰기나 특수문자는 불가능합니다.
      </div>
    </>
  );
};

interface Props {
  data: SignUpData;
  validated: (validated: boolean) => void;
  onChange: (data: SignUpData) => void;
}
