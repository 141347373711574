import { useContext, useEffect, useState } from 'react';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { AuthContext } from './AuthProvider';
import { useUpdateFcmTokenMutation } from '../../generated/graphql';
import { toast } from 'react-toastify';
import { Device } from '@capacitor/device';

// todo - define click actions
export const FCMService = () => {
  const auth = useContext(AuthContext);
  const [postToken] = useUpdateFcmTokenMutation();

  useEffect(() => {
    if (auth) {
      setUpPushNotifications();
    } else {
      void PushNotifications.removeAllListeners();
    }
  }, [auth]);

  const setUpPushNotifications = () => {
    console.log('requesting push notifications');
    PushNotifications.requestPermissions().then(res => {
      if (res.receive === 'granted') {
        PushNotifications.register().then(() => {
          void listenForNotifications();
        });
      }
    });
  };

  const listenForNotifications = async () => {
    PushNotifications.addListener('registration', (token: Token) => {
      console.log(`----- TOKEN: ${JSON.stringify(token)} ------ `);
      postToken({ variables: { token: token.value } });
    });

    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log(notification);
      toast.info(notification.body ?? notification.title ?? 'New notification');
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      const { data } = action.notification;
    });
  };

  return null;
};
