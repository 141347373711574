import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useGetQuestionQuery } from '../../../generated/graphql';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

export const QuestionDetail: React.FC = () => {
  const { questionId } = useParams<{ questionId: string }>();
  const question = useGetQuestionQuery({ variables: { id: ~~questionId } }).data?.searchQuestionById;
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>고객센터 문의</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        {question && (
          <>
            <QuestionBody>
              <div>{question.question}</div>
              <QuestionStatus>
                <div>{question.regDate}</div>
                <StatusIndicator answered={question.status !== 'ING'}>{question.status === 'ING' ? '대기중' : '답변완료'}</StatusIndicator>
              </QuestionStatus>
            </QuestionBody>
            {question.answer && <Answer>{question.answer}</Answer>}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

const QuestionBody = styled.div`
  overflow: auto;
  word-wrap: break-word;
  height: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 2px solid var(--neutral-70);
`;

const QuestionStatus = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--tertiary-60);
`;

const StatusIndicator = styled('div')<{ answered?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 58px;
  height: 22px;
  border-radius: 6px;
  background-color: ${props => (props.answered ? 'var(--ion-color-light-contrast)' : 'white')};
  border: 1px solid var(--ion-color-light-contrast);
  color: ${props => (props.answered ? 'white' : 'var(--primary)')};
`;

const Answer = styled.div`
  width: 100%;
  flex-grow: 1;
  color: var(--neutral-70);
`;
