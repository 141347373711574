import React from 'react';
import { AppLogo } from '../../../../common/components/AppLogo';
import styled from 'styled-components';

export const PremiumApply: React.FC = () => {
  return (
    <Container gap={'20px'}>
      <img src={'/assets/premium/premium_badge.png'} alt='' />
      <AppLogo width={165} height={132} />
      <div className={'font-20-600 n_10'}>프리미엄 회원이 되어보세요!</div>
      <div className={'font-18-600 n_30'} style={{ textAlign: 'center' }}>
        프리미엄 회원이 되시면,
        <br />
        <span className={'color-primary'}>매달 10000원에</span>
        <br />
        다음과 같은 혜택을 누리실 수 있어요.
        <br />
        (첫 달은 무료 혜택을 누려보세요!)
      </div>
      <Container gap={'10px'}>
        <Benefit>
          1. 프리미엄 인증회원 마크가 표시되어
          <br />
          구매자에게 신뢰를 줄 수 있어요.
        </Benefit>
        <Benefit>2. 판매글을 무제한으로 등록할 수 있어요.</Benefit>
        <Benefit>3. 등록한 글을 끌어올리기가 무제한으로 가능해요.</Benefit>
      </Container>
    </Container>
  );
};

const Container = styled('div')<{ gap: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => props.gap};
  height: 100%;
  width: 100%;
`;

const Benefit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 60px;
  color: var(--tertiary-60);
  border: 1px solid var(--tertiary-60);
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
`;
