import { Camera } from '@capacitor/camera';
import { toast } from 'react-toastify';
import { generateRandomString } from './utils';

export const openNativeGallery = (limit: number) => {
  return new Promise<string[]>(resolve => {
    Camera.checkPermissions().then(() => {
      Camera.pickImages({ limit }).then(photos => {
        const srcs = [];
        for (const p of photos.photos) {
          if (srcs.length === limit) break;
          srcs.push(p.webPath);
        }

        if (photos.photos.length > limit) {
          toast.error(`사진은 최대 ${limit}개까지 등록 가능해요`);
        }
        resolve(srcs);
      });
    });
  });
};

export interface ResizedImage {
  file: File;
  width: number;
  height: number;
}

export const imageResize = async (src: string, needsThumbnail: boolean): Promise<{ image: ResizedImage; thumb?: ResizedImage }> => {
  const maxWidth = 1200;
  const maxHeight = 900;
  const maxThumbnailWidth = 480;
  const maxThumbnailHeight = 360;

  const imageFile = await resizeGalleryImage(src, maxWidth, maxHeight);
  let thumbNail;
  if (needsThumbnail) {
    thumbNail = await resizeGalleryImage(src, maxThumbnailWidth, maxThumbnailHeight, `thumb-${imageFile.file.name}`);
  }
  return { image: imageFile, thumb: thumbNail };
};

async function resizeGalleryImage(src: string, maxWidth: number, maxHeight: number, filename?: string): Promise<ResizedImage> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      let ratio;
      if (width >= height) {
        ratio = maxWidth / width;
      } else {
        ratio = maxHeight / height;
      }

      // Don't enlarge images
      if (ratio < 1) {
        width *= ratio;
        height *= ratio;
      }
      canvas.width = width;
      canvas.height = height;

      canvas.width = width;
      canvas.height = height;

      if (ctx) {
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
        canvas.toBlob(
          blob => {
            if (blob) {
              const file = new File([blob], filename ?? generateRandomString(15), { type: 'image/jpeg' });
              resolve({ file, width: canvas.width, height: canvas.height });
            } else {
              reject();
            }
          },
          'image/jpeg',
          0.8,
        );
      } else {
        reject();
      }
    };

    img.src = src;
  });
}

export const uploadImageToS3 = async (file: File, url: string) => {
  const res = await fetch(url, {
    method: 'POST',
    body: file,
    headers: new Headers({
      accept: '*/*',
      'Content-Type': 'image/jpeg',
      'Content-Disposition': 'attachment',
    }),
  });
  console.log(res);
  return res;
};

export const urlToBase64 = async (src: string): Promise<string> => {
  return new Promise(resolve => {
    fetch(src).then(res =>
      res.blob().then(blob => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const b64 = reader.result as string;
          resolve(b64.substring(b64.indexOf(',') + 1));
        };
        reader.readAsDataURL(blob);
      }),
    );
  });
};

export const fileToB64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const b64 = reader.result?.toString();
      if (b64) {
        resolve(b64.substring(b64.indexOf(',') + 1));
      } else {
        reject();
      }
      resolve(reader.result as string);
    };
    reader.onerror = error => reject(error);
  });

export const selectProfileImage = async () => {
  const srcs = await openNativeGallery(1);
  if (srcs.length) {
    const resized = await imageResize(srcs[0], true);
    const b64 = await fileToB64(resized.thumb!.file);
    const filename = `${generateRandomString(10)}.jpeg`;
    return Promise.resolve({ b64, filename });
  } else return Promise.reject();
};
