import { useIonRouter } from '@ionic/react';
import { appRoutes } from '../AppRoutes';
import React from 'react';

const Home: React.FC = () => {
  const router = useIonRouter();
  const params = new URLSearchParams(location.search);
  const itemId = params.get("itemId");
  if(itemId) {
    router.push(appRoutes.fleaMarketDetail.path(itemId));
  } else {
    router.push(appRoutes.allItems.route);
  }

  return (
    <div> Home </div>
  );
};

export default Home;
