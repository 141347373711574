import React, { useMemo, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { useAddQuestionMutation, useGetMyQuestionsQuery } from '../../../generated/graphql';
import styled from 'styled-components';
import { appRoutes } from '../../../AppRoutes';

export const CustomerCenter: React.FC = () => {
  const [input, setInput] = useState('');
  const questionsResult = useGetMyQuestionsQuery();
  const questions = useMemo(() => questionsResult.data?.searchMyQuestionList ?? [], [questionsResult]);
  const [addQuestion] = useAddQuestionMutation();

  const handleAddQuestion = async () => {
    if (input.length) {
      await addQuestion({ variables: { question: input } });
      setInput('');
      questionsResult.refetch();
    }
  };

  const router = useIonRouter();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>고객센터 문의</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        {questions.length === 0 && (
          <NoQuestions>
            신바람빌리지 고객센터에 <br />
            문의할 내용을 작성해주세요. <br />
            관리자가 확인 후, <br />
            답변드리겠습니다.
          </NoQuestions>
        )}
        {questions.length > 0 && (
          <QuestionWrapper>
            {questions.map(q =>
              q ? (
                <Question key={q.qnaId} onClick={() => router.push(appRoutes.myInfo.children.customerCenterQuestion.path(q.qnaId))}>
                  <QuestionBody>{q.question}</QuestionBody>
                  <QuestionStatus>
                    <div>{q.regDate}</div>
                    <StatusIndicator answered={q.status !== 'ING'}>{q.status === 'ING' ? '대기중' : '답변완료'}</StatusIndicator>
                  </QuestionStatus>
                </Question>
              ) : null,
            )}
          </QuestionWrapper>
        )}
        <InputWrapper>
          <IonTextarea
            placeholder={'말씀을 입력해주세요.'}
            className={'customer-center-textarea'}
            value={input}
            onIonInput={e => setInput((e.detail?.value?.trim() as string) ?? '')}
          />
        </InputWrapper>
      </IonContent>
      <IonButton fill={'outline'} expand={'block'} style={{ margin: '40px 24px' }} onClick={handleAddQuestion} disabled={!input.length}>
        전송하기
      </IonButton>
    </IonPage>
  );
};

const NoQuestions = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  align-content: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
`;

const QuestionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-height: 50%;
  overflow: auto;
  gap: 10px;
`;

const Question = styled.div`
  width: 100%;
  padding: 9px 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid var(--neutral-70);
`;

const QuestionBody = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`;

const QuestionStatus = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--tertiary-60);
`;

const StatusIndicator = styled('div')<{ answered?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 58px;
  height: 22px;
  border-radius: 6px;
  background-color: ${props => (props.answered ? 'var(--ion-color-light-contrast)' : 'white')};
  border: 1px solid var(--ion-color-light-contrast);
  color: ${props => (props.answered ? 'white' : 'var(--primary)')};
`;

const InputWrapper = styled.div`
  width: 100%;
  height: 50%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--neutral-70);
  border-bottom: 1px solid var(--neutral-70);
`;
