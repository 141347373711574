import React from 'react';
import styled from 'styled-components';

export const PremiumPurchased: React.FC = () => {
  return (
    <Container gap={'12px'}>
      <img src={'/assets/premium/premium_badge.png'} alt='' />
      <div>
        프리미엄 회원 신청이 완료되었습니다. <br />
        관리자가 검토 후 승인여부가 <br />
        알림과 휴대폰 문자가 전송될 예정입니다.
      </div>
    </Container>
  );
};
const Container = styled('div')<{ gap: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${props => props.gap};
  height: 100%;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-30);
  text-align: center;

  > img {
    width: 140px;
  }
`;
