import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { AppLogo } from '../../common/components/AppLogo';
import { SignUpNickname } from './components/SignUpNickname';
import { SignUpPhone } from './components/SignUpPhone';
import { SignUpAddress } from './components/SignUpAddress';
import { SignUpEmail } from './components/SignUpEmail';
import { SignUpConfirm } from './components/SignUpConfirm';
import { arrowBackOutline, chevronBack } from 'ionicons/icons';
import { useSignUpMutation } from '../../generated/graphql';
import { generateRandomString } from '../../common/utils/utils';
import { appRoutes } from '../../AppRoutes';
import { fileToB64, imageResize } from '../../common/utils/photoUtils';

export interface SignUpData {
  nickname: string;
  photoSrc?: string;
  phone: string;
  address1: string;
  address2: string;
  email: string;
  agreeLocation: boolean;
  agreePersonal: boolean;
  agreeMarketing: boolean;
  agreePersonalMarketing: boolean;

  [key: string]: string | boolean | undefined;
}

const initialState: SignUpData = {
  nickname: '',
  phone: '',
  address1: '',
  address2: '',
  email: '',
  agreeLocation: false,
  agreePersonal: false,
  agreeMarketing: false,
  agreePersonalMarketing: false,
};

// Todo - test profile img upload
const SignUp: React.FC = () => {
  const [signUpData, setSignUpData] = useState<SignUpData>(initialState);
  const [section, setSection] = useState(1);
  const [validated, setValidated] = useState(false);
  const [enteringEmail, setEnteringEmail] = useState(false);

  const router = useIonRouter();
  const [signUp] = useSignUpMutation();

  const handleBack = () => {
    if (section > 1) {
      setSection(prev => prev - 1);
    } else {
      router.goBack();
    }
  };

  const handleNext = () => {
    if (section < 5) {
      setSection(prev => prev + 1);
      setValidated(false);
    } else if (section === 5) {
      handleSignUp();
    } else {
      router.push(appRoutes.allItems.path());
    }
  };

  const handleSignUp = async () => {
    let data = {
      phone: signUpData.phone,
      nickName: signUpData.nickname,
      address: signUpData.address1,
      addressDetail: signUpData.address2,
      email: signUpData.email ?? '',
      isAgree1: signUpData.agreeLocation,
      isAgree2: signUpData.agreePersonal,
      isAgree3: signUpData.agreeMarketing,
      isAgree4: signUpData.agreePersonalMarketing,
    };

    if (signUpData.photoSrc) {
      const resized = await imageResize(signUpData.photoSrc, true);
      const b64 = await fileToB64(resized.thumb!.file);
      const filename = `${generateRandomString(10)}.jpeg`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data = { ...data, base64String: b64, fileRealName: filename };
    }

    const res = await signUp({
      variables: {
        data,
      },
    });
    if (res.data?.addAccount?.status === 'success') {
      setSection(6);
    }
  };

  const getNextButtonText = () => {
    switch (section) {
      case 1:
      case 2:
      case 3:
        return '다음';
      case 4:
        return enteringEmail ? '다음' : '나중에 하기';
      case 5:
        return '완료';
      case 6:
        return '시작하기';
      default:
        return '다음';
    }
  };

  useEffect(() => {
    console.log(signUpData);
  }, [signUpData]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonIcon icon={section === 1 ? arrowBackOutline : chevronBack} onClick={handleBack} size={'large'} />
          </IonButtons>
          <IonTitle>회원 가입</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <div className={'page-container sign-up'}>
          {section === 1 && <SignUpNickname data={signUpData} validated={setValidated} onChange={setSignUpData} />}
          {section === 2 && <SignUpPhone data={signUpData} validated={setValidated} onChange={setSignUpData} />}
          {section === 3 && <SignUpAddress data={signUpData} validated={setValidated} onChange={setSignUpData} />}
          {section === 4 && (
            <SignUpEmail data={signUpData} validated={setValidated} onChange={setSignUpData} isEnteringEmail={setEnteringEmail} />
          )}

          {section === 5 && <SignUpConfirm data={signUpData} onChange={setSignUpData} validated={setValidated} />}

          {section === 6 && (
            <div className={'flex-col-center-20 full_width full_height'} style={{ marginTop: '10vh' }}>
              <AppLogo />
              <img src='/assets/sign-up/img1.png' />
              <div className={'font-20-400 n_10'}>
                {signUpData.nickname}님, 환영합니다! <br /> 회원가입이 완료되었습니다.
              </div>
            </div>
          )}
        </div>
      </IonContent>
      <IonFooter>
        <IonButton className={'font-18-600'} expand={'full'} size={'large'} color={'light'} onClick={handleNext} disabled={!validated}>
          {getNextButtonText()}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default SignUp;
