import React from 'react';
import styled from 'styled-components';
import { IonButton, IonIcon, useIonRouter } from '@ionic/react';
import { heart, heartOutline } from 'ionicons/icons';

import { appRoutes } from '../../AppRoutes';

export const ItemCard: React.FC<Props> = props => {
  const { itemId, s3Url, title, type, diffTime, price, attCount, isAttItem, buttons, onClick, onHeartClick } = props;

  const router = useIonRouter();

  const mapTypeString = () => {
    switch (type) {
      case 'market':
        return '벼룩시장';
      case 'farm':
        return '농산물장터';
      case 'estate':
        return '부동산';
      case 'job':
        return '일자리';
      default:
        return type;
    }
  };

  const handleHeartClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (onHeartClick) {
      onHeartClick();
    }
  };

  const moveToDetailView = (e: React.MouseEvent) => {
    e.stopPropagation();
    router.push(appRoutes.fleaMarketDetail.path(itemId));
  };

  return (
    <Wrapper onClick={onClick}>
      <Container>
        <Img src={s3Url} onClick={moveToDetailView} />
        <Content>
          <div className={'font-16-600 n_30'}>{title}</div>
          <div className={'font-12-500 n_70'}>
            {mapTypeString()} | {diffTime ?? ''}
          </div>
          <div className={'flex-row-between'}>
            <div className={'font-16-600 n_10'}>{price}</div>
            <div className={'flex-row-end'} style={{ gap: 0 }}>
              <IonIcon color={'primary'} icon={isAttItem ? heart : heartOutline} onClick={handleHeartClick} />
              <div style={{ marginLeft: '4px' }}>{attCount}</div>
            </div>
          </div>
        </Content>
      </Container>
      {buttons && (
        <ButtonContainer>
          {buttons.map(button => (
            <IonButton
              key={button.label}
              className={'square-button'}
              color={'primary'}
              expand={'block'}
              fill={'outline'}
              style={{ flexGrow: 1 }}
              onClick={e => button.onClick(itemId, e)}
            >
              {button.label}
            </IonButton>
          ))}
        </ButtonContainer>
      )}
    </Wrapper>
  );
};

interface Props {
  itemId: string;
  s3Url?: string;
  title: string;
  type: string;
  diffTime: string;
  price: string;
  isAttItem: boolean;
  attCount: number;
  onHeartClick?: () => void;
  buttons?: {
    onClick: (itemId: string, e?: React.MouseEvent) => void;
    label: string;
  }[];
  onClick?: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 0.6px solid var(--neutral-90);
  gap: 14px;
  padding: 14px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`;

const Img = styled.img`
  width: 76px;
  height: 76px;
  border-radius: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 14px;
`;
