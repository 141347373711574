import React, { useEffect, useRef } from 'react';
import { SignUpData } from '../SignUp';
import { IonCheckbox, IonInput, IonItem, IonLabel, IonList } from '@ionic/react';

export const SignUpConfirm: React.FC<Props> = ({ data, onChange, validated }) => {
  const allRef = useRef<HTMLIonCheckboxElement>(null);
  const locRef = useRef<HTMLIonCheckboxElement>(null);
  const personalRef = useRef<HTMLIonCheckboxElement>(null);
  const marketingRef = useRef<HTMLIonCheckboxElement>(null);
  const personalMarketingRef = useRef<HTMLIonCheckboxElement>(null);

  const handleToggleAll = (checked: boolean) => {
    if (locRef.current && personalRef.current && marketingRef.current && personalMarketingRef.current) {
      locRef.current.checked = checked;
      personalRef.current.checked = checked;
      marketingRef.current.checked = checked;
      personalMarketingRef.current.checked = checked;
      updateData();
    }
  };

  const updateData = () => {
    if (locRef.current && personalRef.current && marketingRef.current && personalMarketingRef.current && allRef.current) {
      onChange({
        ...data,
        agreeLocation: locRef.current.checked,
        agreePersonal: personalRef.current.checked,
        agreeMarketing: marketingRef.current.checked,
        agreePersonalMarketing: personalMarketingRef.current.checked,
      });
      allRef.current.checked =
        personalRef.current.checked && locRef.current.checked && marketingRef.current.checked && personalMarketingRef.current.checked;
    }
  };

  useEffect(() => {
    validated( data.agreeLocation && data.agreePersonal );
  }, [data]);

  return (
    <>
      <div className={'inline-input-button'}>
        <div className={'font-20-500 n_10'}>
          마지막으로 입력한 정보를 <br /> 확인하세요.
        </div>
        <img src='/assets/icon/account.svg' />
      </div>
      <IonItem lines={'inset'}>
        <IonLabel position={'fixed'}>닉네임</IonLabel>
        <IonInput value={data.nickname} readonly={true} />
      </IonItem>
      <IonItem lines={'inset'}>
        <IonLabel position={'fixed'}>휴대폰</IonLabel>
        <IonInput value={data.phone} readonly={true} />
      </IonItem>
      <IonItem lines={'inset'}>
        <IonLabel position={'fixed'}>주소</IonLabel>
        <IonInput value={data.address1} readonly={true} />
      </IonItem>
      {data.address2 && (
        <IonItem lines={'inset'}>
          <IonLabel position={'fixed'}></IonLabel>
          <IonInput value={data.address2} readonly={true} />
        </IonItem>
      )}
      <IonItem lines={'inset'}>
        <IonLabel position={'fixed'}>이메일</IonLabel>
        <IonInput value={data.email} readonly={true} />
      </IonItem>

      <div className={'font-20-500 n_10'}>서비스 약관에 동의해주세요</div>

      <IonList>
        <IonItem lines={'none'}>
          <IonCheckbox ref={allRef} slot={'start'} mode={'ios'} onIonChange={e => handleToggleAll(e.detail.checked)} />
          <IonLabel className={'font-16-600 n_30'}>모두 동의합니다.</IonLabel>
        </IonItem>

        <IonItem lines={'none'}>
          <IonCheckbox ref={locRef} slot={'start'} mode={'ios'} onClick={updateData} />
          <IonLabel className={'font-14-500 n_30'}>[필수] <a href="http://xn--4i2by9fe3g.net/m/etc/agreement.html">서비스 이용약관</a> 동의</IonLabel>
        </IonItem>

        <IonItem lines={'none'}>
          <IonCheckbox ref={personalRef} slot={'start'} mode={'ios'} onClick={updateData} />
          <IonLabel className={'font-14-500 n_30'}>[필수] <a href="http://xn--4i2by9fe3g.net/m/etc/privacy.html">개인정보 처리방침</a> 동의</IonLabel>
          {/* <IonLabel className={'font-14-500 n_30'}>[필수] 위치정보 이용약관 동의</IonLabel> */}
        </IonItem>

        {/* <IonItem lines={'none'}>
          <IonCheckbox ref={personalRef} slot={'start'} mode={'ios'} onClick={updateData} />
          <IonLabel className={'font-14-500 n_30'}>[선택] 개인정보 수집 이용 동의</IonLabel>
        </IonItem> */}

        <IonItem lines={'none'}>
          <IonCheckbox ref={marketingRef} slot={'start'} mode={'ios'} onClick={updateData} />
          <IonLabel className={'font-14-500 n_30'}>[선택] 마케팅 수신 동의</IonLabel>
        </IonItem>
        <IonItem lines={'none'}>
          <IonCheckbox ref={personalMarketingRef} slot={'start'} mode={'ios'} onClick={updateData} />
          <IonLabel className={'font-14-500 n_30'}>[선택] 개인정보 광고활용 동의</IonLabel>
        </IonItem>
      </IonList>
    </>
  );
};

interface Props {
  data: SignUpData;
  onChange: (data: SignUpData) => void;
  validated: (valid: boolean) => void;
}
