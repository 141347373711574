import React from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from '@ionic/react';
import { useGetAlarmConfigQuery, useUpdateAlarmConfigMutation } from '../../generated/graphql';

export const MyInfoAlertSettings: React.FC = () => {
  const alarms = useGetAlarmConfigQuery();
  const [updateConfig] = useUpdateAlarmConfigMutation();

  const handleUpdate = async (key: string, val: 0 | 1) => {
    await updateConfig({ variables: { alarmConfig: { [key]: val } } });
    alarms.refetch();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>알림 설정</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <IonList>
          <IonItem>
            <IonLabel style={{ padding: '16px 10px' }}>채팅 알림</IonLabel>
            <IonToggle
              checked={!!alarms.data?.searchMyAlarmConfig?.isChat ?? false}
              onIonChange={e => handleUpdate('isChat', e.detail.checked ? 1 : 0)}
              slot='end'
            />
          </IonItem>
          <IonItem>
            <IonLabel style={{ padding: '16px 10px' }}>활동 알림</IonLabel>
            <IonToggle
              checked={!!alarms.data?.searchMyAlarmConfig?.isActive ?? false}
              onIonChange={e => handleUpdate('isActive', e.detail.checked ? 1 : 0)}
              slot='end'
            />
          </IonItem>
          <IonItem>
            <IonLabel style={{ padding: '16px 10px' }}>관리자 공지 알림</IonLabel>
            <IonToggle
              checked={!!alarms.data?.searchMyAlarmConfig?.isNotice ?? false}
              onIonChange={e => handleUpdate('isNotice', e.detail.checked ? 1 : 0)}
              slot='end'
            />
          </IonItem>
          <IonItem>
            <IonLabel style={{ padding: '16px 10px' }}>마케팅 알림</IonLabel>
            <IonToggle
              checked={!!alarms.data?.searchMyAlarmConfig?.isMarketing ?? false}
              onIonChange={e => handleUpdate('isMarketing', e.detail.checked ? 1 : 0)}
              slot='end'
            />
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
