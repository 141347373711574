import React, { useState } from 'react';
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import { useDeleteChatRoomsMutation, useGetChatRoomsQuery } from '../../generated/graphql';
import { ChatRoomCard } from './components/ChatRoomCard';
import { appRoutes } from '../../AppRoutes';
import { NavBar } from '../../common/components/NavBar';

export const Chatting: React.FC = () => {
  const [editMode, setEditMode] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const router = useIonRouter();

  const chatRooms = useGetChatRoomsQuery({ fetchPolicy: 'network-only' });

  console.log(chatRooms);
  const [deleteChatRooms] = useDeleteChatRoomsMutation();

  const handleToggleSelect = (id: string) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(prev => prev.filter(i => i !== id));
    } else {
      setSelectedIds(prev => [...prev, id]);
    }
  };

  const handleDeleteChatRooms = async () => {
    await deleteChatRooms({ variables: { roomIds: selectedIds.map(id => ~~id) } });
    setEditMode(false);
    setSelectedIds([]);
    chatRooms.refetch();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton onClick={() => setEditMode(prev => !prev)}>{editMode ? `${selectedIds.length} 선택해제` : '편집'}</IonButton>
          </IonButtons>
          <IonTitle>채팅</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        {chatRooms.data?.searchMyChatRoomList?.map(room => (
          <ChatRoomCard
            key={room!.roomId}
            selectionMode={editMode}
            selected={selectedIds.includes(room!.roomId)}
            partner={room?.item.title ?? ''}
            lastMessage={room?.lastMessage?.messageType === 'TEXT' ? room?.lastMessage?.message ?? '' : '포토'}
            time={room?.lastMessage?.regDate ?? ''}
            avatar={room?.fromAccount?.file?.s3Url}
            onClick={() => (editMode ? handleToggleSelect(room!.roomId) : router.push(appRoutes.chatting.children.room.path(room!.roomId)))}
          />
        ))}
      </IonContent>
      {editMode && (
        <IonButton
          color={'primary'}
          expand={'block'}
          style={{ margin: '0 auto 40px', width: 'calc(100vw - 40px)' }}
          onClick={handleDeleteChatRooms}
        >
          나가기
        </IonButton>
      )}
      {!editMode && <NavBar />}
    </IonPage>
  );
};
