import { IonContent, IonLabel, IonPage, IonSegment, IonSegmentButton } from '@ionic/react';
import { NavBar } from '../../common/components/NavBar';
import { AppLogo } from '../../common/components/AppLogo';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FleaMarket } from './components/FleaMarket';
import { useGetRootCategoriesQuery } from '../../generated/graphql';
import scrollIntoView from 'scroll-into-view-if-needed';

const AllItems: React.FC = () => {
  const [activeSegment, setActiveSegment] = useState<string>('market');
  const content = useRef<HTMLIonContentElement>(null);
  const rootCategories = useGetRootCategoriesQuery();

  useEffect(() => {
    const segment = document.getElementById(`all-items-segment-${activeSegment}`);
    if (segment) {
      scrollIntoView(segment, { behavior: 'smooth', block: 'center', scrollMode: 'always', inline: 'center' });
    }
  }, [activeSegment]);

  useEffect(() => {
    const state = sessionStorage.getItem('all-item-state');
    if (state) {
      const parsed = JSON.parse(state);
      if (parsed.activeSegment) setActiveSegment(parsed.activeSegment);
      if (parsed.scroll && content.current) void content.current.scrollByPoint(0, parsed.scroll, 200);
    }
  }, []);

  useEffect(() => {
    saveState(0);
  }, [activeSegment]);

  const saveState = useCallback(
    (scroll: number) => {
      sessionStorage.setItem('all-item-state', JSON.stringify({ activeSegment, scroll }));
    },
    [activeSegment],
  );

  return (
    <IonPage>
      <IonContent
        ref={content}
        className={'ion-padding'}
        scrollEvents
        onIonScrollEnd={(e: any) => saveState(e.target.detail.currentY)}
        fullscreen
      >
        <div className={'flex-row-between'}>
          <AppLogo />
          {/*<Bell color={'var(--ion-color-primary)'} />*/}
        </div>
        {rootCategories.data?.searchRootCategory && (
          <>
            <IonSegment mode={'md'} onIonChange={e => setActiveSegment(e.detail.value! as string)} value={activeSegment} scrollable={true}>
              {rootCategories.data?.searchRootCategory?.map((parent, i) => (
                <IonSegmentButton key={`segment_${i}`} value={parent!.description!} id={`all-items-segment-${parent?.description}`}>
                  <IonLabel style={{ fontSize: '13px' }}>{parent!.name}</IonLabel>
                </IonSegmentButton>
              ))}
            </IonSegment>
            <FleaMarket
              parentId={rootCategories.data.searchRootCategory!.find(parent => parent?.description === activeSegment)!.categoryId}
              type={activeSegment}
            />
          </>
        )}
      </IonContent>
      <NavBar />
    </IonPage>
  );
};

export default AllItems;
