import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';

export const SliderArrowLeft: React.FC<Props> = ({ className, style, onClick }) => {
  return (
    <>
      <div
        className={className}
        style={{ ...style, position: 'absolute', top: '50%', left: '15px', zIndex: '1000', cursor: 'pointer' }}
        onClick={onClick}
      >
        <ChevronLeft size={30} color={'white'} />
      </div>
    </>
  );
};
export const SliderArrowRight: React.FC<Props> = ({ className, style, onClick }) => {
  return (
    <>
      <div
        className={className}
        style={{ ...style, position: 'absolute', top: '50%', right: '15px', zIndex: '1000', cursor: 'pointer' }}
        onClick={onClick}
      >
        <ChevronRight size={30} color={'white'} />
      </div>
    </>
  );
};

interface Props {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}
