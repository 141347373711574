import React, { useEffect, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import styled from 'styled-components';
import { useAddBankMutation, useGetListOfBanksQuery, useGetMyBankQuery, useUpdateBankMutation } from '../../generated/graphql';

export const MyInfoEditBank: React.FC = () => {
  const [bankNumber, setBankNumber] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [owner, setOwner] = useState('');

  const [addBank] = useAddBankMutation();
  const [updateBank] = useUpdateBankMutation();
  const myBank = useGetMyBankQuery();
  const banksList = useGetListOfBanksQuery();
  const router = useIonRouter();

  const handleUpdateBank = async () => {
    if (myBank.data?.searchMyBank) {
      await updateBank({ variables: { bank: { owner, bankCode, bankNumber } } });
    } else {
      await addBank({ variables: { bank: { owner, bankNumber, bankCode } } });
    }
    await myBank.refetch();
    router.goBack();
  };

  useEffect(() => {
    console.log(bankCode);
  }, [bankCode]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>계정 정보 관리</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <Container>
          <div className={'font-20-500 n_10'}>
            새로운계좌번호를 <br />
            입력해주세요.
          </div>
          <input
            type='tel'
            className={'large-input'}
            placeholder={'계좌번호'}
            value={bankNumber}
            onChange={e => setBankNumber(e.target.value)}
          />

          <IonSelect
            onIonChange={e => setBankCode(e.detail.value)}
            interface={'action-sheet'}
            cancelText={'닫기'}
            placeholder={'은햏'}
            style={{ border: '1px solid black', borderRadius: '10px' }}
          >
            {banksList.data?.searchCodeByGrpId?.map(
              b =>
                b && (
                  <IonSelectOption key={`bank_${b.codeId}`} value={b.codeId}>
                    {b.name}
                  </IonSelectOption>
                ),
            )}
          </IonSelect>

          <input type='text' className={'large-input'} placeholder={'에금주'} value={owner} onChange={e => setOwner(e.target.value)} />
        </Container>
      </IonContent>
      <IonFooter>
        <IonButton
          className={'font-18-600'}
          expand={'full'}
          size={'large'}
          color={'light'}
          disabled={!bankNumber.trim() || !bankCode || !owner.trim()}
          onClick={handleUpdateBank}
        >
          다음
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
