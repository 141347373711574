import React from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { appRoutes } from '../../../AppRoutes';
import { useGetMyAccountQuery } from '../../../generated/graphql';
import { PremiumPurchased } from './components/PremiumPurchased';
import { PremiumApply } from './components/PremiumApply';

export const PremiumHome: React.FC = () => {
  const isPremium = useGetMyAccountQuery({ fetchPolicy: 'network-only' }).data?.searchMyAccount?.isPremium;
  const router = useIonRouter();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>프리미엄 회원 신청</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        {!isPremium && <PremiumApply />}
        {isPremium && <PremiumPurchased />}
      </IonContent>
      <IonFooter className={'ion-padding'}>
        <IonButton
          expand={'block'}
          size={'large'}
          onClick={() => (isPremium ? router.goBack() : router.push(appRoutes.myInfo.children.premiumRegister.path(), 'forward'))}
        >
          {isPremium ? '화인' : '프리미엄 회원 신청하러 가기'}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};
