import React from 'react';
import { Heart, Phone } from 'react-feather';
import { NavIcon } from '../../../common/components/NavIcon';
import styled from 'styled-components';
import { useUnauthenticatedRedirect } from '../../../common/hooks/useUnauthenticatedRedirect';

interface Props {
  large?: boolean;
  phoneNumber: string;
  onRegisterInterest: () => void;
  onChat: () => void;
  isOwnItem: boolean;
}

export const FleaMarketItemButtons: React.FC<Props> = ({ large, phoneNumber, onRegisterInterest, onChat, isOwnItem }) => {
  const redirect = useUnauthenticatedRedirect();
  const handleHeartClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    redirect(onRegisterInterest);
  };

  const handleChatClick = () => {
    redirect(onChat);
  };

  return (
    <Container>
      <ButtonWrapper onClick={handleHeartClick}>
        <Button>
          <Heart size={large ? 20 : 18} color={'var(--ion-color-primary)'} />
          <div>관심등록</div>
        </Button>
      </ButtonWrapper>
      <ButtonWrapper onClick={isOwnItem ? undefined : handleChatClick}>
        <Button>
          <NavIcon icon={'chatting'} color={'var(--ion-color-primary)'} active={false} />
          <div>거래하기</div>
        </Button>
      </ButtonWrapper>
      <ButtonWrapper>
        <Button>
          <Phone size={large ? 20 : 18} color={'var(--ion-color-primary)'} />
          <a style={{ textDecoration: 'none' }} href={`tel:${phoneNumber}`} className={`n_10`}>
            전화하기
          </a>
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

const Button = styled.div`
  width: max-content;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 3.5vw;
  color: var(--neutral-10);
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 28px;
  box-sizing: content-box;
  border-bottom: 1px solid #e1e3de;
  padding: 12px 0;
  background-color: white;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  flex-grow: 1;
  cursor: pointer;

  & + & {
    border-left: 0.8px solid #aaaca8;
  }
`;
