import { IonApp, setupIonicReact, useIonAlert } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import './App.scss';
/* Theme variables */
import './theme/variables.css';
import { AppRoutes } from './AppRoutes';
import React, { useEffect, useRef } from 'react';
import { ToastProvider } from './common/providers & services/ToastProvider';
import { useGetNoticeMessageQuery } from './generated/graphql';
import { getLastShownInstallApp, getSavedNoShowNoticeIds, setLastShownInstallApp, setSavedNoShowNoticeId } from './common/utils/storage';
import { toast } from 'react-toastify';
import { Device } from '@capacitor/device';
import dayjs from 'dayjs';

setupIonicReact();

const App: React.FC = () => {
  const notice = useGetNoticeMessageQuery().data?.noticeMessage;
  const [alert] = useIonAlert();
  const button = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const noShowIds = getSavedNoShowNoticeIds();
    if (notice && !noShowIds.includes(notice.noticeId) && button.current) {
      button.current.click();
    }
  }, [notice]);

  useEffect(() => {
    Device.getInfo().then(({ platform }) => {
      const lastShown = getLastShownInstallApp();
      const show = !lastShown || dayjs(lastShown).isBefore(dayjs(), 'day');
      if (platform === 'web' && show) {
        toast.info('앱 설치 해보시겠어요', {
          onClick: () => window.open('https://play.google.com/store/apps/details?id=com.uos.project_new_windy', '_blank'),
          autoClose: 4500,
        });
        setLastShownInstallApp();
      }
    });
  }, []);

  return (
    <IonApp>
      {notice && (
        <button
          style={{ display: 'none' }}
          ref={button}
          onClick={() =>
            alert({
              header: notice.title ?? '',
              message: `<div class="notice-alert"><div>${notice.content}</div><img src="${notice?.file?.s3Url}"/></div>`,
              buttons: [
                { text: '닫기', role: 'cancel' },
                { text: '다시 안보기', handler: () => setSavedNoShowNoticeId(notice!.noticeId) },
              ],
            })
          }
        ></button>
      )}
      <AppRoutes />
      <ToastProvider />
    </IonApp>
  );
};

export default App;
