import React from 'react';
import { useGetPreviousItemsQuery } from '../../generated/graphql';
import { IonContent } from '@ionic/react';
import { ItemCard } from '../../common/components/ItemCard';
import styled from 'styled-components';

export const PreviousItemsModal: React.FC<{ dismiss: (item?: any) => void; type: string }> = ({ dismiss, type }) => {
  const items = useGetPreviousItemsQuery({ variables: { type: type } }).data?.searchMyItemListByType ?? [];
  return (
    <IonContent className={'ion-padding'}>
      <Container>
        <div>내가 쓴 다른글을 선택하세요</div>
        {items.map(item =>
          item ? (
            <ItemCard
              key={`prev-item-${item.itemId}`}
              onClick={() => dismiss(item)}
              itemId={item.itemId ?? ''}
              s3Url={item.fileList?.length ? item.fileList[0]?.s3Url ?? '' : ''}
              title={item.title ?? ''}
              type={item.type ?? ''}
              diffTime={item.diffTime ?? ''}
              price={item.price ?? ''}
              isAttItem={false}
              attCount={item.attUserCount ?? 0}
            />
          ) : null,
        )}
      </Container>
    </IonContent>
  );
};

const Container = styled.div`
  width: 100%;
  height: 50vh;
  overflow: auto;
`;
