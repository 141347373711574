import React, { useEffect, useRef, useState } from 'react';
import {
  useGetMyAccountQuery,
  useGetRootCategoriesQuery,
  useGetSoldItemByIdLazyQuery,
  useToggleAttItemMutation,
  useUpdateItemStatusMutation,
} from '../../generated/graphql';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonPage,
  IonPopover,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonRouter,
} from '@ionic/react';
import { SellerInfoCard } from '../../common/components/SellerInfoCard';
import { NavBar } from '../../common/components/NavBar';
import { ItemCard } from '../../common/components/ItemCard';
import { appRoutes } from '../../AppRoutes';
import { star, starOutline } from 'ionicons/icons';

type SectionType = 'sell' | 'end' | 'buy';

export const MyInfo: React.FC = () => {
  const [activeSection, setActiveSection] = useState<SectionType>('sell');

  const [getSelectedItem] = useGetSoldItemByIdLazyQuery();
  const account = useGetMyAccountQuery();
  const categories = useGetRootCategoriesQuery();
  const [itemStatus] = useUpdateItemStatusMutation();
  const [toggleAttItem] = useToggleAttItemMutation();

  const [present] = useIonAlert();

  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverData, setPopoverData] = useState<{ points?: number; review: string }>();

  const router = useIonRouter();

  useEffect(() => {
    account.refetch();
  }, []);

  const handleUpdateStatusClick = async (itemId: string) => {
    const item = account.data?.searchMyAccount?.ingItemList?.find(item => item?.itemId === itemId);
    if (item) {
      await present(`${item.title} 거래가 완료되었나요? 거래 후기를 작성해보세요`, [
        {
          text: '완료',
          handler: () => {
            updateItemSold(itemId).then(() => setActiveSection('end'));
          },
        },
        {
          text: '거래후기쓰기',
          handler: () => updateItemSold(itemId).then(() => handleSellReviewClick(itemId)),
        },
      ]);
    }
  };

  const updateItemSold = async (itemId: string) => {
    await itemStatus({
      variables: {
        itemId: ~~itemId,
        status: 'END',
      },
    });
    await account.refetch();
  };

  const handleBuyReviewClick = (itemId: string, e?: React.MouseEvent) => {
    const item = account.data?.searchMyAccount?.buyItemList?.find(i => i?.item?.itemId === itemId);

    // if user hasn't yet reviewed the seller
    if (item && !item.review && item.sellId) {
      return router.push(appRoutes.writeBuyerReview.path(itemId, item.sellId));
    }

    // if user has reviewed seller and seller has reviewed user
    if (item && (item.sellerReview || item.sellerPoint)) {
      popover.current!.event = e;
      return presentReviewPopUp(item.sellerReview ?? '', item.sellerPoint ?? 0);
    }

    // if user has reviewed seller but seller hasn't reviewed user
    if (item && item.review && !item.sellerReview) {
      return presentReviewPopUp('리뷰 없습니다');
    }
  };

  const handleSellReviewClick = async (itemId: string, e?: React.MouseEvent) => {
    const res = await getSelectedItem({ variables: { itemId: ~~itemId } });
    const item = res.data?.searchItemSellByItemId;

    if (!item) {
      return router.push(appRoutes.writeSellerReview.path(itemId));
    }

    // if user hasn't reviewed buyer
    if (item && !item.sellerReview) {
      return router.push(appRoutes.writeSellerReview.path(itemId));
    }

    // if user has reviewed buyer and and buyer has reviewed user
    if (item && (item.review || item.point)) {
      return presentReviewPopUp(item.review ?? '', item.point ?? 0);
    }

    // if user has reviewed buyer but buyer hasn't reviewed seller
    if (item && item.sellerReview && !item.review) {
      return presentReviewPopUp('리뷰 없습니다');
    }
  };

  const handleHeartClick = async (itemId?: string) => {
    if (itemId) {
      await toggleAttItem({ variables: { itemId: ~~itemId } });
      account.refetch();
    }
  };

  const presentReviewPopUp = (review: string, points?: number) => {
    setPopoverData({ points, review });
  };

  const handleCheckBoughtItemInfo = (itemId: string) => {
    //
  };

  const getCategoryName = (type?: string) => {
    if (categories.data?.searchRootCategory) {
      return categories.data.searchRootCategory.find(c => c?.description === type)?.name ?? '';
    }
    return '';
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton onClick={() => router.push(appRoutes.myInfo.children.manage.path(), 'forward')}>관리</IonButton>
          </IonButtons>
          <IonTitle>내 정보</IonTitle>
        </IonToolbar>
        <div className={'ion-padding'} style={{ backgroundColor: 'white' }}>
          <SellerInfoCard
            sellerId={account.data?.searchMyAccount?.accountId ?? ''}
            s3Url={account.data?.searchMyAccount?.file?.s3Url}
            sellerName={account.data?.searchMyAccount?.nickName ?? ''}
            addressName={account.data?.searchMyAccount?.address ?? ''}
            avgStarPoint={account.data?.searchMyAccount?.avgStarPoint ?? 0}
            reviewCount={account.data?.searchMyAccount?.reviewCount ?? 0}
            refetch={account.refetch}
          />
          <hr />
          {account.data?.searchMyAccount && (
            <IonSegment mode={'md'} value={activeSection} onIonChange={e => setActiveSection(e.detail.value as SectionType)}>
              <IonSegmentButton value={'sell'}>판매중 {account.data?.searchMyAccount?.ingItemList?.length}</IonSegmentButton>
              <IonSegmentButton value={'end'}>판매완료 {account.data.searchMyAccount.endItemList?.length}</IonSegmentButton>
              <IonSegmentButton value={'buy'}>구매이력 {account.data.searchMyAccount.buyItemList?.length}</IonSegmentButton>
            </IonSegment>
          )}
        </div>
      </IonHeader>
      {account.data?.searchMyAccount && (
        <IonContent className={'ion-padding'}>
          {activeSection === 'sell' &&
            account.data.searchMyAccount.ingItemList?.map(item => (
              <ItemCard
                itemId={item?.itemId ?? ''}
                key={`selling_${item?.itemId}`}
                s3Url={item?.fileList?.length ? item?.fileList[0]?.s3Url! : '/assets/defaultImage.png'}
                title={item?.title ?? ''}
                type={getCategoryName(item?.type)}
                diffTime={item?.diffTime ?? ''}
                price={item?.price ?? ''}
                attCount={item?.attUserCount ?? 0}
                isAttItem={false}
                buttons={[
                  {
                    onClick: handleUpdateStatusClick,
                    label: '거래 완료',
                  },
                ]}
                onClick={() => router.push(appRoutes.myInfo.children.editItem.path(item?.itemId ?? ''))}
              />
            ))}

          {activeSection === 'end' &&
            account.data.searchMyAccount.endItemList?.map(item => (
              <ItemCard
                itemId={item?.itemId ?? ''}
                key={`complete_${item?.itemId}`}
                s3Url={item?.fileList?.length ? item?.fileList[0]?.s3Url! : '/assets/defaultImage.png'}
                title={item?.title ?? ''}
                type={getCategoryName(item?.type)}
                diffTime={item?.diffTime ?? ''}
                price={item?.price ?? ''}
                attCount={item?.attUserCount ?? 0}
                isAttItem={false}
                onHeartClick={() => handleHeartClick(item?.itemId)}
                buttons={[
                  {
                    label: '거래후기 확인',
                    onClick: handleSellReviewClick,
                  },
                ]}
              />
            ))}

          {activeSection === 'buy' &&
            account.data.searchMyAccount.buyItemList?.map(item => (
              <ItemCard
                itemId={item?.item?.itemId ?? ''}
                key={`purchased_${item?.item?.itemId}`}
                s3Url={item?.item?.fileList?.length ? item.item.fileList[0]?.s3Url! : '/assets/defaultImage.png'}
                title={item?.item?.title ?? ''}
                type={getCategoryName(item?.item?.type)}
                diffTime={item?.item?.diffTime ?? ''}
                price={item?.item?.price ?? ''}
                attCount={item?.item?.attUserCount ?? 0}
                isAttItem={item?.item?.isAttItem ?? false}
                onHeartClick={() => handleHeartClick(item?.item?.itemId)}
                buttons={[
                  {
                    onClick: handleCheckBoughtItemInfo,
                    label: '구매/배송 정보',
                  },
                  {
                    onClick: handleBuyReviewClick,
                    label: !item?.review ? '거래후기 쓰기' : '거래후기 확인',
                  },
                ]}
              />
            ))}
          <IonPopover ref={popover} isOpen={!!popoverData} onDidDismiss={() => setPopoverData(undefined)} alignment={'center'}>
            {popoverData && (
              <IonContent className={'ion-padding'}>
                {popoverData.points && (
                  <>
                    <IonIcon size={'large'} icon={popoverData!.points >= 1 ? star : starOutline} color={'warning'} />
                    <IonIcon size={'large'} icon={popoverData!.points >= 2 ? star : starOutline} color={'warning'} />
                    <IonIcon size={'large'} icon={popoverData!.points >= 3 ? star : starOutline} color={'warning'} />
                    <IonIcon size={'large'} icon={popoverData!.points >= 4 ? star : starOutline} color={'warning'} />
                    <IonIcon size={'large'} icon={popoverData!.points >= 5 ? star : starOutline} color={'warning'} />
                  </>
                )}
                <div>{popoverData!.review}</div>
              </IonContent>
            )}
          </IonPopover>
        </IonContent>
      )}
      <IonFooter>
        <NavBar />
      </IonFooter>
    </IonPage>
  );
};
