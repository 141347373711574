import React, { useEffect, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import styled from 'styled-components';
import { PhotoSelectContainer } from '../../../common/components/PhotoSelectContainer';
import { useAddPremiumMutation, useGetMyAccountLazyQuery } from '../../../generated/graphql';
import { fileToB64, imageResize } from '../../../common/utils/photoUtils';
import { generateRandomString } from '../../../common/utils/utils';
import { Browser } from '@capacitor/browser';

export const PremiumRegister: React.FC = () => {
  const [businessType, setBusinessType] = useState<'기업' | '개인'>('기업');
  const [photos, setPhotos] = useState<string[]>([]);
  const [selected, setSelected] = useState<'PREMIUM_6' | 'PREMIUM_12'>('PREMIUM_6');

  const [getAccount] = useGetMyAccountLazyQuery();
  const [addPremium] = useAddPremiumMutation();

  const router = useIonRouter();

  useEffect(() => {
    Browser.addListener('browserFinished', checkPaymentSuccess);

    return () => {
      Browser.removeAllListeners();
    };
  }, []);

  const checkPaymentSuccess = async () => {
    const account = await getAccount({ fetchPolicy: 'network-only' });
    if (account.data?.searchMyAccount?.isPremium) {
      router.goBack();
    } else {
      // todo - payment error toast?
    }
  };

  const handleSubmit = async () => {
    const fileList = [];
    for (const p of photos) {
      const res = await imageResize(p, false);
      const b64 = await fileToB64(res.image.file);
      fileList.push({ base64String: b64, fileRealName: `${generateRandomString(8)}.jpeg` });
    }

    const res = await addPremium({
      variables: {
        data: {
          term: selected,
          type: businessType === '개인' ? 'PER' : 'BUS',
          fileList,
        },
      },
    });
    if (res.data?.addPremium?.status === 'success' && res.data.addPremium.data) {
      const json = JSON.parse(res.data?.addPremium?.data);
      const { paymentId } = json;
      Browser.open({ url: `https://newind.startlump.com/admin/paymentPopup/${paymentId}` });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonTitle>프리미엄 회원 신청</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <Container gap={'26px'} style={{ height: '100%' }}>
          <Container gap={'10.5px'}>
            <div className={'font-16-600 n_30'}>사업자 구분</div>
            <ButtonContainer>
              <IonButton
                onClick={() => setBusinessType('기업')}
                expand={'block'}
                fill={businessType === '기업' ? 'solid' : 'outline'}
                className={'square-button'}
                style={{ flexGrow: 1 }}
              >
                기업
              </IonButton>
              <IonButton
                onClick={() => setBusinessType('개인')}
                expand={'block'}
                fill={businessType === '개인' ? 'solid' : 'outline'}
                className={'square-button'}
                style={{ flexGrow: 1 }}
              >
                개인
              </IonButton>
            </ButtonContainer>
          </Container>
          <Container gap={'10.5px'}>
            <div className={'font-16-600 n_30'}>사업자 등록증</div>
            <div className={'font-14-500 n_30'}>제출한 서류를 검토 후 관리자가 승인합니다.</div>
            <PhotoSelectContainer limit={5} photos={photos} onChange={setPhotos} />
          </Container>
          <Container gap={'10.5px'}>
            <div className={'font-16-600 n_30'}>회원권 선택</div>
            <div className={'font-12-500 n_30'}>
              첫 달은 무료로 이용 가능하며 언제든 중도해지 가능합니다.
              <br />
              (해지일 기준, 남은 기간에 대해 환불 처리됩니다)
            </div>
            <Offer className={'ion-padding'} active={selected === 'PREMIUM_6'} onClick={() => setSelected('PREMIUM_6')}>
              <OfferTitle active={selected === 'PREMIUM_6'} className={'font-18-600 ion-padding-bottom'}>
                6개월 이용권
              </OfferTitle>
              <div className={'ion-padding-top'}>
                <span>첫 달은 무료!</span>
                <br />
                <span>월10000원X5개월=50,000원</span>
              </div>
            </Offer>
            <Offer className={'ion-padding'} active={selected === 'PREMIUM_12'} onClick={() => setSelected('PREMIUM_12')}>
              <OfferTitle active={selected === 'PREMIUM_12'} className={'font-18-600 ion-padding-bottom'}>
                1년 이용권
              </OfferTitle>
              <div className={'ion-padding-top'}>
                <span>첫 달은 무료!</span>
                <br />
                <span>월10000원X11개월=11000원 100,000원</span>
              </div>
            </Offer>
          </Container>
        </Container>
      </IonContent>
      <IonFooter className={'ion-padding'}>
        <IonButton expand={'block'} size={'large'} onClick={handleSubmit}>
          결제하기
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

const Container = styled('div')<{ gap: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.gap};
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Offer = styled('div')<{ active: boolean }>`
  width: 100%;
  color: ${props => (props.active ? 'white' : 'var(--ion-color-primary)')};
  background: ${props => (props.active ? 'var(--ion-color-primary)' : 'white')};
  text-align: center;
`;

const OfferTitle = styled('div')<{ active: boolean }>`
  border-bottom: 1px solid ${props => (props.active ? 'white' : 'var(--ion-color-primary)')};
`;
