import React, { useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { PhotoSelectContainer } from '../../common/components/PhotoSelectContainer';
import { useGetJobCategoriesQuery, usePostAddItemMutation } from '../../generated/graphql';
import styled from 'styled-components';
import { fileToB64, imageResize } from '../../common/utils/photoUtils';
import { generateRandomString } from '../../common/utils/utils';

export const CreateNewJob: React.FC = () => {
  const [photos, setPhotos] = useState<string[]>([]);
  const [type, setType] = useState<string>('구인');
  const [categoryId, setCategoryId] = useState<string>();
  const [pay, setPay] = useState('');
  const [payDescription, setPayDescription] = useState('');
  const [content, setContent] = useState('');

  const jobCategories = useGetJobCategoriesQuery();

  const [postAddItem, { data, loading }] = usePostAddItemMutation();

  const handleSubmit = async () => {
    const fileList = [];
    for (const src of photos) {
      const resized = await imageResize(src, false);
      const b64 = await fileToB64(resized.image.file);
      fileList.push({ base64String: b64, fileRealName: `${generateRandomString(10)}.jpeg` });
    }
    postAddItem({
      variables: {
        itemParam: {
          type: 'job',
          category: categoryId!,
          title: type,
          price: pay,
          contents: content,
          fileList,
        },
      },
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode={'ios'}>
          <IonButtons slot='start'>
            <IonBackButton mode={'md'} />
          </IonButtons>
          <IonButtons slot='end'>
            <IonButton
              disabled={!type || !categoryId || !payDescription || !content || loading}
              className={'font-16-600'}
              onClick={handleSubmit}
            >
              완료
            </IonButton>
          </IonButtons>
          <IonTitle>일자리 글쓰기</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={'ion-padding'}>
        <PhotoSelectContainer limit={10} photos={photos} onChange={setPhotos} />

        <IonList>
          <IonItem lines={'none'}>
            <IonLabel>구분</IonLabel>
            <IonButton
              className={'square-button'}
              size={'default'}
              onClick={() => setType('구인')}
              fill={type === '구인' ? 'solid' : 'outline'}
            >
              구인
            </IonButton>
            <IonButton
              className={'square-button'}
              size={'default'}
              onClick={() => setType('구직')}
              fill={type === '구직' ? 'solid' : 'outline'}
            >
              구직
            </IonButton>
            <IonButton
              className={'square-button'}
              size={'default'}
              onClick={() => setType('기타')}
              fill={type === '기타' ? 'solid' : 'outline'}
            >
              기타
            </IonButton>
          </IonItem>

          <IonItem lines={'none'}>
            <IonLabel>일자리 종류</IonLabel>
            <IonSelect interface={'popover'} placeholder={''} slot={'end'} onIonChange={e => setCategoryId(e.detail.value)}>
              {jobCategories.data?.searchJobCategory?.map(c => (
                <IonSelectOption key={`category_${c?.name}`} value={c?.categoryId}>
                  {c?.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonLabel>보수 (선택사항)</IonLabel>
            <IonInput onIonInput={e => setPay(e.detail.value!)} />
          </IonItem>

          <IonItem>
            <TextArea
              placeholder={'보수에 대해 정확하게 작성해주세요.\n(예: 월급 100만원, 식비 별도, 시급 2만원 등)\n'}
              onChange={e => setPayDescription(e.target.value)}
            />
          </IonItem>

          <IonItem>
            <IonLabel style={{ padding: '16px 0' }}>내용</IonLabel>
          </IonItem>
          <IonItem lines={'none'}>
            <TextArea placeholder={'필요한 구인/구직 내용에 대해 자세히\n설명해주세요.\n'} onChange={e => setContent(e.target.value)} />
          </IonItem>
        </IonList>
      </IonContent>

      <IonFooter>
        <FooterButtonContainer>
          <FooterButton className={'font-14-500'} isMiddle={false}>
            자주 쓰는 문구
          </FooterButton>
          <FooterButton className={'font-14-500'} isMiddle={true}>
            내가 쓴 다른 글
          </FooterButton>
          <FooterButton className={'font-14-500'} isMiddle={false}>
            판매자 변경
          </FooterButton>
        </FooterButtonContainer>
      </IonFooter>
    </IonPage>
  );
};

const TextArea = styled.textarea`
  padding: 16px 0;
  width: 100%;
  height: 20vh;
  outline: none;
  border: none;
  background-color: white;
`;

const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const FooterButton = styled('div')<{ isMiddle: boolean }>`
  margin: 20px 0;
  padding: 0 20px;
  border-left: ${props => (props.isMiddle ? '1px solid var(--neutral-70)' : 'none')};
  border-right: ${props => (props.isMiddle ? '1px solid var(--neutral-70)' : 'none')};
  text-align: center;
  color: black;
`;
